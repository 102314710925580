@import "src/shared/styles/mixins";

.ddContainer {
  width: 100%;
  z-index: 100;
  .scrollbars-wrapper {
    max-height: unset !important;
  }
}

.ddContainerAbsolute {
  position: absolute;
}

.ddBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.dropDown {
  position: relative;
  z-index: 200;
  background: var(--popup-background);
  box-shadow: 0 4px 12px var(--shadow);
}

$gap-option-side: 0.7rem;

.drop-down_options-group-divider {
  margin: 0.7rem #{$gap-option-side} 0.4rem;
  border: none;
  height: 0.06rem;
  background-color: var(--border);
}

.dropDown {
  .dd-option-container {
    flex-shrink: 0;
    display: flex;
    margin: 0;
    padding: 0.4rem #{$gap-option-side} 0;
    max-width: 100%;

    &.dd-option-selected {
      .dd-option-default {
        color: var(--primary) !important;
        @include fakeBold();
      }

      .dd-option-radio {
        &:after {
          @include positionAbsoluteCentered();
          border-radius: 50%;
          width: 65%;
          height: 65%;
          background-color: var(--icon-active);
          content: "";
        }
      }
    }
  }

  .dd-option-default {
    font-family: "Roboto Condensed", sans-serif;
    text-transform: capitalize;
    font-weight: normal;
    font-size: 0.9rem;
    cursor: pointer;
    line-height: 1.1rem;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    color: var(--text-default);
  }

  .dd-option-input {
    display: none;
  }

  .dd-option-radio {
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.3rem;
    cursor: pointer;

    &:before {
      content: "";
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid var(--gray);
      @include positionAbsoluteCentered();
    }
  }
}

.dropDown .dd-option-container:first-child {
  padding-top: 0.5rem;
}

.dropDown .dd-option-container:last-child {
  padding-bottom: 0.5rem;
}

.dropDown .dd-option-default:hover {
  color: var(--primary);
}

.dropDown .dd-option-default.trans-lang-placeholder {
  white-space: nowrap;
  cursor: default;
  &:hover {
    font-weight: normal;
    color: var(--text-default);
  }
}

.dd-margin-top {
  margin-top: 0.5rem;
}

.dd-option-container.dd-disabled-title {
  pointer-events: none;
  .dd-option-default {
    color: var(--gray);
  }
}

.mode-blind {
  .dropDown {
    border: 1px solid var(--border);
    margin-top: -1px;
    box-shadow: none;
  }
}

#dropDownPortal {
  .ddContainer {
    width: unset;
    max-width: calc(100vw - 2rem);
    z-index: var(--tooltip-z-index);
  }
}
