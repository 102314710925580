.tree-list.tree-categories {
  .categories-root {
    color: var(--category-egw);
    fill: var(--category-egw);
  }

  .categories-book,
  .categories-book-translation,
  .categories-language-folder {
    color: var(--text-inactive) !important;
    fill: var(--text-inactive) !important;
  }
}
