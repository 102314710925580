.provided-by_title {
  opacity: 0.65;
  margin-bottom: 0.3rem;

  font-family: Roboto Condensed, sans-serif;
  font-size: 1rem;
  line-height: 1.2;

  color: var(--text-default);
}

// ======= R&H logo styles =======
.provided-by-rah {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.3rem;
}

.provided-by-rah_logo {
  line-height: 1;

  i {
    color: var(--logo-r-and-h);
    font-size: 1.7rem;
  }
}

.provided-by-rah_text {
  opacity: 0.65;

  font-family: Roboto Condensed, sans-serif;
  text-transform: uppercase;

  color: var(--text-default);
}

.provided-by-rah_title {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.2;
}

.provided-by-rah_subtitle {
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1.2;
}

.mode-mobile,
.mode-tablet {
  .provided-by-rah_title {
    font-size: 0.8rem;
  }

  .provided-by-rah_subtitle {
    font-size: 0.5rem;
  }
}

.mode-mobile {
  .provided-by {
    display: flex;
    align-items: center;
  }
}
