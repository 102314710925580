.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  right: 22px;
  bottom: 0px; 
}

.label-shrinked {
  right: unset !important; 
  text-overflow: ellipsis;
}

.dialog-container {
  &.highlight-dialog, &.note-dialog {
    padding: 1rem;

    .dialog-btn-wrap {
      padding-top: 1rem;
    }
  }

  &.color-picker-dialog {
    padding: 1rem;
    .dialog-btn-wrap {
      padding-top: 0;
    }
  }

  &.highlight-dialog {
    .sc-dialog-title {
      margin-bottom: 0;
    }
    .sc-folder-wrap {
      margin-top: 0;
    }
  }
}

.sc-dialog {
  background: var(--popup-background);
  width: 100%;
  max-width: 22.2rem;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;

  &:focus-within {
    border: none;
    outline: none;
  }
}

.sc-title {
  white-space: nowrap;
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.7rem;
  letter-spacing: 0.05rem;
  color: var(--btn-sec);
  flex: 0 1 auto;
  margin-right: 0.5rem;
}

.sc-title-margin {
  margin-bottom: 0.5rem;
}

.sc-label {
  font-family: "Roboto Condensed";
  text-transform: capitalize;
  color: var(--text-inactive);
  font-size: 1rem;
}

.note-textarea {
  color: var(--text-inactive);
  font-size: 1rem;
  padding: 0.5rem;
  resize: none;
  background: var(--popup-background);
}

.sc-scroll-wrap {
  height: calc(100% - 1.3rem);

  &>div:first-child {
    box-sizing: border-box;
    //padding: 0 0.2rem;
  }
}

.sc-color-item {
  user-select: none;
  cursor: pointer;
  font-size: 0.9rem;
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid var(--sc-color-border);

  &:hover {
    opacity: 0.7;
  }
}

.sc-color-list-item {
  height: 1.5rem;
  padding: 0.2rem 0.5rem;
  width: 100%;
  display: flex;
  
  .sc-color-item-text { 
    max-width: calc(100% - 1rem);
  }
}

.sc-color-grid-item {
  justify-content: center;
  display: inline-flex;
  height: 1.5rem;

  .sc-color-item-text { 
    max-width: calc(100% - .5rem);
  }
}

.sc-color-item-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 0.1rem;
  text-transform: uppercase;
  position: absolute;
  z-index: 2;
}

.sc-color-circle-item {
  border-radius: 50%;
  justify-content: center;
  display: flex;
  width: 2.2rem;
  height: 2.2rem;
}

.scColorListItemSelected::after {
  content: "";
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  right: -0.2rem;
  bottom: -0.2rem;
  border: 2px solid var(--sc-selected-color);
}

.scColorListItemSelectedCircle::after {
  content: "";
  position: absolute;
  top: -0.3rem;
  left: -0.3rem;
  right: -0.3rem;
  bottom: -0.3rem;
  border: 0.1rem solid var(--sc-selected-color);
  border-radius: 50%;
}

.hlListIcon {
  height: 0.8rem;
  width: 0.8rem;
  margin-left: 0.3rem;
}

.sc-folder-wrap {
  margin-top: 1.4rem;
  display: flex;
  align-items: baseline;
  .sc-title {
    margin-right: 0;
  }
}

.sc-folder-value {
  font-family: "Roboto Condensed", sans-serif;
  cursor: pointer;
  font-weight: bold;
  margin-left: 0.3rem;
  color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  &:hover {
    color: var(--text-link-hover);
  }

  i::before {
    line-height: unset;
    font-size: 0.8rem;
  }
}

.sc-folder-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  white-space: nowrap;
  text-transform: capitalize;
}

.sc-dialog-container {
  padding-right: 2rem;
}

.hl-color-title {
  display: flex;
  align-items: center;
  font-family: "Bebas Neue", sans-serif;
  font-size: 0.9rem;
  line-height: 175.78%;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--gray);
  letter-spacing: 0.05em;

  svg, i {
    font-size: 0.8rem;
    width: 0.8rem;
    height: 0.8rem;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.hl-color-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2.2rem, 1fr));
  grid-row-gap: 0.9rem;
  grid-column-gap: 0.3rem;
  padding: 0.3rem;
  justify-items: center;

  &.sc-color-row-list-type {
    grid-template-columns: minmax(1rem,1fr);
    grid-row-gap: 0.3rem;
    padding: 0 0.2rem;
  }

  &.sc-color-row-grid-type {
    grid-template-columns: repeat(3, minmax(1rem, 1fr));
    grid-gap: 0.3rem;
    justify-items: normal;
    padding: 0 0.3rem;
  }
}

.hl-control-btn {
  margin-left: 0.6rem;
  margin-right: 0.2rem;
}

.new-folder-input {
  width: 100%;
}

.new-editor-folder-input {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.1rem;
  line-height: 1.1rem;
  background-color: var(--background);
  color: var(--text-default);
  margin-top: 0.2rem;
  box-sizing: border-box;
  padding: 0.5rem 0.6rem;
  border: 1px solid var(--border);
  text-overflow: ellipsis;
  resize: none;
}

.sc-tabs {
  justify-content: flex-start;
  padding: 0;

  .tab-default {
    margin-right: 1em;
  }
}

.tab-wrap {
  &.sc-tabs {
    .tab-default {
      font-size: 1.1rem;
    }
  }
}

.sc-range-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .sc-range-item {
    // flex: 0.45;
    width: 100%;

    &:first-child {
      margin-right: 1.5rem;
    }
    &:last-child {
      margin-left: 1.5rem;
    }

    .sc-select-value {
      width: 100%;
      height: 2.2rem;
      padding: 0.5em;
      //margin-top: 10px;
      box-sizing: border-box;
      border: 1px solid var(--border);
    }
  }

  .MuiOutlinedInput-root {
    width: 100%;
    height: 2.6rem;
    border-radius: 0;
  }
}

.sc-print-wrap {
  min-width: 400px;
}

.sc-devider {
  height: 1px;
  background-color: var(--border);
}

.sc-range-label {
  font-size: 0.9rem;
  line-height: 0.9rem;
  letter-spacing: 0.08em;
  margin-bottom: 0.5em;
  color: var(--text-inactive);
}

.sc-dialog-range {
  margin: 1em 0;
  font-size: 1.1rem;
  line-height: 1.1rem;
  letter-spacing: 0.08em;
  color: var(--text-inactive);
}

.sc-dialog-title {
  margin-bottom: 0.8rem;
}

.sc-dialog-row {
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;

  .checkbox-item {
    width: 50%;
  }

  &:nth-child(3) {
    justify-content: center;
    .button-base {
      &:first-child {
        margin-right: 1.5rem;
      }
      &:last-child {
        margin-left: 1.5rem;
      }
    }
  }
  &:last-child {
    justify-content: center;
    margin-bottom: 0;
  }
}

.sc-line-input {
  width: 23.3rem;
}

.mode-mobile {
  .sc-line-input {
    width: 100%;
  }
}

.static-share-link-row {
  margin-top: 1.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  .static-share-link-value {
    flex: 1;
  }

  .static-share-button {
    width: 2.2rem;
    margin-left: 0.5rem;
    padding-top: 0;
    
    .egw-font-icon {
      font-size: 1rem;
      margin: 0;
      color: var(--btn-st-text);
    }
  }
}

.hl-folder-wrap {
  display: flex;
  flex-direction: column;
  width: 17rem;
  max-height: calc(90vh - 5rem);

  .sc-tree-scroll {
    max-height: calc(90vh - 12rem);
    min-height: 2rem;
  }

  .active-row {
    .sc-tree-title-part {
      .sc-tree-expand, .sc-tree-folder {
        fill: var(--icon-active);
      }
      .sc-tree-title {
        color: var(--icon-active);
        font-weight: 700;
      }
    }
  }
}

.mode-blind {
  .hl-folder-wrap {
    .active-row {
      .sc-tree-title-part {
        .sc-tree-expand, .sc-tree-folder {
          fill: var(--icon-background);
        }
        .sc-tree-title {
          color: var(--icon-background);
        }
      }
    }
  }
}

.mode-mobile {
  .sc-dialog-container {
    padding-right: 0;
  }
  .hl-folder-wrap {
    max-width: 100%;
  }

  &.mode-zoom-150 {
    .hl-color-row:not(.sc-color-row-grid-type):not(.sc-color-row-list-type) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.mode-zoom-200, .mode-tablet {
  .sc-folder-wrap {
    margin-top: 1rem;
  }
}

.mode-tablet, .mode-mobile {
  .sc-print-wrap {
    width: 100%;
    min-width: unset;
  }
  .dialog-container {
    &.highlight-dialog {
      .sc-folder-wrap {
        margin-top: 0.3rem;
      }
    }
  }
}
