@import "src/styles/mixins";
@import "src/shared/styles/mixins";

.player-controls,
.timer-controls,
.volume-controls,
.progress-controls,
.track-cover {
  width: 100%;
}

.icon-hover-slider {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;

  &.is-expanded {
    .icon-hover-slider__slider-wrapper__inner {
      left: 0;
      opacity: 1;
    }
  }

  &.is-disabled {
    .icon-hover-slider__slider-wrapper__inner {
      opacity: 0 !important;
    }
  }

  &.has-slide-direction-left {
    flex-direction: row-reverse;

    .icon-hover-slider__slider-wrapper__inner {
      left: unset !important;
      right: -100%;
    }

    &.is-expanded {
      .icon-hover-slider__slider-wrapper__inner {
        right: 0;
      }
    }
  }
}

.icon-hover-slider__slider {
  padding: 0;
}

.icon-hover-slider__icon-wrapper {
  z-index: 1;
  display: flex;
  align-items: center;
}

.icon-hover-slider__slider-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.icon-hover-slider__slider-wrapper__inner {
  position: relative;
  left: -100%;
  opacity: 0;
  width: 8.3rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.6rem;
  padding: 0 0.5rem;
  align-items: center;
  transition: 1s;
}

.icon-hover-slider__slider-label-right {
  width: 2rem; // To reserve 3 digits width.
  font-size: 1.1rem;
  line-height: 1.3rem;
  letter-spacing: 0.1em;
  color: var(--icon-color);
  text-align: right;
}

.timer-controls {
  grid-area: timer-controls;
}

.timer-controls-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0;
  margin-left: 0.1rem;
  border: none;
  background: none;
  color: var(--icon-color);
  outline: none;
  &:hover {
    .timer-controls-icon__label {
      color: var(--icon-hover);
    }
  }
}

.timer-controls-icon__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%,-50%);
}

.timer-controls-icon__label {
  pointer-events: none;
  z-index: -1;
  position: relative;
  opacity: 0;
  width: 4rem;
  padding: 0.3rem;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}

.volume-controls {
  grid-area: volume-controls;
}

.text-controls {
  &.value-is-not-zero {
    .timer-controls-icon__label {
      opacity: 1;
    }
    .text-controls__text-current {
      color: var(--text-link);
    }
    .text-controls__text {
      text-decoration: none;
      color: var(--text-default);
    }
  }
}

.text-controls__text {
  text-decoration: line-through;
  color: var(--gray);

  &.clickable {
    cursor: pointer;
  }
}

.text-controls__controls,
.timer-controls__controls {
  .icon-button-base {
    margin-left: 0.4rem;
  }
}

.playback-controls {
  grid-area: playback-controls;
}

.playback-controls__icon {
  height: 1.2rem;
}

.progress-controls {
  grid-area: progress-controls;
  box-sizing: border-box;
  &.with-slider {
    padding: 0 0.2rem; // Small side indents to not crop the slider thumb.
  }
}

.progress-controls__time-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  line-height: 1.3rem;
  letter-spacing: 0.1em;
  color: var(--icon-color);
}

.track-cover {
  text-align: center;
}

.track-cover__text-wrapper {
  margin-top: 1rem;
}

.track-cover__text-wrapper__text {
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.08em;
  text-align: center;
  margin-top: 1.8rem;
  color: var(--primary-light);
  display: block;
  &:last-child {
    margin-top: 0.2rem;
  }
}

.track-cover__text-wrapper__text-track {
  letter-spacing: 0.05em;
  color: var(--primary);

  @include lineClamp(2, 1.5rem)
}

.player-controls {
  grid-area: player-controls;
  display: grid;
  grid-gap: 0.5rem;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(3, auto);

  &.skip-buttons-shown {
    grid-template-columns: repeat(5, auto);
  }

  & > i {
    text-align: center;
    min-width: 1em;
  }
}

.track-cover {
  grid-area: track-cover;
}

.track-cover__img {
  max-width: 100%;
  width: auto;
  height: 15em;
}

.mode-mobile {
  .icon-hover-slider__slider-label-right {
    font-size: 0.9rem;
  }

  .timer-controls-icon__label {
    font-size: 0.8rem;
  }

  .timer-controls-icon__svg {
    height: 1.7rem;
  }
}

.mode-text {
  .track-cover__text-wrapper {
    margin-bottom: 2rem;
    margin-top: 0;
    span {
      display: block;
    }
  }

  .progress-controls__time-wrapper {
    color: var(--text-default);
  }

  .text-controls__text-current,
  .progress-controls:not(.with-slider) .progress-controls__time-wrapper__current {
    color: var(--text-link);
  }

  .text-controls {
    .icon-button-base {
      width: 0.9rem;
      height: 0.9rem;
    }
  }
}
