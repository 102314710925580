@import "../../shared/styles/egw-web-font";

.swipeable-wrapper {
  &::after {
    position: fixed;
    z-index: 400;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    vertical-align: middle;

    flex: 1;
    display: none;
    align-items: center;
    height: 100vh;
    padding: 0.3rem;

    letter-spacing: -0.05em;
    font-family: "egw-web-font";
    font-weight: 700;
    font-size: 2rem;

    opacity: 0.45;
    color: var(--primary-light);

    user-select: none;
    pointer-events: none;
    content: map-get($egw-web-font-map, "chevron-left") map-get($egw-web-font-map, "chevron-right");
  }

  &.show-icon-swiping-left,
  &.show-icon-swiping-right {
    &::after {
      display: flex;
    }
  }

  &.show-icon-swiping-left {
    &::after {
      justify-content: flex-start;
    }
  }

  &.show-icon-swiping-right {
    &::after {
      justify-content: flex-end;
    }
  }
}
