.please-sign-in-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 3rem 0;

  svg {
    width: 4.4rem;
    height: 4.4rem;
    &.sign-in-icon {
      fill: var(--primary);
    }
  }

  .please-sign-in-page-content {
    text-align: center;

    .__text {
      font-size: 2rem;
      line-height: 2.1rem;   
      letter-spacing: 0.1em;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .button-base {
      min-width: 120px;
      font-size: 0.9rem;
    }
  }
}

.mode-mobile {
  .__text {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }
}
