.help-back {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-wrap {
  position: fixed;
  margin: auto;
  padding: 2rem;
  z-index: 51;
  opacity: 0.9;
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  border-radius: 0.2rem;
  box-sizing: border-box;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  background-color: var(--popup-background);
}

.help-title {
  font-size: 1.5rem;
}

.help-content {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.help-item {
  font-size: 0.9rem;
  color: var(--text-default);
  font-family: Roboto, sans-serif;
  .value {
    font-weight: bold;
  }
}
