.accordion-book-translations {
  margin-top: 0.6rem;

  .accordion_head {
    margin-bottom: 1rem;
  }
}

.accordion-book-translations_text-lang {
  text-transform: uppercase;
}
