@import "../../styles/variables.module.scss";

$search-field-padding-top: 0.65rem;

.search-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border);
  height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.search-list,
.search-history-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.search-list {
  .empty-search-recommended-filter {
    font-family: "Roboto Condensed", sans-serif;
    margin-top: 0.6rem;
    padding: 0 0.6rem;

    .current-filter,
    .to-filter {
      color: var(--bc-text-active);
    }

    .to-filter {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.search-list-empty-placeholder{
  padding: 0.8rem;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  margin-top: 1rem;
  color: var(--text-inactive);
  text-align: center;
  line-height: 1.2;
}

.search-result-header {
  height: $panel-header-height;
  padding: 0 0.5rem;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  border-bottom: 1px solid var(--border-sec);

  .results-count,
  .results-related,
  .results-context {
    padding: 0;
    font-size: 1.1rem;
    color: var(--primary);
  }

  .results-related {
    margin-left: auto;
    svg {
      margin-top: 0;
    }
  }
  .results-count.empty-search {
    color: var(--bc-text);

    .empty-search-comment {
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 1.125;
    }
  }

  .results-context {
    text-align: right;
  }
}

.search-history-list {
  box-sizing: border-box;
  border-top: 1px solid var(--border);
}

.search-history-item {
  box-sizing: border-box;
  cursor: pointer;

  .search-history-item-inner {
    margin: 0 15px;
    border: 1px solid transparent;
    height: 2.2rem;
    border-bottom-color: var(--border);
    box-sizing: border-box;
  }

  .search-item-title {
    font-size: 1rem;
    display: flex;
    height: 100%;
    align-items: center;

    .search-item-title-index {
      margin-right: 1rem;
    }
    .search-item-title-text {
      overflow: hidden;
      width: 85%;
      text-overflow: ellipsis;
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  &.is-focused,
  &:hover {
    background: var(--highlighted-elem-egw);
    color: var(--text-hover);

    .search-history-item-inner {
      border-bottom-color: transparent;
    }
  }
}

.search-external-item {
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom-color: var(--border);

  .search-external-item-inner {
    margin: 0 15px;
    height: 2.2rem;
    box-sizing: border-box;
  }

  .search-item-title {
    font-size: 1rem;
    display: flex;
    height: 100%;
    align-items: center;

    .search-item-title-index {
      margin-right: 1rem;
    }
    .search-item-title-text {
      overflow: hidden;
      width: 85%;
      text-overflow: ellipsis;
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  &.is-focused {
    border: 1px solid var(--outline-border);

    .search-external-item-inner {
      border-bottom-color: transparent;
    }
  }
}
.search-title-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-item-badge-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.25rem;
}
.search-item-badge {
  color: var(--tertiary);
  font-family: "Bebas Neue", sans-serif;
  border: 2px solid var(--tertiary);
  border-radius: 0.3rem;
  padding: 0.2rem;
  line-height: 1;

  &:hover {
    cursor: pointer;
    color: var(--tertiary-light);
    border: 2px solid var(--tertiary-light);
  }
}

.search-item-title,
.dictionary-item-title {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  line-height: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1em;
  text-transform: uppercase;

  .search-item-title-index {
    color: var(--text-default);
  }

  .search-item-title-text {
    font-weight: bold;
  }
}

.default-select {
  /* Vertical align as the icons have */
  display: flex;
  align-items: center;
  /* ============= */
  max-height: 2rem;

  &.font-list-container {
    max-height: none;
  }
}

.right-panel-search-list {
  .ReactVirtualized__Grid__innerScrollContainer {
    background-color: var(--background);
  }
}

.search-item-wrap,
.dictionary-item-wrap {
  font-family: "Roboto Condensed", sans-serif;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  border-bottom-color: var(--icon-color);
  height: 100%;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0 0.5rem;

  &.bookTitle {
    font-weight: bold;
  }

  &.egwwritings,
  &.bookTitle,
  &.reference,
  &.bible,
  &.apl {
    .search-item-snippet {
 
      color: var(--text-default);

      &:hover {
        color: var(--text-default);
      }
    }
  }

  &.egwwritings mark {
    background-color: var(--category-egw-search-mark);
  }

  &.bookTitle mark {
    background-color: var(--category-egw-search-mark);
  }

  &.wordTitle mark {
    background-color: var(--category-egw-search-mark);
  }

  &.bible mark {
    background-color: var(--category-bible-search-mark);
  }

  &.apl mark {
    background-color: var(--category-apl-search-mark);
  }

  &.reference mark {
    background-color: var(--category-references-search-mark);
  }

  &:hover,
  &.in-reader {
    &.egwwritings,
    &.external,
    &.bookTitle,
    &.wordTitle {
      background: var(--highlighted-elem-egw);
      color: var(--category-egw);
    }

    &.reference {
      background: var(--highlighted-elem-reference);
    }

    &.bible {
      background: var(--highlighted-elem-bible);
    }

    &.apl {
      background: var(--highlighted-elem-apl);
    }
  }

  &.is-focused {
    border: 1px solid var(--outline-border);
  }
}

.dictionary-item-wrap {
  height: auto;
  .dictionary-item-snippet {
    height: 4.6em;
  }
}

.search-item-snippet,
.dictionary-item-snippet {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  color: var(---text-default);
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.25;
}

.search-page-value {
  border: 1px solid var(--icon-color);
  margin: 0 0.5rem;
  font-style: normal;
  background-color: var(--background);
  font-family: "Roboto Condensed", sans-serif;
  color: var(--text-default);
  font-weight: bold;
  font-size: 1rem;
  max-width: 3rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  transition: border-color 0.2s;
  -moz-appearance: textfield;

  &:focus {
    border-color: var(--sc-selected-color);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.book-code-panel {
  display: inline-flex;
  align-items: center;
  margin-left: 0.25rem;
  color: var(--icon-color);

  .title {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.05rem;
  }
}

.search-nav-panel {
  display: inline-flex;
  align-items: center;
}

.searchPanel {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: $panel-header-height;
  align-items: flex-start !important; // same
  padding: 0 0.5rem;
  border-bottom: 1px solid var(--border);
}

.searchPanelLeft {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  padding: 0.2rem 0;

  .panel-base {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .close-icon {
      // margin-left: auto;
      position: absolute;
      right: 0;
    }
  }
}

.searchPanelLeft input {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 0.25rem;
}

.suggestionItem {
  color: var(--primary);
  font-size: 1rem;
  padding: 8px 8px 8px 0;
  cursor: pointer;
}

.suggestionItem:hover {
  color: var(--primary);
}

.search-wrap-base {
  border: 1px solid var(--border-sec);
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 1.5rem;
  padding: 0 0.6rem;
  background-color: var(--search-background);
  justify-content: space-between;
  transition: border-color 0.2s;

  &:focus-within {
    border: 1px solid var(--border-sec);
  }

  .text-only {
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;
    font-style: normal;
    font-weight: normal;
    padding: 0 12px;
    color: var(--text-inactive);

    &:hover {
      color: var(--text-link-hover);
    }

    &.searchButton {
      margin: 0;
    }

    &.search-filter-icon {
      border-right: 1px solid var(--border-sec);
      margin-left: 0;
    }

    &.search-guide-icon,
    &.search-show-search-icon {
      border-left: 1px solid var(--border-sec);
    }
  }
}

.searchField {
  border: none;
  outline: none;
  font-size: 0.75rem;
  // padding: $search-field-padding-top 0.25rem 0.5rem;
  background: none;
  width: 100%;
  min-width: 0; // Prevent FF overgrow.
  text-overflow: ellipsis;

  &:focus {
    border: none;

    &::placeholder {
      color: transparent;
    }
  }
}

.search-icon {
  flex-shrink: 0;
  font-size: 0.9rem;
  cursor: pointer;
  color: #9f9f9f; // [EGWW-3133] must be not changable by theme
  line-height: 1.2;

  &:hover {
    color: #1c8ab3; // [EGWW-3133] must be not changable by theme
  }
}

.search-guide-icon.icon-button-active,
.search-filter-icon.icon-button-active {
  color: #115b77;
}

.searchButton {
  margin: 0 0.5rem 0 0;
  cursor: pointer;
}

.search-button-wrap {
  display: inline-flex;

  .searchButton:last-child {
    margin-right: 0;
  }
}

.searchLong {
  font-size: 0.9rem;
}

.orderOptions,
.fontOptions {
  width: 16rem;
}

.snippetOptions {
  width: 5.5rem;
}

.dictionary-options {
  width: 18rem;
}

.periodOptions,
.searchOptions {
  width: 11.6rem;
}

.orderOptions,
.snippetOptions,
.periodOptions,
.searchOptions,
.dictionary-options,
.fontOptions {
  position: fixed;
  z-index: var(--tooltip-z-index);
}

.reader-icons-container .search-dictionary {
  padding: 8px 4px;
}

.results-count {
  max-height: 2em;
  overflow: hidden; 
  letter-spacing: 0.05rem;

  .result-value {
    text-decoration: underline;
    margin-left: 0.25rem;
  }
}

.right-panel-content {
  .search-result-header {
    padding: 0 0.5rem;
    .panel-base {
      display: inline-flex;
    }

    .results-count {
      font-size: 1rem;
      line-height: 1em;
      max-height: 2em;
      overflow: hidden;
      flex: 1;
      color: var(--bc-text-active);
      text-overflow: ellipsis;
      white-space: nowrap;

      &.results-history-count {
        padding-right: 0;
      }
    }

    .results-count-history-icon {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      fill: var(--bc-text-active);
    }
  }

  .empty-search {
    color: var(--bc-text);

    .empty-search-text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .empty-search-comment {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1.15;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.search-top-panel-wrapper,
.searchPanel {
  &.text-only {
    .panel-popup {
      box-shadow: none;
      border: 1px solid var(--border-sec);

      .default-select {
        position: relative;
      }

      .ddContainer {
        left: 0.75rem;
        width: 90%;
      }
    }
  }
}

.search-placeholder {
  color: var(--bc-text);
  letter-spacing: 0.02em;
  margin: 0 1rem auto 1rem;
  align-self: start;
  text-align: left;
  font-size: 1.1rem;
}

.search-panel__popup-tree-dots {
  padding: 0.45rem;
  background: var(--popup-background);
  line-height: 1.1;

  .icon-button-base {
    margin: 0 0.25rem;

    &.text-only {
      overflow: inherit;
    }
  }

  .default-select {
    display: inline-block; // Vertical align as the icons have.
  }

  .search-nav-panel,
  .book-code-panel {
    align-items: center;
    display: flex; // Moves down, below the icons.
    margin-top: 0.25rem;  
  }

}

.search-text-label {
  margin-top: 0.25rem;
  font-weight: bold;
  letter-spacing: 0.01rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
  margin-left: 0.25rem;
  color: var(--text-inactive);
  &:hover {
    color: var(--text-link-hover);
    cursor: pointer;
  }
  &.active {
    color: var(--text-link);
  }
}

.mode-mobile {
  .search-list {
    flex-grow: 1;
  }

  .search-wrapper {
    height: 100%;
  }

  .search-item-wrap {
    display: grid;
    grid-template-rows: 1.5rem 1fr;
    padding: 0 0.9rem;
  }
}

.mode-tablet {
  .menuTreeWrap {
    margin-right: 0.5rem;
  }
}

.mode-mobile,
.mode-tablet {
  .search-wrap-base {
    flex: 1;
  }
}
.mode-blind {
  .searchField {
    caret-color: var(--caret-color);
    color: var(--caret-color);
  }
  .search-item-wrap,
  .dictionary-item-wrap {
    mark {
      color: var(--search-background);
      background-color: var(--category-egw-search-mark);
    }
    &:hover,
    &.in-reader {
      .search-item-snippet,
      .dictionary-item-snippet {
        color: var(--text-hover) !important;
      }
      .search-item-title,
      .dictionary-item-title {
        &.egwwritings,
        &.reference,
        &.bible,
        &.apl {
          color: var(--highlighted-category-egw) !important;
        }
        mark {
          color: var(--white);
          background-color: var(--highlighted-category-egw);
        }
      }
      .egwwritings,
      .bookTitle,
      .reference,
      .bible,
      .apl {
        color: var(--highlighted-category-egw);
        mark {
          color: var(--white);
          background-color: var(--highlighted-category-egw);
        }
      }
    }
  }
  .search-history-item {
    &.is-focused,
    &:hover {
      .search-item-title-text,
      .search-item-title-index {
        color: var(--icon-active);
      }
    }
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .right-panel-content .search-result-header .search-top-panel-wrapper {
      margin-right: 0;
    }

    .search-item-wrap {
      padding: 0 0.5rem;
    }
  }
}

.mode-text {
  .searchPanelLeft {
    .show-popup-text-button {
      padding-right: 1rem;
    }
  }

  .search-panel__popup-tree-dots {
    display: flex;
    flex-direction: column;

    .icon-button-base {
      padding: 0.2rem;
    }

    .default-select {
      display: flex;
    }
  }
}

.search-list-correction-wrap {
  font-family: "Roboto Condensed", sans-serif;
  padding: 0 0.6rem;
  margin-top: 0.6rem;

  .search-list-correction-value {
    font-weight: bold;
    color: var(--text-link);
    &:hover {
      cursor: pointer;
      color: var(--text-link-hover);
    }
  }
  .correction-delimiter {
    color: var(--text-default);
    margin: 0 0.25rem;
  }
}

.search-list-suggestion-wrap {
  font-family: "Roboto Condensed", sans-serif;
  padding: 0 0.6rem;
  margin-bottom: 0.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .search-list-suggestion-value {
    color: var(--text-link-hover);
    font-weight: bold;
  }
  .search-list-suggestion-item {
    margin-top: 0.6rem;
    margin-right: 0.6rem;
  }
}

.shortcut-content-top-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: 0 0.5rem;

  i {
    margin-left: 0.5rem;
  }
}

.shortcut-item-wrap {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid var(--border);
  line-height: 1.2;

  .shortcut-title {
    font-weight: bold;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    font-family: "Bebas Neue", sans-serif;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .shortcut-content {
    font-family: "Roboto", sans-serif;
    color: var(--text-default);
  }
}
