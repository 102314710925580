.popup-background {
  z-index: var(--popup-wrap-z-index);
  position: fixed;

  &.popup-main {
    z-index: calc(var(--popup-wrap-z-index) + 2);
  }
}

.popup-main {
  /* For hover zone (e.g. not to close popup when mouse is moved to the side on a footnote). */
  &.has-hover-zone {
    &:before {
      position: absolute;
      left: 0;
      right: var(--target_width);
      height: var(--target_height);
      bottom: calc(var(--target_height) * -1);

      content: "";
    }

    &.is-shown-at-bottom {
      &:before {
        top: calc(var(--target_height) * -1);
      }
    }

    &.is-shown-at-right {
      &:before {
        left: var(--target_width);
        right: 0;
      }
    }
  }
  /* ==================================== */
}

.popup-main + .modalBack {
  z-index: calc(var(--popup-wrap-z-index) + 1);
}

.popup-inner {
  background: var(--popup-background);
  box-shadow: 0 10px 20px var(--shadow);
}

.popup-background,
.popup-inner,
.popup-background .scrollbars-render-view {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vh;
}

.toast-view {
  position: fixed;
  top: 100px;
  user-select: none;
  background: var(--background);
  border-radius: 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 1rem 0.5rem;
  width: 16rem;
  max-width: 100%;
  z-index: var(--toast-z-index);
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--text-inactive);
}

.toast-error {
  color: var(--category-bible);
}

.toast-success {
  color: var(--category-apl);
}

.toast-warning {
  color: var(--secondary);
}

/*start tooltip classes*/

.tooltip-wrap {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--tooltip-z-index);
}

.tooltip-view {
  background-color: var(--popup-background);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.75rem;
  line-height: 1.1;
  max-width: 12rem;
  box-sizing: border-box;
  padding: 0.3rem 0.5rem;
  position: relative;
  color: var(--text-inactive);
  border-radius: 0.1rem;
  box-shadow: 0 4px 12px var(--shadow);

  .tooltip-text {
    word-break: break-all;
    text-overflow: clip;
  }

  &.content{
    font-size: 1rem;
    max-width: 15rem;
  }
}

.tooltip-view [class*="pointer"] {
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
}

$offsetSmall: 0.5rem;
$offsetBig: 0.75rem;

.pointer-left-top {
  top: -$offsetSmall;
  left: $offsetBig;
  border-width: $offsetSmall 0 0 $offsetSmall;
  border-color: transparent transparent transparent var(--background);
}

.pointer-center-top {
  top: -$offsetSmall;
  position: absolute;
  border-width: $offsetSmall 0 0 $offsetSmall;
  border-color: transparent transparent transparent var(--background);
}

.pointer-right-top {
  top: -$offsetSmall;
  right: $offsetBig;
  border-width: 0 0 $offsetSmall $offsetSmall;
  border-color: transparent transparent var(--background) transparent;
}

.pointer-left-bottom {
  bottom: -$offsetSmall;
  left: $offsetBig;
  border-width: $offsetSmall $offsetSmall 0 0;
  border-color: var(--background) transparent transparent transparent;
}

.pointer-right-bottom {
  bottom: -$offsetSmall;
  right: $offsetBig;
  border-width: 0 $offsetSmall $offsetSmall 0;
  border-color: transparent var(--background) transparent transparent;
}

.context-menu-item {
  font-family: "Roboto Condensed", sans-serif;
  padding: 0.5rem;
  color: var(--bc-text);
  font-size: 1rem;
  display: flex;
  align-items: center;

  .egw-font-icon {
    margin-right: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    color: var(--primary);
  }

  &.active {
    color: var(--primary);
  }

}

.mode-blind {
  .popup-inner {
    background: var(--popup-background);
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .toast-view {
    border: 1px solid var(--border);
  }
  .tooltip-view {
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .pointer-left-top {
    border-color: transparent transparent transparent var(--border);
  }
  
  .pointer-center-top {
    border-color: transparent transparent transparent var(--border);
  }
  
  .pointer-right-top {
    border-color: transparent transparent var(--border) transparent;
  }
  
  .pointer-left-bottom {
    border-color: var(--border) transparent transparent transparent;
  }
  
  .pointer-right-bottom {
    border-color: transparent var(--border) transparent transparent;
  }
}

.mode-text {
  .popup-inner {
    box-shadow: none;
    border: 1px solid var(--border);
  }
}
