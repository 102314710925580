.badge {
  display: flex;
  position: relative;
}

.badge__label {
  position: absolute;
  background-color: var(--primary);
  font-family: "Roboto Condensed";
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 0.6rem;
  height: 1.2rem;
  min-width: 0.8rem;
  padding: 0 6px;
  box-sizing: border-box;
  z-index: 1;
  align-items: center;
  align-content: center;
  border-radius: 0.65rem;
  justify-content: center;
  border: 3px solid var(--background);
  color: var(--btn-st-text);
  pointer-events: none;
}

.badge__label.top {
    top: 0;
}
.badge__label.bottom.right {
    bottom: 0;
    right: 0;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 0%;
}
.badge__label.left {
    left: 0;
}
