@import "../../styles/variables.module.scss";

.sc-tree-wrap {
  min-width: 250px;
  flex: 1;
  height: 100%;
  position: relative;

  .draggable {
    outline: none;
    &:nth-child(even) {
      background-color: var(--tree-row-odd);
    }

    &:nth-child(odd) {
      background-color: var(--tree-row-even);
    }
  }
}

.drag-drop-context,
.drag-drop-context > div {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.study-center-placeholder {
  margin: 1rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.3rem;
  color: var(--bc-text);
}

.study-center-please-login {
  margin: 1rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-family: "Roboto Condensed", sans-serif;
  color: var(--primary-light);
  text-decoration: underline;
  cursor: pointer;
}

.draggable-active {
  background-color: var(--background);
}

.sc-tree-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  height: $panel-header-height;
  align-items: center;
  padding-right: 1.1rem;
  justify-content: space-between;
  cursor: pointer;

  &.active-nav {
    font-weight: bold;
  }

  &.active,
  &:hover {
    background-color: #67d3fb80;

    .sc-tree-folder,
    .sc-tree-expand {
      fill: var(--icon-active);
    }
    .sc-tree-title {
      color: var(--icon-active);
    }
  }

  &:hover {
    .sc-tree-menu {
      visibility: visible;
    }
  }
  .sc-dots-menu-icon {
    min-width: 1.1rem;
  }
}

.sc-tree-wrap {
  .sc-tree-row.active-row {
    .sc-tree-title-part {
      .sc-tree-expand,
      .sc-tree-folder {
        fill: var(--icon-active);
      }
      .sc-tree-title {
        color: var(--icon-active);
        font-weight: 700;
      }
    }
  }
}

.sc-tree-wrap .draggable.sc-tree-row:hover {
  background-color: #67d3fb80;
}

.sc-tree-title-part {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;

  &.isEditor {
    .sc-tree-icon {
      margin-left: 0.3rem;
    }

    .sc-check-icon {
      margin-left: 0.5rem;
      color: var(--category-egw);
      fill: var(--category-egw);
    }
  }
}

.sc-check-icon {
  flex-shrink: 0;
}

.sc-tree-menu-top {
  svg {
    margin-left: 0.2rem;

    &:hover {
      fill: var(--tiles-st);
    }
  }
}

.sc-tree-menu {
  visibility: hidden;

  svg {
    margin-left: 4px;
  }
}

.sc-tree-title {
  user-select: none;
  margin-left: 4px;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 197.83%;
  cursor: pointer;
  color: var(--text-default);
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 2rem;

  &:hover {
    color: var(--text-link-hover);
    text-decoration: underline;
  }
}

.sc-tree-text {
  margin-left: 4px;
  max-width: 300px;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
  font-family: "Roboto Condensed", sans-serif;
  color: var(--text-inactive);

  &.locked-tab {
    max-width: 120px;
  }
}

.sc-tree-expand {
  cursor: pointer;
  width: 0.7rem;
  min-width: 0.7rem;
  height: 0.7rem;
  transition: transform 0.25s;
  fill: var(--icon-color);

  &.is-expanded {
    transform: rotate(90deg);
  }
}

.sc-tree-folder {
  color: var(--icon-color);
  margin-left: 0.2rem;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
}

.sc-tree-icon {
  color: var(--icon-color);
  font-size: 1rem; 
  margin-left: 0.5rem; 
}

.sc-tree-placeholder {
  background: var(--primary-light);
  height: 3px;
}

.sc-tree-dragged {
  box-shadow: 0 4px 12px var(--shadow);
  padding-left: 0;
  user-select: none;
  width: 300px;
  box-sizing: border-box;
  max-height: 4em;
  z-index: 500;
  opacity: 1;
}

.sc-tree-selected {
  color: var(--text-link);
  text-decoration: underline;
}

.sc-tree-menu-wrap {
  padding: 8px;
}

.sc-tree-menu-icon-row {
  white-space: nowrap;
  border-top: 1px solid var(--border);
  padding-top: 8px;

  &:first-child {
    border: none;
    padding-top: 0;
  }

  .rcm-icon {
    font-size: 1.3rem; 
    margin: 0 0.6rem;
  }
}

.mode-text {
  .sc-tree-menu-top {
    .icon-button-base {
      color: var(--gray);
      margin-right: 0.3rem;
      padding-right: 0.3rem;
      border-right: 1px solid var(--gray);

      &:last-of-type {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
    }
  }
}

.mode-blind {
  .sc-tree-wrap {
    .sc-tree-row.active,
    .sc-tree-row:hover {
      background-color: var(--selection-background);
      color: var(--selection-color);
      svg {
        fill: var(--selection-color);
      }
      .sc-tree-title,
      .sc-tree-text {
        color: var(--selection-color);
      }
    }

    .sc-tree-row.active-row:not(.active) {
      .sc-tree-title-part {
        .sc-tree-expand,
        .sc-tree-folder {
          fill: var(--icon-background);
        }
        .sc-tree-title {
          color: var(--icon-background);
        }
      }

      &:hover {
        .sc-tree-title-part {
          .sc-tree-expand,
          .sc-tree-folder {
            fill: var(--selection-color);
          }
          .sc-tree-title {
            color: var(--selection-color);
          }
        }
      }
    }
  }
}
