$arrow__max-width: 97px;
$arrow__right-offset: 0.7rem;

.lib-hint-container__popup {
  .scrollbars-render-view {
    overflow-x: hidden !important;
  }

  .popup-inner {
    background-color: var(--lib-hint-background);
  }
}

.lib-hint__root {
  box-sizing: border-box;
  max-width: 45rem;
  display: grid;
  padding: 0.9rem;
  margin-left: auto;
  margin-right: auto;

  .categoryListItem {
    box-shadow: none;
    pointer-events: none;
    background-color: var(--tiles-bg);
  }
  a {
    cursor: default;
  }
}

.lib-hint__head {
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--white);
  text-align: center;
  font-weight: bold;
}

.lib-hint__body {
  display: grid;
}

.lib-hint__text {
  grid-area: hint-text;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--white);
}

.lib-hint-category-content {
  &:nth-of-type(1) {
    position: relative; // Is needed for the arrow element.

    grid-area: category-first;
  }

  &:nth-of-type(2) {
    grid-area: category-second;
  }

  &:nth-of-type(3) {
    grid-area: category-third;
  }
}

.lib-hint__actions {
  grid-area: actions;
}

.lib-hint__btn-ok {
  width: 100%;
}

.lib-hint__category-text {
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--white);
  font-weight: bold;
  text-align: center;
}

.lib-hint__arrow {
  position: absolute;
  top: 1rem;
  right: -#{$arrow__right-offset};

  min-width: $arrow__max-width;

  transform: translateX(100%);
}

.lib-hint__root:not(.need-compact-view) {
  .lib-hint__root {
    grid-row-gap: 2rem;
  }

  .lib-hint__head {
    font-size: 4rem;
  }

  .lib-hint__body {
    grid-gap: 1.45rem 0.9rem;
    grid-template-rows: auto 2.2rem auto;
    grid-template-areas:
      "category-first hint-text hint-text"
      "category-first actions actions"
      "category-second category-third .";
  }

  .lib-hint__text {
    align-self: flex-end;
    padding-left: $arrow__max-width;
    margin-left: $arrow__right-offset;
  }

  .lib-hint__actions {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
  }

  .lib-hint__btn-ok {
    max-width: 258px;
    margin-left: auto;
  }

  .lib-hint__category-text {
    font-size: 2rem;
  }
}

.lib-hint__root.need-compact-view {
  $content-padding-side: 1.2rem;

  padding-top: 1.9rem;
  grid-row-gap: 0.9rem;
  grid-template-rows: auto 1fr;
  align-items: flex-start;

  .lib-hint__head {
    overflow: hidden; // Prevent block width overflow.
    font-size: 1.8rem;
  }

  .lib-hint__body {
    padding-left: $content-padding-side;
    padding-right: $content-padding-side;
    grid-row-gap: 1.2rem;
    grid-template-areas:
      "hint-text"
      "category-first"
      "category-second"
      "category-third"
      "actions";
  }

  .lib-hint__text {
    font-size: 0.9rem;
    text-align: center;
    margin-left: -#{$content-padding-side};
    margin-right: -#{$content-padding-side};
    margin-bottom: 0.9rem;
  }

  .lib-hint__category-text {
    font-size: 1rem;
  }

  .lib-hint__arrow {
    position: absolute;
    top: -0.5rem;
    right: -4rem;

    height: 0.8rem;
    min-width: 5.4rem;

    transform: matrix(-.5,.77,.87,.5,0,0) scale(-1);
  }

  .lib-hint-category-content {
    display: grid;
    grid-row-gap: 0.9rem;

    .categorySpaceItemWrap {
      padding-top: 0.45rem;
      padding-bottom: 0.45rem;
      margin-left: auto;
      margin-right: auto;
      box-shadow: none;
    }

    .categoryListItem {
      flex-wrap: wrap; // Allow wrap children. Needed on 320px width screen, zoom 120+.
    }
  }
}

.mode-tablet,
.mode-desktop {
  .lib-hint__root.need-compact-view {
    .lib-hint-category-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 14rem; // Tablet, desktop ".categorySpaceItemWrap" item width.
    }
  }
}

.mode-mobile {
  .lib-hint-category-content {
    .categorySpaceItemWrap {
      height: unset;
    }
  }
}

.mode-blind {
  .lib-hint-container {
    .categorySpaceItemWrap {
      /* Prevent black bg tile on black bg in ACC Dark. */
      background-color: var(--white);
      color: var(--tab-inactive);
      /* ============= */
    }
  }
}