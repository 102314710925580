.search-print {
  // diff for scroll wrapper
  padding-bottom: 17px;
}

.search-print-title {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  color: var(--gray);
  margin-bottom: 1.1rem;
  display: block;
}

.search-print-input {
  box-sizing: border-box;
  border: 1px solid var(--icon-color);
  font-style: normal;
  background-color: var(--background);
  font-family: "Roboto Condensed", sans-serif;
  color: var(--text-default);
  font-weight: bold;
  padding: 0 0.9rem;
  font-size: 1rem;
  line-height: 1.2rem;
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  outline: none;
  transition: border-color 0.2s;

  &:focus {
    border-color: var(--sc-selected-color);
  }
}

.search-print-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-print-label {
  display: block;
  margin-bottom: 0.5rem;
}

.search-tabs {
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 1.6rem;
}

.search-tab {
  &:first-child {
    margin-right: 1.1rem;
  }
}

.tab-wrap {
  &.search-tabs {
    .tab-default {
      font-size: 1.1rem;
      &:first-child {
        margin-right: 1.1rem;
      }
    }
  }
}

.search-dialog-container {
  box-sizing: border-box;
  padding: 0.9rem;
  width: 27.2rem;
}

.search-print-column {
  width: 100%;
}

.search-dialog-container-m {
  width: 100%;

  .pub-row-space-24 {
    width: 100%;
    height: 24px;
  }
}

.search-print-row-m {
  flex-wrap: wrap;
}

.mode-tablet, .mode-mobile {
  .search-dialog-container {
    width: 100%;
    padding: 0;
  }
}

.mode-zoom-400 {
  &.mode-blind {
    .tab-wrap.search-tabs {
      margin-bottom: 1rem;
    }
  }
}
