.theme-mode-container {
  .item-container {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1rem;
    width: 100%;
    border-bottom: 1px solid var(--border-sec);
    svg {
      width: 1.9rem;
      height: 1.9rem;
    }

    &:first-child {
      border-top: 1px solid var(--border-sec);
    }
  }

  .item-inner {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1rem 0.5rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .view-mode-icons {
    margin: 1.5rem 0;
    justify-content: center;
  }

  .view-mode-icons-icon {
    margin: 0 0.7rem;

    svg {
      width: 2rem;
      height: 2rem;
      &.selected {
        width: 1.8rem;
        height: 1.8rem;
        fill: var(--icon-active);
      }
    }
  }
}

.theme-active {
  color: var(--primary);

  .theme-title {
    color: var(--primary);
  }
}

.mode-zoom-150 {
  .theme-mode-container {
    .item-inner {
      padding: 0.5rem;
    }
  }
}

.mode-blind {
  .theme-mode-container {
    .view-mode-icons-icon {
      svg {
        &.selected {
          fill: var(--icon-background);
        }
      }
    }
  }
}
