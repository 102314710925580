@import "./src/styles/variables.module";

.relevant-search-view-right-panel {
  width: 100%;
  background: var(--background);
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  color: var(--text-inactive);
  background: var(--background);
  position: relative;

  .relevant-search-block {
    padding: 0 0;
  }

  .folder {
    width: 100%;

    .list-wrap {
      .item {
        a {
          color: var(--text-default);
        }

        a:hover {
          color: var(--text-link-hover);
        }

        &.passive {
          a {
            color: var(--bc-text);
          }

          color: var(--bc-text);
          font-weight: normal;
        }
      }
    }
  }

  .vertical-tabs-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.5rem;
    height: $panel-header-height;
    flex-wrap: wrap;
    font-size: 0.9rem;
    line-height: 0.9rem;
    .form-select-field {
      white-space: nowrap;
      svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    .vertical-tabs-item-related {
      // font-size: 0.8rem;
      font-weight: bold;
      cursor: pointer;
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      position: relative;
      &.active {
        color: var(--tab-active);
        text-decoration: underline;
      }

      &:first-child:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 90%;
        width: 0.1rem;
        background-color: var(--gray);
        display: block;
      }
    }
    .nav-panel {
      padding-top: 0.1rem;
      padding-right: 0.4rem;
    }
  }
  .list-wrap {
    display: block;
  }
  .relevant-search-content-item {
    flex-basis: 100%;
    .item-title {
      font-family: "Roboto Condensed", sans-serif;
      //border: 1px solid red;
      //text-transform: lowercase;
    }
  }
  .relevant-search-box-left,
  .relevant-search-box-right {
    margin-right: 0;
  }
  
  .relevant-search-box {
    display: block;
     margin-right: 0.5rem;

    .folder-item {
      justify-content: flex-start;
      cursor: pointer;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 15.5rem;
      }
    }
  }
  .vertical-tabs-column,
  .relevant-search-select {
    padding-top: 0.4rem;
    width: auto;
  }
  .vertical-tabs-item {
    margin-top: 0.4rem;
    white-space: nowrap;
  }
}

// moved from the "RightPanel.scss"
.search-plus-related-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  min-height: 0;

  .clickable {
    cursor: pointer;
  }

  .topic-wrapper {
    margin: 0 0.5rem;
    padding: 0 0.5rem;
    width: 230px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 230px;
    justify-content: space-between;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    color: var(--text-inactive);
    white-space: nowrap;
    display: flex;
    height: 3rem;
    align-items: center;
    border-bottom: 1px solid var(--border);

    .topic-wrapper-title {
      display: flex;
      align-items: center;
      flex-direction: row;
      overflow: hidden;
    }

    &:hover {
      color: var(--primary);
      background: var(--highlighted-elem-egw);

      .title {
        color: var(--text-hover);
      }
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .search-plus-list {
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .relevant-search-box-left .title {
    padding: 0.2rem 0 0.3rem 0.5rem;
    margin: 0;
  }
  .relevant-search-view-right-panel .relevant-search-box .folder-item {
    width: 100%;
    overflow: hidden;

    span {
      letter-spacing: 0.05rem;
    }

    svg {
      flex-shrink: 0;
      align-self: flex-start;
    }
  }
}

.mode-mobile {
  .relevant-search-view-right-panel {
    .form-col {
      padding: 0;
    }
  }
}
