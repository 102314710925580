.bc-wrap {
  $font-size: 0.9rem;
  height: $font-size; // Prevent twitching when the crumbs height is grown to more than one line before it is calculated to be compact.
  font-size: $font-size;
  line-height: 1.1rem;
  min-width: 0.9rem;
  letter-spacing: 0.1em;
  flex-grow: 1;
  white-space: nowrap;
}

.bc-popup-wrap {
  position: relative;
  display: inline-block;
  // margin-right: 0.5rem;
 

  &.active,
  &:hover {
    cursor: pointer;
    .bc-popup-modal {
      transition: visible 0s 0.15s, opacity 0.15s linear;
      opacity: 1;
      visibility: visible;
    }
  }
}



.bc-popup-modal {
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: var(--background);
  box-shadow: 0 4px 12px var(--shadow);
  top: 100%;
  left: 0;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  transition: visible 0s 0.15s, opacity 0.15s linear;
  opacity: 0;
  max-width: 12rem;

  span {
    letter-spacing: normal;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 94%;
    white-space: nowrap;
  }
}


.bc-item-wrap{
  text-decoration: none;
  user-select: none;
  color: var(--bc-text);
  position: relative;
  // padding-right: 0.3rem;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  line-height: 1;

  .collapsable:hover{
    &::after {
      transform: rotate(90deg);
    }
  }

  &.bc-title-vertical {
    padding: 0.5rem 0;
    display: flex;

    &:hover{
      color: var(--bc-text-active);
    }
  
    span {
      flex: 1;
    }
  }


  // &.active {
  //   color: var(--bc-text-active);
  // }
}

.breadcrumb-item {
  vertical-align: bottom;
  overflow: hidden;
  display: inline-block;
  max-width: 30rem;
  text-overflow: ellipsis;
}

.dropdown-menu-area {
  position: absolute;
  top: 100%;
  z-index: 100;
  left: 0;
  .scrollbars-wrapper {
    max-height: unset !important;
  }
}

.dropdown-menu-padding {
  padding-left: 0.3rem;
  .bc-head {
    height: 0.5rem;
    width: 100%;
  }
}

.dropdown-menu {
  width: 12rem;
  // padding: 0;
  background: var(--popup-background);
  box-shadow: 0 4px 12px var(--shadow);

  p {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
    letter-spacing: 0;
    margin: 0;
    white-space: nowrap;
    font-size: 0.9rem;
    line-height: 152.34%;
    color: var(--text-inactive);
    list-style: none;

    &:last-child {
      margin-bottom: 7px;
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;
      color: var(--primary);
    }
  }
}

.dropdown-text-selected {
  font-weight: bold !important;
  color: var(--tiles-st) !important;

  &:hover {
    color: var(--primary) !important;
  }
}

.mode-blind {
  .dropdown-menu {
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .bc-popup-modal {
    box-shadow: none;
    border: 1px solid var(--border);
  }
}
