@use 'sass:math';
@import "src/styles/layers";

.base-drag-line {
  $width: 0.8rem;
  z-index: $base-drag-line-z-index;
  position: absolute;
  top: 6rem;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  margin-left: -#{math.div($width, 2)};
  user-select: none;
  cursor: col-resize;

  &:hover {
    .base-drag-line__line {
      visibility: visible;
    }
  }

  &.is-dragging {
    opacity: 0.3;

    .base-drag-line__line {
      visibility: visible;
    }
  }

  &.is-drag-between {
    .base-drag-line__line {
      background: var(--secondary);
    }

    .base-drag-line__thumb {
      fill: var(--secondary);
    }
  }

  &.is-phantom {
    opacity: 0.5;

    .base-drag-line__line {
      visibility: visible;
    }
  }
}

.base-drag-line__line {
  visibility: hidden;
  position: relative;
  width: 0.12rem;
  height: 100%;
  background-color: var(--primary);
}

.base-drag-line__thumb {
  position: absolute;
  top: 50%;
  left: -0.7rem;
  width: 1.5rem;
  height: 0.7rem;
  fill: var(--primary);
  transform: translateY(-50%);
  cursor: col-resize !important;
}