.logo-container {
  $logo-height: 2.28rem;

  // flex-shrink: 0; // Prevent shrink logo.
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .logo-content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    letter-spacing: 0.1em;
    font-weight: bold;

    &:hover {
      border-bottom: 2px solid var(--primary);
      margin-bottom: -2px;

      .logo {
        fill: var(--primary);
      }

      .logo-content-text {
        span {
          color: var(--primary);
        }
      }
    }
  }

  .logo {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .logo {
    height: $logo-height;
  }

  .logo-content-text {
    display: flex;
    align-items: flex-end;
    margin-left: 0.11rem;
    font-size: 1.55rem;
  }

  .logo-content-copyright {
    font-size: 0.78rem;
    margin-bottom: 0.7rem;
  }

  .logo-top-container {
    align-items: center;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    font-weight: bold;
    flex-direction: column;
  }

  .logo-container-links {
    margin-top: 0.3rem;
    display: block;
    font-weight: 400;
    white-space: nowrap;

    .logo-link {
      font-size: 0.7rem;

      &:hover {
        text-decoration: underline;
        color: var(--primary);
      }
    }
  }

  svg {
    fill: var(--header-item);
  }

  span {
    letter-spacing: 0.1em;
  }

  span,
  a {
    color: var(--header-item);
    text-decoration: none;
  }
}

.logo-content-app-name {
  position: relative;
  top: -0.15rem;
}

.mode-mobile {
  /* Move to the next line logo text if not enough space. */
  .logo-container { 
    overflow: hidden; 
    flex: 1;    
  }

  .mobile-search-form-visible {
    .logo-content-text {
      .logo-content-copyright {
        margin-left: -0.28rem;
        margin-bottom: -0.2rem;
      }
    }
  }

  
  .logo-content {
    overflow: hidden;
    height: 1.8rem;
    flex-wrap: wrap;
  }
  .logo {
    height: 100%;
  }
  /* =========== */

  .logo-content-text {
    font-size: 1.22rem;
  }
}

.mode-tablet {
  .logo-content-text {
    font-size: 1.22rem;
  }

  .logo-top-container {
    max-width: 9rem;
  }

  .logo {
    height: 1.89rem;
  }
}

.mode-mobile,
.mode-tablet {
  .logo-content-copyright {
    font-size: 0.66rem;
    margin-bottom: 0.6rem;
  }
}

.mode-tablet,
.mode-desktop {
  .logo-container {
    &:hover {
      filter: drop-shadow(0 0 1px rgba(128, 128, 128, 0.5));
    }
  }
}
