@import "./src/styles/layers";

// .mainPart {
//   z-index: $main-part-z-index;
// }

// .mainWrapper {
//   z-index: $main-wrapper-z-index;
// }

body {
  &:not(.mode-mobile) {
    // Make a little indent at the bottom.
    .mainWrapper {
      margin-bottom: 5px;
      min-height: 0 !important;
    }
  }
}

.phantomWrap {
  z-index: $resizer-phantom-wrap-z-index;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
