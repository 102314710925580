@import "../../shared/styles/functions";

$sub-option-height: 1.1rem;
$sub-options-block-indent-side: 1.2rem;
$sub-options-block-indent-bottom: 1rem;
$subs-edit-inner-child-indent-side: 1.2rem;
$subs-dialog-content-indent-top: 0.9rem;

:export {
  subOptionHeight: stripUnit($sub-option-height);
}