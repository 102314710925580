.no-data-template {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-data-template_title {
  color: var(--primary-light);
}

.no-data-template_description {
  margin-top: 0;
  text-align: center;
}
