.tree-list {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  min-height: 0; // Prevent height overflow as flex-box gives to children min-height: 100% by default.
}

.tree-item {
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  align-items: center;

  &:focus {
    outline: none;
    background: var(--background);
  }

  &.active-tree-position,
  &:hover {
    &.reference {
      background: var(--highlighted-elem-reference) !important;
      .tree-link {
        color: var(--highlighted-elem-reference-text);
      }
    }

    &.bibleCommentary, // for the 'titles' tree
    &.bible {
      background: var(--highlighted-elem-bible) !important;
      .tree-link {
        color: var(--highlighted-elem-bible-text);
      }
    }

    &.apl {
      background: var(--highlighted-elem-apl) !important;
      .tree-link {
        color: var(--highlighted-elem-apl-text);
      }
    }
  }
}
.tree-item-loader {
  font-family: "Roboto Condensed", sans-serif;
}

.current-lang {
  color: var(--category-egw) !important;
}

.language,
.para {
  color: var(--text-default);
}

.tree-link {
  text-decoration: none;
  margin-left: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 125%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer !important;
    text-decoration: underline;
  }
}

.tree-icon {
  margin-left: 5px;
  width: 1em;
  height: 1em;
}

.icon-check {
  width: 0.75em;
  height: 0.75em;

  :hover {
    cursor: pointer;
  }
}

.tree-icon-flag {
  margin-left: 5px;
  width: 1em;
  height: 0.75em;
}

.icon-toggle {
  margin-right: 5px;
  width: 0.75em;
  height: 0.75em;
  transition: transform 0.25s;

  &:hover {
    cursor: pointer;
  }

  &.is-expanded {
    transform: rotate(90deg);
  }
}

.current-folder {
  font-weight: bold !important;
  text-decoration: underline !important;
}

.tree-vertical-align {
  display: inline-flex;
  align-items: center;
  height: 100%;

  .icon-toggle-with-checkbox {
    margin-right: 3px;
  }

  .tree-icon-with-checkbox {
    margin-left: 0;
  }

  .icon-check-container {
    padding: 0.4em;
    width: 0.9em;
    height: 0.9em;
  }
}

.mode-blind {
  .tree-item.active-tree-position.egwwritings {
    background-color: var(--highlighted-elem-egw) !important;
  }
  .tree-item {
    &.active-tree-position,
    &:hover {
      &.egwwritings,
      &.reference,
      &.bible,
      &.apl {
        .tree-link {
          color: var(--highlighted-category-egw);
          fill: var(--highlighted-category-egw);
        }
        .tree-icon,
        .icon-check,
        .icon-check-container,
        .icon-toggle {
          color: var(--highlighted-category-egw);
          fill: var(--highlighted-category-egw);
        }
      }
    }
  }
}
