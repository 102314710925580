.not-found-title {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 300px;
  line-height: 300px;
  text-align: center;
  letter-spacing: 0.08em;
  color: var(--text-link);
  margin: 0;
  text-shadow: 10px 10px 0px var(--text-link-shadow);;
}

.not-found-subtitle {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--text-default);
  margin: 0;
  margin-top: -35px;
}

.not-found-container {
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.not-found-button {
  margin-top: 40px;
}

.mode-mobile {
  .not-found-title {
    font-size: 250px;
    line-height: 250px;
  }

  .not-found-button {
    margin-top: 20px;
  }
}
