@import "./src/styles/layers";
@import "./src/shared/styles/mixins";

$grid-scrollbar-z-index: 0;

.main-header {
  width: 100%;
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  color: var(--tiles-st);
  overflow: hidden;
}

.main-header-inner {
  display: grid;
  grid-template-columns: auto auto;
}

.main-header-title {
  font-weight: bold;
  letter-spacing: 0.08em;
  margin: 0;
  font-size: 2rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: text;

  span {
    @include threeDots();
  }
  .back-icon {
    font-size: 0.75em;
    cursor: pointer;
  }
}

.watermark-wrap {
  width: calc(100vw - 355px);
  position: absolute;
  right: 0;
  z-index: -10;
  top: 0;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  user-select: none;

  img {
    max-width: 50%;
    display: none;
    align-self: flex-end;
    height: auto;

    &.active {
      display: block;
    }
  }

  .watermark-text {
    flex-basis: 50%;
    font-size: 120px;
    text-align: right;
    line-height: 1;
    letter-spacing: 0.08em;
    margin-bottom: 1.5rem;
    color: var(--water-mark);
    text-shadow: 4px 8px 15px rgba(0, 0, 0, 0.08);
  }
}

.folder-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -0.3rem -0.3rem 0;
}

.grid-scrollbar,
.scroller-view {
  z-index: $grid-scrollbar-z-index;
}

.scrollToWrapper {
  z-index: $grid-scrollbar-z-index + 1;
  position: absolute;
  width: 2rem;
  bottom: 1.5rem;
  right: 1.5rem;
  pointer-events: none; // Fix user click blocking when some of buttons isn't visible but its place is reserved though.

  &.--absolute {
    position: absolute;
    right: 0;
  }

  .top-button,
  .bottom-button {
    height: 2rem;
    width: 2rem;
    fill: var(--secondary);
    pointer-events: auto; // Fix user click blocking when some of buttons isn't visible but its place is reserved though.

    &:hover {
      cursor: pointer;
      fill: var(--secondary);
    }
  }

  .bottom-button {
    transform: rotate(180deg);
  }
}

.drag-overlay {
  z-index: var(--drag-overlay-z-index);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  user-select: none;

  :hover {
    cursor: grabbing;
  }
}

.dnd-wrap {
  z-index: $dnd-wrap-z-index;
}

.drag-view {
  box-shadow: 0 4px 12px var(--shadow);
  padding: 0.5rem;
  opacity: 0.9;
  user-select: none;
  text-align: center;
  width: 10rem;
  box-sizing: border-box;
  max-height: 5rem;
  overflow: hidden;
  z-index: var(--drag-view-z-index);
  transform: translate(-50%, 0);
  position: absolute;
  background: var(--background);
  text-overflow: ellipsis;
  letter-spacing: 0.05rem;
  color: var(--primary);
  line-height: 1.2;
  font-weight: bold;
}

.drop-field-active {
  border: 3px solid var(--primary);
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.mode-tablet {
  .watermark-wrap {
    display: none !important;
  }

  .main-header {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding-top: 0;
    span {
      cursor: pointer;
    }
  }
}

.mode-mobile {
  .main-header {
    font-size: 1.3rem;
    padding: 0.9rem 0;
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
    }
  }

  .main-header-inner {
    width: 100%;
  }

  .back-icon {
    font-size: 0.85em;
    line-height: 0.7;
  }
  .main-header-title {
    font-size: 1.5rem;
    // padding-left: 0.9rem;
    display: flex;
    align-items: center;

    span {
      line-height: 1;
    }

    // &:after {
    //   font-size: 0.8rem;
    //   line-height: 1.3rem;
    // }
  }

  .folder-list {
    margin: 0;
  }
}

.mode-blind {
  .drag-view {
    box-shadow: none;
    border: 1px solid var(--border);
  }
}

.mode-zoom-200 {
  &.mode-tablet {
    .main-header {
      font-size: 1.4rem;
      padding: 0;

      svg {
        min-width: 1rem;
        min-height: 1rem;
      }
    }
  }

  &.mode-mobile {
    .scrollToWrapper {
      width: 1.5rem;
      bottom: 1rem;
      right: 1rem;

      .top-button,
      .bottom-button {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.mode-zoom-300,
.mode-zoom-400 {
  &.mode-mobile {
    .scrollToWrapper {
      width: 1.5rem;
      bottom: 1rem;
      right: 1rem;

      .top-button,
      .bottom-button {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.main-application {
  z-index: $main-application-z-index;
}

.dev-mode-view {
  position: fixed;
  top: -0.5rem;
  right: -2rem;
  z-index: 200;
  background: red;
  color: white;
  font-weight: bold;
  padding: 1rem 2rem 0;
  transform: rotate(45deg);
}
