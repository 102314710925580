.common-popup {
  position: fixed;
  z-index: 500;
  padding: 0 0.3rem;
  display: flex;
  background: var(--background);
  box-shadow: 0 4px 12px var(--shadow);

  i {
    display: inline-block;
    margin: 0.6rem;
    position: relative;
    z-index: 5;
    min-width: 1.1rem;
    min-height: 1.2rem;

    &:hover {
      cursor: pointer;
      fill: var(--primary);
    }
  }

  a {
    text-decoration: none;
    max-height: 2.2rem;
    display: inline-block;
  }
}

.mode-blind {
  .common-popup {
    box-shadow: none;
    border: 1px solid var(--border);
  }
}
