@import "./src/shared/styles/mixins";

.workspaces {
  .ws-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ws-list {
    display: flex;
    flex-direction: column;

    .ws-item {
      width: 100%;    
      display: flex;
      gap: 0.1rem;
      justify-content: space-between;

      &.ws-empty {
        color: var(--border);
        height: unset;
      }

      a {
        display: flex;
        gap: 0.5rem;
        width: 100%;
      }
    }

    .ws-item, 
    .ws-row-left {
      height: 1.4rem;
      overflow: hidden;
    }

    .ws-row-right {
      background: var(--background);
    }

  }
}

.ws-grid {
  display: grid;
  gap: 0.5rem;
  max-width: 27rem; // 5rem * 5 + 0,5 * 4
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));

  .ws-item {
    max-width: 5rem;

    .ws-item-layout > .Pane {
      height: 100%;
      width: 100%;
    }

    .SplitPane {
      position: unset !important;
    }

    .ws-title {
      padding: 0.2rem 0;
    }

    .Resizer {
      cursor: auto;
      pointer-events: none;

      &:hover {
        border-color: var(--background);
        background-color: var(--background);
      }
    }

    .Pane {
      background-color: var(--icon-color);
      overflow: hidden;

      .ws-pane-item {
        color: var(--background);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0.2rem;

        span {
          font-size: 0.7rem;
          @include threeDots();
        }
      }
    }

    .ws-item-layout {
      height: 5rem;
      width: 5rem;
    }

    .ws-date {
      font-family: "Bebas Neue", "Roboto", "Roboto Condensed", sans-serif;
      background: var(--footer);
      color: var(--footer-item);
      z-index:  2;
      box-sizing: border-box;
      padding: 0.35rem;
      font-size: 0.7rem;
      line-height: 1;
    }
  }
}

.workspaces, .ws-grid {
  .ws-item {
    a {
      text-decoration: none;
      align-items: center;
      
      font-family: "Roboto Condensed", sans-serif;
      color: var(--icon-color);

      .ws-title {
        @include threeDots();
      }
    }

    &.active a,
    a:hover {
      color: var(--icon-hover);
      
      .ws-title {
        font-weight: 600;
      }

      i {
        color: var(--icon-hover);
      }
    }
  }
}

.ws-tools {
  i {
    font-size: 1rem;
    margin: 0.15rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

@include canHover {
  .ws-grid {
    .ws-item:hover {
      .Pane {
        background-color: var(--icon-hover);
      }
    }
  }

  .workspaces,
  .ws-grid {
    .ws-item:hover {
      .ws-title {
        color: var(--icon-hover);
        font-weight: 600;
      }
    }
  }
}