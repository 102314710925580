@import "../../styles/layers";
@import "src/shared/styles/mixins";

.book-image-wrap {
  z-index: 0;
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--background);
  outline: none;

  &.enlarged {
    z-index: 1;
  }

  .hover-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .hover-wrapper {
    z-index: $book-list-item-cover__image-z-index;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.25s;
    will-change: margin;
  }
}

.book-image-wrap {
  user-select: none;
  -webkit-touch-callout: none;
  @include canHover {
    &:hover,
    &:focus {
      .book-image-tap-wrap {
        opacity: 1;
        visibility: visible;
      }

      .book-item-image-img {
        box-shadow: 4px 5px 8px var(--shadow);
        transition: all 0.25s;
        will-change: box-shadow;
        pointer-events: none;
      }
    }
  }
}

.book-item-image_mark-btn-download {
  z-index: 4;
  position: absolute;
  left: 0;
  bottom: 0;

  display: flex;

  pointer-events: none;

  svg {
    height: 1.5rem;
  }
}

.book-image-wrap__backdrop {
  z-index: $book-list-item-cover__backdrop-z-index;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.book-image-tap-wrap {
  // transition for opacity only, to prevent element blinking at the initial rendering
  transition: opacity 0.25s linear;
  opacity: 0;
  visibility: hidden;
  // ==============
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  z-index: $book-list-item-cover__image-z-index + 2;
  color: var(--book-center-title-text);

  @include positionAbsoluteCentered;

  .book-center-title,
  svg {
    @include positionAbsoluteCentered;
  }

  &.book-image-circle-blue {
    &:hover {
      fill: var(--book-center-title-bg-hover);
    }
  }

  &.book-image-circle-orange {
    &:hover {
      color: var(--primary);
      fill: var(--secondary);
     }
  }

  &.book-image-circle-red {
    &:hover {
      fill: var(--book-center-title-bb-bg-hover);
    }
  }

  &.book-image-circle-green {
    &:hover {
      fill: var(--quaternary-light);
    }
  }
}

.book-image-circle-blue {
  fill: var(--primary-light);
}

.book-image-circle-orange {
  fill: var(--secondary);
}

.book-image-circle-red {
  fill: var(--tertiary);
}

.book-image-circle-green {
  fill: var(--quaternary);
}

.book-like-icon {
  fill: var(--secondary) !important;

  &:hover {
    fill: var(--secondary) !important;
  }
}

.book-image-tap-btn {
  width: 3.4rem;
  height: 3.4rem;
  font-size: 0.7rem;
  line-height: 1;

  svg {
    width: 100%;
    height: 100%;
  }
}

.book-image-tap-btn-small {
  font-size: 0.55rem;
  line-height: 1;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
}

.book-center-title {
  z-index: 15;
  text-align: center;
}

.book-image-bottom {
  position: absolute;
  font-size: 0.7rem;
  line-height: 1.3;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  bottom: 0;
  left: 0;
  padding: 0.6rem;
  box-sizing: border-box;
  width: 50%;
  background-color: var(--category-egw);
}

.book-image-title-big {
  padding-top: 0.1rem;
  text-align: center;
}

.book-image-title-small {
  padding-left: 0.1rem;
  padding-top: 0.1rem;
  text-align: center;
}

.book-mark {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-top: 0.1rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bm-vertical {
  box-sizing: border-box;
  width: calc(100% - 1.25rem);
  padding: 0.35rem;
  font-size: 0.7rem;
  line-height: 1;
}

.bm-date {
  background: var(--footer);
  color: var(--footer-item);
  z-index:  $book-list-item-cover__image-z-index + 2;
}

.bi-info-background {
  position: absolute;
  z-index:  $book-list-item-cover__image-z-index + 2;
  right: 0;
  bottom: 0;
  background: var(--footer);
  color: var(--footer-item);
  padding: 0.15rem;
  font-size: 1.1rem;
  line-height: 0.5;
}

.bi-translate-background{
  position: absolute;
  z-index:  $book-list-item-cover__image-z-index + 2;
  left: 0;
  bottom: 0;
  background: var(--quaternary);
  color: var(--footer-item);
  padding: 0.15rem;
  font-size: 1.1rem;
  line-height: 0.5;
}

.mode-blind {
  .book-image-tap-wrap {
    &.book-image-circle-orange {
      &:hover {
        color: var(--search-background);
      }
    }
  }

  .book-image-wrap {
    &:hover,
    &:focus {
      .book-item-image-img {
        border: 1px solid var(--border);
        box-shadow: none;
        will-change: unset;
      }
    }
  }
}
