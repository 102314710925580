.dialog-background {
  z-index: var(--dialog-background-z-index);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.dialog-container {
  position: relative;
  outline: none;
  z-index: var(--dialog-container-z-index);
  padding: 2rem;
  min-width: 18rem;
  max-width: 100vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  border-radius: 0.2rem;
  box-sizing: border-box;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  background-color: var(--popup-background);
}

.dialog-scroll-wrapper {
  display: flex;
  flex-direction: column;
}

.dialog-cancelable {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dialog-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: var(--primary-light);
  display: block;
  margin-right: 1rem;
  // fix overflow-hidden from <Scroll />
  margin-top: 0.3rem;
}

.dialog-message {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.9rem;
  margin-top: 1.3rem;
  line-height: 1.3;
  letter-spacing: 0.05em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-btn-wrap {
  margin-top: auto;
  padding-top: 1.3rem;
  display: flex;
  align-items: center;  
  justify-content: center;}

.dialog-btn {
  // flex: 1;
  flex: 0 1 7rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mode-mobile {
  .dialog-container {
    display: flex;
    flex-direction: column;
    // "Rem" value of "320px" is too big in case x4 zoom on tablet.
    min-width: 320px;
    padding: 1rem;
    margin: 1rem;

    &.root-date-picker {
      width: auto;
    }
  }
  .dialog-btn-wrap {   
    padding-top: 1rem;
    justify-content: space-around;
  }
  &.mode-zoom-150 {
    .dialog-title {
      margin-top: 1rem;
      margin-right: 0;
    }
    .dialog-btn {
      flex-basis: 5rem;
    }
  }

  &.mode-zoom-250 {
    .dialog-btn-wrap {
      .dialog-btn {
        &:first-child {
          margin-right: 0.5rem;
        }
        &:last-child {
          margin-left: 0.5rem;
        }
      }
    }
  }

  &.mode-2k, 
  &.mode-4k {
    .dialog-container {
      max-width: 21rem;
    }
  }
}

.mode-blind {
  .dialog-container {
    box-shadow: none;
    border: 2px solid var(--border);
  }

  .dialog-btn {
    &.button-secondary,
    &.button-primary {
      // Specific line height for the button that has double border in blind mode.
      line-height: 1.8rem;
    }
  }
}
