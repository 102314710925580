@import "Header.variables.module";
@import "Header.layers";
@import "src/shared/styles/mixins";

.login-popup {
  padding-top: 0.75rem;
}

.login-header-menu {
  position: relative;
  display: inline-flex;
  max-width: $header-login-btn-max-width-default;
  align-items: center;
  cursor: pointer;
  z-index: $login-button-z-index;

  &.open {
    color: var(--icon-hover);

    .headerLoginText {
      color: var(--icon-hover);
      hr {
        background: var(--icon-hover);
      }
    }
    ::after {
      border-color: var(--icon-hover);
      color: var(--icon-hover);
    }
  }
}

.headerProfileContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 2.78rem;
  height: 2.78rem;
  min-width: 2.78rem;
  min-height: 2.78rem;
  color: var(--icon-default);
  z-index: 10;

  ::after {
    position: absolute;
    content: " ";
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    border: 2px solid var(--icon-color);
  }
}

.headerLoginImage {
  width: 2.78rem;
  height: 2.78rem;
  min-width: 2.78rem;
  min-height: 2.78rem;
  fill: var(--icon-color);
  border-radius: 50%;
}

.headerLoginText {
  width: 100%;
  overflow: hidden;

  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
  font-size: 0.59rem;

  text-overflow: ellipsis;
  text-align: center;

  line-height: 1;
  letter-spacing: 0.1em;

  border-radius: 1rem;
  color: var(--icon-color);

  hr {
    height: 1px;
    background: var(--icon-color);
    margin: 0.15rem 0 0.1rem;
  }
}

.header-menu_btn-languages {
  display: flex;
  align-items: center;

  img {
    width: 0.9rem;
    height: 0.6rem;
  }
}

.dropDownText {
  white-space: nowrap;
}

.headerPopupMenu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 8.72rem;
  overflow: hidden;
  padding-top: 0.28rem;
  padding-bottom: 0.58rem;
  background: var(--background);

  button {
    cursor: pointer;
  }

  .dropDownText {
    box-sizing: border-box;
    width: 100%;
    padding: 0.39rem 0.6rem 0;

    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    font-size: 0.88rem;
    line-height: 1.5;

    text-transform: capitalize;
    text-decoration: none;

    color: var(--text-default);

    &:hover, &:focus, &.open {
      color: var(--primary);

      @include fakeBold;
    }
  }
}

.mode-text {
  .login-header-menu {
    max-width: $header-icon-max-width-text-mode;
    &::after {
      content: none;
    }
  }
}

.mode-blind {
  .login-header-menu.active {
    background-color: var(--icon-background);
    .headerLoginImage {
      color: var(--icon-active);
      fill: var(--icon-active);
      background-color: var(--icon-background);
    }
    .headerProfileContainer {
      color: var(--icon-active);
      &::after {
        border-color: var(--icon-active);
      }
    }

    .headerLoginText {
      color: var(--icon-active);
      hr {
        background: var(--icon-active);
      }
    }
    &::after {
      color: var(--icon-active);
      fill: var(--icon-active);
    }
  }
}
