.nested-dd {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
  box-shadow: 0 4px 12px var(--shadow);
  background-color: var(--background);
  box-sizing: border-box;
}
.nested-dd__li {
  list-style-type: none;
  margin: 0;
  padding: 0.35rem 0.5rem;
  color: var(--bc-text);
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.nested-dd__dd {
  flex-direction: column;
  position: absolute;
  cursor: initial;
}

.nested-dd__title {
  padding-right: 0.1rem;
  width: 100%;
}
