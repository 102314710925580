@import "./src/styles/layers";
@import "./src/styles/variables.module";

$bg: var(--background);

@keyframes animationSlideInRightPanel {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

.right-panel {
  position: relative;

  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex: 1 0;
  flex-direction: column;

  &:not(.sliding) {
    position: relative;
    height: 100%;

    opacity: 0;
    transition: opacity #{$animation-duration_side-panel} #{$animation-timing-fn_side-panel};

    &.shown {
      opacity: 1;

      animation: animationSlideInRightPanel #{$animation-duration_side-panel} forwards;
    }

    .right-panel-inner {
      border: 1px solid var(--border-sec);
    }
  }

  &.sliding {
    $box-shadow_size: 10px;

    z-index: $right-panel-sliding-z-index;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: -4px 4px #{$box-shadow_size} rgba(0, 0, 0, 0.15);

    background: $bg;

    transition: transform #{$animation-duration_side-panel} #{$animation-timing-fn_side-panel};

    &:not(.shown) {
      transform: translateX(calc(100% + #{$box-shadow_size}));

      & + .right-panel__backdrop {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .search-placeholder {
    margin: 0.9rem auto;
  }

  .pin-icon {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0.8rem;
  }
}

.right-panel-inner {
  position: relative;

  flex: 1 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  background: $bg;
}

.right-panel__backdrop {
  z-index: #{$right-panel-sliding-z-index - 1};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.05);

  cursor: pointer;
  transition: opacity #{$animation-duration_side-panel} #{$animation-timing-fn_side-panel};
}

.tab-container {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  height: 100%;
}

.tab-wrap {
  &.tabs-right-panel {
    flex-shrink: 0; // prevent height shrink
    flex-wrap: nowrap;
    height: $panel-header-height;
    padding: 0;

    .tab-default {
      text-align: center;
      font-size: 1.1rem;
      flex: 1 1 110px;
      overflow: hidden;
      padding: 0.25rem;
      text-overflow: ellipsis;
      background-color: var(--slider-inactive);
    }

    .tab-active {
      text-decoration: none;
      background-color: var(--background);
    }

    .tab-locked-active {
      background-color: var(--background);
    }
  }
}

.right-panel-content {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;

  .search-result-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 2rem;

    &.featured-tab {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-top-panel-wrapper {
      margin-left: auto;
      position: relative;
      display: flex;
    }
  }

  .SplitPane {
    .Pane.vertical {
      overflow: hidden;
    }
  }
}

// Resets Relevant Search styles. Should be refactored.
.right-panel .search-plus-featured-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.75rem;

  h3 {
    margin: 0.6rem 0;
    letter-spacing: 0.05rem;
  }

  .list-wrap {
    padding-left: 0;
  }
}

.mode-mobile,
.mode-tablet {
  .right-panel-inner {
    background-color: var(--background);
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .tab-wrap {
      &.tabs-right-panel {
        padding: 0.5rem 0.5rem 0;
      }
    }

    .search-plus-related-wrapper .topic-wrapper {
      padding: 0;
    }
  }
}

.mode-blind {
  .tab-wrap.tabs-right-panel {
    .tab-active:hover {
      color: var(--text-default);
    }
    .tab-inactive {
      background-color: var(--secondary);
      color: var(--tab-inactive);
    }
  }
}

.mode-text {
  &.mode-blind {
    .search-result-header {
      align-items: center;
    }
  }
}
