.app-notification {
  z-index: var(--app-notification-z-index);
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--app-notificaton-background);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);

  .app-notification-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-top: 1.6rem;
  }

  .app-notification-column1 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40%;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
    min-width: 35%;

    img {
      max-width: 100%;
      object-fit: cover;
      max-height: 100%;
    }
  }

  .app-notification-column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.9rem;

    .app-notification-content-title {
      color: var(--white);
      font-size: 1.2rem;
      line-height: 1.2;
      font-weight: bold;
      letter-spacing: 0.05rem;
      font-family: "Bebas Neue", sans-serif;
      margin-bottom: 0.5rem;
    }

    .app-notification-content-text {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.3;
      font-weight: normal;
      font-family: "Roboto Condensed", Roboto, Helvetica, Arial, sans-serif;
      color: var(--white);
    }

    .app-notification-actions {
      .button-base {
        padding: 0;

        img {
          width: 8rem;
          height: 2.2rem;
        }
      }
    }
  }

  .hide-icon-container {
    top: 0.6rem;

    &.iconInActive {
      color: rgba(255, 255, 255, 0.5);

      svg {
        width: 0.9rem;
        height:  0.9rem;
        fill: rgba(255, 255, 255, 0.5);

        &:hover {
          fill: #ffffff;
        }
      }

      &:hover {
        color: #ffffff;

        svg {
          fill: #ffffff;
        }
      }
    }
  }
}

.mode-mobile {
  .app-notification {
    left: 0;

    .hide-icon-container.iconInActive {
      color: rgba(255,255,255, 0.6);

      svg {
        fill: rgba(255,255,255, 0.6);
      }
    }
  }

  .app-notification-column1 {
    align-items: flex-end; // Nail to the bottom as the mobile image is cropped at the bottom.
  }
}

.mode-2k, .mode-4k {
  .app-notification-column1 {
    img {
      height: 100%;
    }
  }
}