@import "../../styles/layers.scss";

.cart-side-panel {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 28.5rem;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  transform: translateX(102%);
  z-index: $cart-side-panel-z-index;
  box-shadow: 0 4px 12px var(--shadow);
  transition: transform 0.25s;
}

.cart-side-panel.visible {
  transform: none;
}
.cart-side-panel__backdrop {
  z-index: $cart-side-panel-z-index - 1;
}

.cart-side-panel__title {
  font-family: "Bebas Neue";
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1rem;
  padding: 1.2rem 0 1rem 1.2rem;
  color: var(--header-item);
}

.cart-side-panel__checkout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem;
}

.cart-side-panel__total-price {
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.4rem;
  padding: 1.2rem 0;
}

.cart-side-panel__subtitle {
  color: var(--bc-text);
  flex: 1;
}

.cart-side-panel__pay-btn {
  min-width: 9rem;
  justify-self: flex-end;
}

.mode-mobile {
  .cart-side-panel {
    width: 80vw;
  }
}
