.cart-list {
    padding: 0 1.2rem;
    margin: 0;
}

.cart-list__border {
    border: none;
    border-top: 1px solid var(--border);
    padding: 0;
    margin: 0;
}