.dialog-toggle-in-library {
  max-width: 20rem;
}
.dialog-toggle-in-library__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 0.5rem;

  .button-base {
    &:first-child {
      margin-bottom: 0.2rem;
    }
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.dialog-toggle-in-library__checkbox {
  margin-top: 0.7rem;
  padding: 0;

  .controlLabel {
    font-weight: 400;
    font-size: 1rem;
  }
}
