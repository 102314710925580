.library-text, .library-link {
  font-family: "Bebas Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.1rem;
  letter-spacing: 0.1em;
  margin: 0;
  display: inline;
}

.loading {
  padding: 0 0.5rem;
}

.library-text {
  color: var(--sc-color-border);
}

.library-link {
  color: var(--tiles-st);
  margin-left: 0.3rem;

  &:hover {
    color: var(--text-link-hover);
  }
}
