@import "src/shared/styles/mixins";

.egw-slider {
  $transition-duration: 0.05s;
  position: relative;
  width: 100%;
  padding: 13px 0;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    background: var(--outline-background);
    outline: 1px solid var(--outline-border);
  }

  &.draggable-only-thumb {
    cursor: default;
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &:after {
    position: absolute;
    top: -0.3rem;
    left: 0;
    right: 0;
    bottom: -0.3rem;
    content: "";
  }

  &.disabled {
    pointer-events: none;
  }

  .egw-slider-track {
    position: relative;
    height: 0.25rem;
    border-radius: 0.25rem;
    background-color: var(--slider-inactive);

    .egw-slider-progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      border-radius: 0.25rem;
      background-color: var(--slider-active);
      transition: $transition-duration;
    }

    .egw-slider-thumb {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: var(--gray);
      transform: translate(-50%, -50%);
      transition: $transition-duration;
    }
  }
}
