.long-content {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  text-indent: 0.7rem;
}
.mode-mobile {
  .long-content {
    padding: 0.5rem;
  }
}

.logo-page {
  fill: var(--primary);
  margin: 1rem;
  height: 8rem;
  width: 8rem;
}

.patch-note {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Roboto Condensed", sans-serif;

  .patch-note-head {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    align-items: center;
  }

  .patch-note-search {
    height: 1.5rem;
    margin-right: 1rem;
    flex: 1;
  }

  .patch-row {
    margin-bottom: 0.5rem;
    border-bottom: 1px var(--border) solid;
  }

  .patch-description {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    color: var(--text-inactive);
  }

  a {
    color: var(--text-link);
  }

  .author {
    color: var(--text-inactive);
    margin-right: 1rem;
  }

  .message {
    color: var(--text-default)
  }

  .second-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .patch-note-select{
    width: 10rem;
    height: 1.5rem;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    border-radius: 1rem;
    background-color: var(--search-background);
  }
}