@import "../../styles/layers.scss";
@import "../../styles/variables.module.scss";
@import "../../shared/styles/mixins.scss";

.relevant-title-row {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 0.5rem;

  .relevant-title {
    position: relative;
    cursor: pointer;
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.1;
    text-align: center;
    letter-spacing: 0.08em;
    color: var(--tiles-text-hover);

    &.active {
      color: var(--tab-active);
      text-decoration: underline;
    }
    &:first-child {
      margin-right: 0.55rem;
      &:after {
        content: "";
        position: absolute;
        right: -0.55rem;
        top: 0;
        width: 0.1rem;
        height: 100%;
        background-color: var(--gray);
      }
    }
    &:last-child {
      margin-left: 0.55rem;
    }
  }
}

.relevant-hide-wrap {
  position: absolute;
  right: 0;
  top: 1rem;
  display: flex;

  .filter-icon-wrap {
    display: flex;
    color: var(--icon-color);
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.05rem;
    margin-right: 0.5rem;
    font-size: 0.88rem;

    .egw-font-icon {
      margin-right: 0.2rem;
      font-size: 0.9rem;
    }
    &:hover {
      color: var(--text-link-hover);
      cursor: pointer;
    }
  }

  .hide-icon-container {
    position: unset;
  }
}

.related-search-text-wrap {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 1rem;
}

.divider {
  width: 0.1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: var(--gray);
  display: block;
  margin-top: 0.8rem;
}

.relevant-search-view {
  margin-top: -$top-bar-desktop-height; // Minus top bar height.
  background: var(--background);
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  color: var(--text-inactive);
  background: var(--background);
  z-index: $relevant-search-view-z-index;
  position: relative;
  margin-right: 0.8rem;
  width: 100%;
  overflow: hidden;

  .with-divider {
    &:after {
      content: "";
      margin-left: 0.5rem;
      width: 0.1rem;
      height: 100%;
      background-color: var(--gray);
    }
  }

  .tab-head-wrap {
    display: flex;
    border-bottom: 1px solid var(--border-sec);
    flex-direction: row;
    letter-spacing: 0.05em;
    margin-left: 0.5rem;
    font-size: 1.15rem;
    padding-bottom: 0.25rem;
    margin-bottom: 0.75rem;
    padding-right: 8.5rem; // for hide button and filter
    font-weight: bold;
    font-family: "Bebas Neue", sans-serif;
    text-transform: uppercase;
    flex-wrap: wrap;

    .tab-head-item {
      margin-top: 1rem;
      // margin-right: 0.5rem;
      white-space: nowrap;
      display: flex;

      &.active {
        color: var(--primary-light);
        cursor: auto;
        &.with-underline {
          text-decoration: underline;
        }
      }

      &:hover {
        cursor: pointer;
        color: var(--primary);
      }
    }
  }

  .relevant-search-select {
    color: var(--gray);
    margin-right: 1rem;
  }

  .vertical-tabs-list {
    display: flex;
    font-size: 1.1rem;
    letter-spacing: 0.08em;
    font-style: normal;
    font-weight: bold;
    padding-top: 0.7rem;
    padding-left: 0;
    text-transform: uppercase;
    align-items: baseline;
  }

  .form-col .form-search {
    width: calc(100% - 50px);
  }

  .form-col {
    width: auto;
    height: auto;
  }

  .flex-container-vertical {
    display: flex;
    flex-direction: column;
    height: 100%;

    .horizontal {
      display: flex;
    }
    .horizontal-header-row {
      display: flex;
      min-height: 50px;
    }
    .horizontal-first-row {
      display: flex;
      min-height: 650px;
      height: 100%;
    }

    .button-right {
      display: flex;
      justify-content: flex-end;

      .clickable {
        font-family: "Bebas Neue", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.1em;
        cursor: pointer;
        text-align: right;
        color: #ff9d5b;
        justify-content: flex-end;
      }
    }

    .header {
      font-family: "Bebas Neue", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #5aaece;
      padding-bottom: 1.1rem;
    }

    .writings-box {
      display: flex;
    }

    .writings-img {
      height: 50px;
      width: 35px;
      margin-right: 15px;
      margin-top: 9px;
    }

    .writings-text-box > svg {
      padding-bottom: 0.7rem;
    }

    .writings-text-box {
      display: flex;
      align-items: center;
    }

    .writings-text-box-vertical {
      display: flex;
      flex-direction: column;
    }

    .writings-text-box-name {
      font-family: "Bebas Neue", sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
      padding-right: 0.8rem;
      letter-spacing: 0.03em;
      color: #727272;
    }

    .writings-text-box-category {
      font-family: "Bebas Neue", sans-serif;
      font-size: 0.8rem;
      line-height: 0.8rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #5aaece;
    }

    .title {
      font-family: "Roboto Condensed", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #1c8ab3;
    }
  }

  .horizontal-header-row > div:first-child {
    flex-basis: 25%;
    margin-right: 0.5rem;
  }
  .horizontal-header-row > div {
    flex-basis: 35%;
    margin-right: 0.5rem;
  }

  .horizontal-first-row > div:first-child {
    flex-basis: 25%;
    margin-right: 0.5rem;
  }
  .horizontal-first-row > div:last-child {
    flex-basis: 35%;
  }
  .horizontal-first-row > div {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    flex-basis: 35%;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }

  .horizontal .item {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    flex-basis: 33%;
  }

  .horizontal .item-scroll {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    flex-basis: 33%;
    margin-bottom: 0.5rem;
  }
  .horizontal .item-scroll .clickable {
    cursor: pointer;
  }

  .filter-search-box-placeholder {
    display: block;
    color: var(--bc-text);
  }
  .filter-search-box > div {
    line-height: 1.5rem;
  }

  .filter-search-box.clickable {
    white-space: pre-wrap;
    cursor: pointer;
  }

  .filter-search-box {
    .facets-text {
      color: var(--category-egw);
    }
    div {
      line-height: 1.5rem;
      color: var(--text-inactive);
    }

    .facets-icons {
      padding-right: 0.4rem;
      padding-top: 0.5rem;
      color: var(--category-egw);
    }
  }

  .filter-search-box {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.8rem;
    color: #727272;
    white-space: nowrap;
    display: flex;
    margin: 0.5rem 0;
  }
  .form-search {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #aaaaaa;
    display: flex;
  }

  @media screen and (max-width: 480px) {
    .flex-container-vertical .writings-text-box,
    .flex-container-vertical .writings-text-box-vertical {
      flex-grow: 1;
    }

    .filter-search-box > svg,
    .flex-container-vertical .writings-text-box > svg {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    .flex-container-vertical {
      .header-row {
        flex-direction: column;
        .header {
          padding: 10px 0 5px 0;
        }
        .content-row {
          display: flex;
          width: 80%;
        }
      }
      .button-right {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    top: -80px;
  }

  @media screen and (min-width: 1025px) {
    .form-col .form-search {
      width: calc(100% - 50px);
    }
    .form-col {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  .vertical-tabs-list {
    font-family: "Bebas Neue", sans-serif;

    padding: 0 0 1rem 0;

    .vertical-tabs-item {
      font-weight: bold;
      cursor: pointer;
      margin-right: 0.9rem;
      padding-right: 0.9rem;
      position: relative;
      margin-top: 1rem;
      white-space: nowrap;

      &.active {
        color: var(--tab-active);
        text-decoration: underline;
      }

      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 80%;
        width: 0.1rem;
        background-color: var(--gray);
        display: block;
      }
    }
  }
  &.row-1 {
    .relevant-search-content-item {
      flex-basis: 100%;
    }
    .relevant-search-box-left,
    .relevant-search-box-right {
      margin-right: 0;
    }
    .relevant-title {
      max-width: 16.1rem;
    }
    .relevant-search-box {
      display: block;
      max-width: 16.1rem;
    }
    .vertical-tabs-list {
      flex-wrap: wrap;
      align-items: baseline;

      .form-select-field {
        white-space: nowrap;
        svg {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    .vertical-tabs-column,
    .relevant-search-select {
      display: flex;
      width: auto;
    }
    .vertical-tabs-item {
      white-space: nowrap;
    }
  }

  &.row-2 {
    .list-wrap {
      .item {
        flex-basis: 50%;
      }
    }
    .relevant-search-content-item {
      flex-basis: 100%;
    }
    .vertical-tabs-list {
      flex-wrap: wrap;
    }
    .vertical-tabs-item {
      white-space: nowrap;
    }

    .search-relevant-featured-wrapper {
      .relevant-search-content-item {
        flex-basis: 100%;
      }
    }
  }

  &.row-3 {
    .list-wrap {
      .item {
        flex-basis: 100%;
      }
    }
    .relevant-search-content-item {
      flex-basis: 50%;
    }
  }

  &.row-4 {
    .list-wrap {
      .item {
        flex-basis: 100%;
      }
    }
    .relevant-search-content-item {
      flex-basis: 33%;
    }
    .search-relevant-featured-wrapper {
      .relevant-search-content-item {
        flex-basis: 33%;
      }
    }
  }

  &.row-5 {
    .relevant-search-content-item {
      flex-basis: 25%;
    }
    .search-relevant-featured-wrapper {
      .relevant-search-content-item {
        flex-basis: 50%;
        .list-wrap {
          .item {
            flex-basis: 50%;
          }
        }
      }
      .relevant-search-content-item:first-child {
        flex-basis: 25%;
        .list-wrap {
          .item {
            flex-basis: 100%;
          }
        }
      }
      .relevant-search-content-item:last-child {
        flex-basis: 25%;
        .list-wrap {
          .item {
            flex-basis: 100%;
          }
        }
      }
    }
  }

  &.row-6 {
    .relevant-search-content-item {
      flex-basis: 20%;
    }
    .search-relevant-featured-wrapper {
      .relevant-search-content-item:first-child {
        flex-basis: 20%;
        .list-wrap {
          .item {
            flex-basis: 100%;
          }
        }
      }
      .relevant-search-content-item {
        flex-basis: 40%;
        .list-wrap {
          .item {
            flex-basis: 50%;
          }
        }
      }
    }
  }

  &.row-7 {
    .relevant-search-content-item {
      flex-basis: 16.6%;
    }
    .search-relevant-featured-wrapper {
      .relevant-search-content-item {
        flex-basis: 33%;
        .list-wrap {
          .item {
            flex-basis: 50%;
          }
        }
      }
    }
  }

  &.row-8,
  &.row-9,
  &.row-10,
  &.row-11,
  &.row-12 {
    .relevant-search-content-item {
      flex-basis: 16.6%;
    }
    .search-relevant-featured-wrapper {
      .relevant-search-content-item {
        flex-basis: 100%;
        .list-wrap {
          .item {
            flex-basis: 20%;
          }
        }
      }
    }
  }
}

.relevant-search-box {
  display: flex;

  .title,
  .folder-item {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--primary-light);
    align-items: center;
    flex: none;
    order: 0;
    align-self: center;
  }

  .title {
    justify-content: flex-start;
    margin: 0.2rem 0;
  }

  .cols {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    color: var(--text-inactive);
    font-family: "Roboto Condensed", sans-serif;
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 180%;
  }

  .folder-item {
    cursor: pointer;

    &:hover {
      color: var(--text-link-hover);
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15.5rem;
    }
  }

  .ellipsis {
    display: block;
    padding-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.relevant-search-box-left {
  .title {
    justify-content: space-between;
    margin: 0.2rem 0 0.3rem 0.5rem;
    .badge-text {
      letter-spacing: 0;
    }
  }
}

.search-plus-featured-wrapper {
  padding-left: 0;
  h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    margin-left: 0.5rem;
  }
  .list-wrap {
    padding-left: 0.5rem;
  }

  // add border bottom for the open accordion for S+
  .accordion.open {
    border-bottom: 1px solid var(--border-sec);
    margin-bottom: 0.5rem;
  }

  // do each accordion item in single line for S+
  .accordion_body {
    display: flex;
    flex-direction: column;
  }

  // rm unnecessary styles for S+
  .featured-section__item {
    margin: 0;
    font-weight: normal;
  }
}

.relevant-search-view, // For Relevant Search.
.search-plus-featured-wrapper // For right panel.
{
  .folder {
    width: 100%;

    .list-wrap {
      .item {
        button,
        a {
          color: var(--text-default);
          &:hover {
            color: var(--text-link-hover);
          }
        }

        &.passive {
          a {
            color: var(--bc-text);
          }

          color: var(--bc-text);
          font-weight: normal;
        }
      }
    }
  }
}

.search-history-item {
  flex-basis: 150px !important;
}

.relevant-search-box-left,
.relevant-search-box-right {
  display: flex;
  min-width: 225px;
  margin-right: 1.5rem;
  flex-direction: column;

  .relevant-search-item {
    min-height: 100px;
    align-items: flex-start;
  }
}

.relevant-search-box-right {
  width: 100%;
}

.relevant-search-block {
  border-bottom: 1px solid var(--border-sec);
  padding: 0.5rem 0;

  .clickable {
    &:hover {
      cursor: pointer;
      background: var(--highlighted-elem-egw);
      color: var(--text-link-hover);
    }
  }
  &.mobile-view {
    padding: 0;
    border-bottom: none;

    .folder-item {
      padding: 0.2rem 0 0.3rem 0.5rem;
    }
  }
}

.with-badge-wrap {
  display: flex;
}

.relevant-search-content-item {
  display: flex;
  line-height: 1.3rem;
  justify-content: space-between;
  flex: 0 0 10%;
  padding: 0.2rem 0 0.2rem 0.5rem;
  box-sizing: border-box;

  &.overflow-text {
    overflow: hidden;
    .item-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 95%;
    }
  }

  .more-button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .more-button-title {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.4rem;
    color: var(--icon-hover);
  }
  .more-button-icon {
    width: 0.8rem;
    height: 0.8rem;
    fill: var(--icon-hover);
    margin-left: 0.2rem;
  }
}

.search-relevant-featured-wrapper {
  .relevant-search-content-item {
    flex-direction: column;
    border-bottom: 1px solid var(--border-sec);
    .list-wrap{
      border-bottom: none;
    }
  }
}

.search-relevant-featured-fake-wrapper {
  font-family: "Bebas Neue", "Roboto", "Roboto Condensed", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: var(--primary-light);

  h3 {
    font-size: 1.1rem;
    letter-spacing: 0.08em;
    color: var(--bc-text);
    margin-left: 0.5rem;
  }

  .relevant-search-content-item {
    .item {
      display: block;
    }
  }
}

.search-relevant-featured-wrapper {
  display: flex;
  flex-wrap: wrap;

  a {
    color: var(--primary-light);
    text-decoration: none;
  }

  .folder-item {
    margin-bottom: 0.3rem;
    letter-spacing: 0.1em;
    svg {
      display: none;
    }
  }

  .list-wrap {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: start;
    flex-wrap: wrap;

    .item {
      flex: 0 1 200px;
      a {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 0.8rem;
        font-weight: normal;
        line-height: 180%;
        color: var(--text-inactive);
      }

      &.passive {
        a {
          color: var(--bc-text);
        }
      }

      &:hover {
        cursor: pointer;
        background: var(--highlighted-elem-egw);
      }
    }
  }

  .relevant-search-content-item {
    width: 100%;
    margin-bottom: 1rem;
    flex: 0 0 100%;
  }
}

.mode-tablet {
  .relevant-search-view,
  .history-search-view {
    // $top-bar-height: 4rem;
    // margin-top: -#{$top-bar-height};
    margin-top: 0;
  }
}

.mode-zoom-200,
.mode-zoom-250,
.mode-zoom-300 {
  &.mode-tablet {
    .relevant-search-view,
    .history-search-view {
      // $top-bar-height: 3rem;
      // margin-top: -#{$top-bar-height};
      margin-top: 0;
    }
  }
}

.mode-blind {
  .clickable {
    &:hover {
      div {
        color: var(--icon-active);
      }

      cursor: pointer;
      background: var(--highlighted-elem-egw);
      color: var(--icon-active);
      .badge-wrap {
        border: 1px solid var(--highlighted-category-egw);
        .badge-text {
          color: var(--highlighted-category-egw);
        }
      }
    }
  }
}
