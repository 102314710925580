@import "../../styles/layers.scss";
@import "../../styles/variables.module.scss";
@import "../../shared/styles/mixins.scss";

$menuWidth: 12.5rem; //225
$menuWidthAction: 13rem; //235
$menuWidthSelected: 14rem; //250
$menu-item-hidden-z-index: 1;
$menu-item-bigger-z-index: 1;
$menu-item-hovered-z-index: 2;
$menu-item-selected-z-index: 3;
$menu-item-item-more-z-index: 4;

.left-menu {
  // Common class name for mobile and desktop resizer.
  z-index: $left-menu-z-index;

  &.topmost {
    z-index: $left-panel-sliding-z-index;
    transition: transform #{$animation-duration_side-panel} #{$animation-timing-fn_side-panel};
  }
}

.left-slide-menu {
  z-index: $left-panel-sliding-z-index;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  transition: transform #{$animation-duration_side-panel} #{$animation-timing-fn_side-panel};
  background: rgba(0, 0, 0, 0.05);
}

.hidden-menu-btn {
  position: absolute;
  border-top: 1px solid var(--border);
  background-color: var(--popup-background);
  z-index: $menu-item-hidden-z-index;
  width: 100%;
  &.down {
    bottom: 0;
  }
  &.up {
    top: 0;
  }
}

.sideMenuSelected {
  color: var(--menu-item-color-active);
}

.menu-item-icon {
  transition: all 0.25s;

  &.is-show-footer {
    transform: rotate(-180deg);
  }
}

.menu-icon-rotate {
  transform: rotate(90deg);
}

.miSel {
  .menu-item-icon {
    fill: var(--menu-item-color-active);
    color: var(--menu-item-color-active);
  }
}

.sideMenuItemDefault {
  width: 100%;
}

.menuWrap {
  display: flex;
  width: 100%;
  height: 100%;

  .badge-container {
    top: unset;
    bottom: unset;
    right: 0.5rem;

    &.min {
      top: 0.15rem;
      right: 0.15rem;
      width: 1rem;
      height: 1rem;
      font-size: 0.5rem;
    }
  }

  &.sliding {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: var(--background);
    z-index: 100;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    display: block;
  }

  &.sliding,
  &.pinned {
    display: block;
    flex: 0;
  }

  .mode-text {
    .sideMenuMain {
      box-shadow: none;

      .sideMenuItem {
        border-bottom: 1px solid var(--border-sec);
        box-shadow: none;

        > .sideMenuItem-label {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

$z-index_tree-wrap: 0;

.sideMenuMain {
  z-index: #{$z-index_tree-wrap + 1};
  padding: 0;
  margin: 0;
  min-width: $side-menu-item-height;
  background: var(--menu-bg);
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

@keyframes animationSlideInTreeMenu {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

.menuTreeWrap {
  z-index: $z-index_tree-wrap;
  position: relative;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  background: var(--tree-bg);
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;

  animation: animationSlideInTreeMenu #{$animation-duration_side-panel} forwards;
}

.searchMenuWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  .pin-icon {
    position: absolute;
    right: 0;
    font-size: 0.8rem;
    top: 0;
    transform: rotate(90deg);
  }
}

.menuItemWrap {
  height: $side-menu-item-height;
  position: relative;
  font-size: 1.3rem;
  font-weight: bold;

  &.item-more {
    z-index: $menu-item-item-more-z-index;
    position: relative;
    display: flex;
    align-items: center;

    .sideMenuItem {
      position: absolute;
    }

    .hidden-menu-items {
      position: absolute;
      left: 101%;
      width: 100%;
      bottom: 0;

      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      &.expanded {
        .sideMenuItemDefault {
          width: $menuWidth;
        }
      }
      &.with-arrow-down {
        padding-bottom: 1rem;
      }
      &.with-arrow-up {
        padding-top: 1rem;
      }
    }
  }
}

.expanded {
  .menuItemWrap {
    &.item-more {
      .hidden-menu-items {
        left: 100%;
      }
    }
  }
}

.menu-disabled {
  color: var(--menu-item-disabled) !important;
  .sideMenuItem-label {
    color: var(--menu-item-disabled) !important;
  }
  .menu-item-icon {
    color: var(--menu-item-disabled) !important;
  }
}

.sideMenuItem {
  font-size: 1rem;
  line-height: 1.2em;
  text-transform: uppercase;
  position: relative;
  list-style-type: none;
  color: var(--menu-item-color);
  background: var(--menu-bg);
  cursor: pointer;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  font-weight: bold;
  display: flex;
  text-decoration: none;
  border-bottom: 1px solid var(--border-sec);
  transition: width 0.25s;
  // box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  &.sideMenuBigger {
    z-index: $menu-item-bigger-z-index;
  }

  &.sideMenuSelected {
    z-index: $menu-item-selected-z-index;
  }

  .sideMenuItem-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    transition: all 0.25s;
    letter-spacing: 0.03rem;
  }

  .menu-item-icon {
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.25rem;
    min-width: 1.2rem;
    margin: 0 0.8rem;
    fill: var(--menu-item-color);
    color: var(--menu-item-color);

    &.icon-avatar {
      border-radius: 50%;
    }
  }

  &:hover {
    z-index: $menu-item-hovered-z-index;

    .menu-item-icon {
      fill: var(--menu-item-color-hover);
      color: var(--menu-item-color-hover);
    }

    .sideMenuItem-label {
      color: var(--primary);
    }
  }

  .hidden-menu-items {
    .menu-item-icon {
      fill: var(--menu-item-color) !important;
      color: var(--menu-item-color) !important;
    }
    .sideMenuItem {
      .sideMenuItem-label {
        color: var(--menu-item-color) !important;
      }
      &.sideMenuBigger {
        .sideMenuItem-label {
          color: var(--menu-item-color-hover) !important;
        }
        .menu-item-icon {
          fill: var(--menu-item-color-hover) !important;
          color: var(--menu-item-color-hover) !important;
        }
      }
      &.sideMenuSelected {
        .sideMenuItem-label {
          color: var(--menu-item-color-active) !important;
        }
        .menu-item-icon {
          fill: var(--menu-item-color-active) !important;
          color: var(--menu-item-color-active) !important;
        }
      }
      &:hover {
        .sideMenuItem-label {
          color: var(--menu-item-color-hover) !important;
        }
        .menu-item-icon {
          fill: var(--menu-item-color-hover) !important;
          color: var(--menu-item-color-hover) !important;
        }
      }
    }
  }
}

.searchMenu {
  height: 1.2rem;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  margin-right: 0.3rem;
  box-sizing: border-box;
  flex: 1;
}

.tree-mode-icon {
  font-size: 0.9rem;
  margin-right: 0.3rem;
}

.mode-tablet {
  .sideMenuMain {
    width: auto;
  }
  .tree-mode-icon {
    margin-right: 1rem;
  }

  .menuWrap {
    border-right: 1px solid var(--border-sec);

    .mode-text {
      .sideMenuMain {
        .sideMenuItem {
          border-left: none;
          border-right: none;

          &.sideMenuItemDefault,
          &.sideMenuSelected,
          &.sideMenuBigger {
            width: $menuWidth;
          }

          > .sideMenuItem-label {
            margin-left: 2rem;
          }
        }
      }
    }
  }

  .sideMenuItem {
    border-bottom: 1px solid var(--border-sec);
    display: flex;
    text-decoration: none;
    box-shadow: none;

    &.sideMenuItemDefault {
      width: $menuWidth;
    }
  }
}

.mode-desktop {
  /* Fix [EGWW-2736], can't click adv search category, because menu is above. */
  .left-menu {
    pointer-events: none;
  }
  .menuTreeWrap,
  .sideMenuMain {
    pointer-events: all;
  }
  /* ================ */
  .sideMenuSelected {
    width: $menuWidthSelected;
    position: absolute;
    border: none;
    background: var(--menu-bg-active);
    color: var(--menu-item-color-active);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    font-size: 1.3rem;

    .menu-item-icon {
      font-size: 1.4rem;
      width: 1.4rem;
      height: 1.4rem;
      fill: var(--menu-item-color-active);
      color: var(--menu-item-color-active);
      margin-left: 1.3rem;
    }

    &:hover {
      .menu-item-icon {
        margin-left: 1.5rem !important;
      }
    }
  }

  .sideMenuItemCollapsed {
    position: absolute;

    .sideMenuItem-label {
      width: 0;
      transition: none;
    }

    &:hover {
      position: absolute;
      box-shadow: 0 4px 10px var(--shadow);
      width: $menuWidthAction;

      .sideMenuItem-label {
        width: 100%;
        color: var(--primary);
      }

      .badge-container {
        top: unset;
        bottom: unset;
        font-size: 0.7rem;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .sideMenuBigger,
  .sideMenuItemDefault:hover {
    position: absolute;
    border: none;
    // border-bottom: 1px solid var(--border-sec);
    background: var(--menu-bg-hover);
    width: $menuWidthAction;
    color: var(--menu-item-color-hover);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .sideMenuBigger .menu-item-icon,
  .sideMenuItem:hover .menu-item-icon {
    fill: var(--menu-item-color-hover);
    color: var(--menu-item-color-hover);
    font-size: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
    min-width: 1.4rem;
    min-height: 1.4rem;
    margin-left: 1rem;
  }

  .sideMenuBigger:hover {
    width: $menuWidthSelected;
  }

  .menuTreeWrap {
    margin-right: 0.25rem;
    border-left: 1px var(--border-sec) solid;
  }

  .menuWrap {
    .mode-text {
      .sideMenuMain {
        .sideMenuItem {
          &.sideMenuItemDefault,
          &.sideMenuSelected,
          &.sideMenuBigger {
            width: 224px;
          }
        }
      }
    }
  }
  .hidden-menu-items {
    .menu-item-icon {
      fill: var(--menu-item-color);
      color: var(--menu-item-color);
    }
    &.not-expanded {
      .sideMenuItem {
        width: unset;
        &:hover {
          width: $menuWidth;
        }
      }
    }
    .sideMenuItemDefault {
      width: $menuWidth;
      &:hover {
        width: $menuWidthAction;
      }
    }
  }
}

.mode-mobile {
  .left-slide-menu {
    width: 95%;
  }

  .sideMenuItem {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }

  .menuWrap {
    flex: 1;

    .mode-text {
      .sideMenuMain {
        .sideMenuItem {
          margin-top: -1px;
          border: 1px solid var(--border-sec);
        }
      }
    }

    &.sliding {
      .sideMenuMain {
        &.expanded {
          height: 100%;
        }
      }
    }
  }
}

.mode-text {
  .sideMenuItem-label {
    margin-left: 2rem;
  }

  .sideMenuItem {
    box-shadow: none;
    border-bottom: 1px solid var(--border);
    border-right: 1px solid var(--border);
  }

  .sideMenuItemDefault:hover {
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .item-more {
    .menu-item-icon {
      display: none;
    }
  }
}

.mode-blind {
  .sideMenuMain {
    box-shadow: none;
  }

  .menuItemWrap {
    margin-top: -1px;
  }

  .sideMenuItem {
    box-shadow: none;
    border-bottom: 1px solid var(--border);
    border-right: 1px solid var(--border);
    border-top: 1px solid var(--border);
  }

  .sideMenuItemDefault:hover {
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .sideMenuItemCollapsed:hover {
    box-shadow: none;
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .sideMenuItem {
      .menu-item-icon {
        margin: 0 0.5rem;
      }
    }
  }
}
