.timer-flag-wrap {
  position: relative;
  width: 100%;
}

.timer-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 2px solid #5AAECE;
  z-index: 1;
}

.mode-mobile {
  .player-content__time-slider {
    padding: 8px 0;
  }
  &.mode-zoom-200, &.mode-zoom-250, &.mode-zoom-300, &.mode-zoom-400 {
    .timer-flag {
      border-right: 0.2rem solid #5AAECE;
    }
  }
}