@import "./functions";

$root__font-size: 18px;

$mobile-lg-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

$border-radius-lg: 20px;
$border-radius-md: 15px;
$border-radius-sm: 5px;

$font-family-default: Roboto Condensed, sans-serif;

:export {
  md: stripUnit($tablet-width);
  lg: stripUnit($desktop-width);
}
