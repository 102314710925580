.book-package-container {
  font-family: "Roboto Condensed", sans-serif;
  .expandItem {
    height: 100%;
    letter-spacing: normal;
  }
  .expandItem::after {
    position: absolute;
    font-size: 1rem;
    top: 0;
    right: 0;
    margin-right: -1rem;
  }
}

.book-package_head {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  color: var(--bc-text);

  &:hover, &:hover .expandItem {
    color: var(--primary);
  }

  .price-description {
    .egw-font-icon {
      color: var(--icon-color);
    }
  }

  .provided-by {
    margin: 0.8rem 0;
  }

  &.purchased {
    .provided-by {
      align-self: flex-end;
    }
  }

  &.is-single-book {
    line-height: 1.2;

    flex-direction: row;

    .provided-by {
      margin-top: 0;
    }

    .price-description {
      align-items: flex-start;
    }
  }
}

.book-package_head_top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.book-package_prices {
  & > * {
    margin-top: 0.7rem;
  }

  & > :first-child {
    margin-top: 0;
  }
}

.book-package_price-with-arrow {
  display: flex;
  align-items: center;

  .expandItem::after {
    position: static;
  }
}

.mode-mobile {
  .book-package_head {
    &.purchased {
      .provided-by {
        align-self: flex-start;
        margin-bottom: 0.5rem;
      }
    }

    &.is-single-book {
      flex-direction: column;
    }
  }
}
