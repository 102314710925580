/* mobile first */
.search-example-view {
  box-sizing: border-box;
  background: var(--background);
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  padding: 1rem;
  font-size: 0.9rem;

  color: var(--text-inactive);

  .searchString {
    display: inline-block;
    padding: 0.25rem 0;
  }
}

.search-example-view__title {
  padding-left: 0;
  margin-top: 0.3rem;
  margin-bottom: 0;

  font-family: "Bebas Neue", "Roboto", "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: var(--primary-light);
}

.search-item-box {
  flex-direction: column;
  display: flex;
  width: 12rem;
  flex: 50%;
  margin-top: 0.5rem;
  flex-basis: 12rem;
  flex-grow: 1;

  &.full-width {
    flex-basis: 100%;
  }

  .title {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: bold;
    line-height: 1.5;
    color: var(--text-default);
  }

  .clickable {
    cursor: pointer;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-link);

    &:hover {
      color: var(--primary);
      text-decoration: none;
    }
  }

  .delimiter {
    color: var(--text-link);

    &:after {
      content: " | ";
      white-space: pre-wrap;
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.mode-desktop {
  .search-example-view {
    width: 54vw;
  }
}

.mode-mobile {
  .search-example-view {
    padding: 0.5rem;
    width: 90%;
  }

  .search-example-view__popup {
    border-top: 1px solid var(--gray);
  }
}

.mode-blind {
  .search-example-view {
    box-shadow: none;
  }
}
