.bibliography-container {
  max-width: 21.4rem;
}

.bibliography-btn-wrap-m {
  flex-wrap: wrap;

  & div:first-child {
    margin-bottom: 0.4rem;
  }
}

.mode-mobile {
  .bibliography-container {
    max-width: unset;
  }

  .bibliography-btn-wrap-m {
    padding-top: 1.3rem;
  }
}
