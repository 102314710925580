.waveform-control {
  position: relative;
  width: 100%;
  height: 3.3rem;
}

.waveform-control__canvas {
  width: 100%;
  height: 100%;
  z-index: 3;
  fill: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.waveform-control__seek-bar {
  background: black;
  width: 100%;
  height: 100%;
}

.waveform-control__progress {
  height: 100%;
  background: blue;
}

.player-content__time-slider {
  margin-top: 0.6rem;
  margin-bottom: 5px;
}