@use 'sass:math';
@import "src/shared/styles/mixins";

.player-component {
  box-sizing: border-box;
  padding: 0.9rem;

  &.has-medium-size-ui {
    .track-cover__img { height: 10rem; }

    .player-component__header,
    .player-component__body {
      .icon-button-base { font-size: 2rem; }
      .player-controls > i { min-width: 1.2em; }

      .timer-controls-icon { width: 3rem; }
      .timer-controls-icon__svg { width: 2.5rem; }
    }

    .timer-controls-icon__label {
      padding-top: 0.35rem;
      font-size: 1rem;
    }

    .waveform-control { height: 2.5rem; }
  }

  &.has-small-size-ui {
    .track-cover__img {
      max-height: 10rem;
      height: auto;
    }

    .player-component__header,
    .player-component__body {
      .icon-button-base { font-size: 1.4rem; }
      .player-controls > i { min-width: 1.4em; }

      .timer-controls-icon { width: 2.7rem; }
      .timer-controls-icon__svg { width: 1.9rem; }

      .timer-controls-icon__label {
        padding-top: 0.1rem;
        padding-bottom: 0;
        font-size: 0.9rem;
      }
    }
  }

  &.has-medium-size-ui,
  &.has-small-size-ui {
    .player-component__header {
      $children-gap: 0.6rem;
      @include marginChildrenGap($children-gap);
      // paddings - so the icons edges won't be cut (the reason is yet unknown).
      padding-top: math.div($children-gap, 2);
      padding-right: 0.1rem;
      // ============
    }

    .player-component__body {
      padding: 0;
      grid-gap: 1rem;
    }
  }

  &.has-layout-compact {
    .player-component__body {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
      "track-cover track-cover"
      "player-controls player-controls"
      "timer-controls volume-controls"
      "progress-controls progress-controls";
    }
  }
}

.player-component__header {
  display: flex;
  align-items: center;

  .player-component__header__block-empty {
    flex: 1 0;
  }
}

.player-component__header {
  @include marginChildrenGapHorizontal(0.55rem);
}

.player-component__header,
.player-component__body {
  .icon-button-base { font-size: 2.8rem; }
}

.player-component__body {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  align-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "track-cover track-cover track-cover"
    "timer-controls player-controls volume-controls"
    "progress-controls progress-controls progress-controls";
  grid-gap: .5rem;
  margin: 1.2rem auto;
  padding: 0 3.1rem;

  .timer-controls-icon__svg { width: 4.4rem; }
}

.player-component_btn-like {
  &:not(:hover) {
    &.active {
      color: var(--icon-booklist-active);
    }
  }
}

.mode-mobile {
  .player-component__header {
    flex-wrap: wrap; // Let icons wrap to the next line.
    margin: 0 !important; // Prevent horizontal scroll on IOS mobile.
    padding: 0 !important; // Prevent horizontal scroll on IOS mobile.

    .playback-controls {
      width: 100%;
    }
  }
  .player-component__body {
    .icon-hover-slider__slider-wrapper,
    .icon-hover-slider__slider-wrapper__inner {
      width: 100%; // rewrite "wrapper__inner" fixed height, bcz it causes width overflow on IOS mobile.
    }
  }
}

.mode-text {
  .player-component {
    .icon-button-base.text-only { font-size: 1rem !important; }

    .progress-controls {
      margin-top: 2rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid var(--border-sec);
    }

    &.has-small-size-ui {
      .player-component__body {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        grid-template-areas:
        "track-cover"
        "player-controls"
        "progress-controls"
        "volume-controls"
        "timer-controls";
      }
      .text-controls.timer-controls { text-align: left; }
    }
  }

  .player-component__header {
    flex-wrap: wrap; // Let icons wrap to the next line.
    @include marginChildrenGapHorizontal(0.15rem);
    .playback-controls { width: unset; }
  }

  .player-component__body {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    grid-template-areas:
        "track-cover track-cover track-cover"
        "player-controls player-controls player-controls"
        "progress-controls progress-controls progress-controls"
        "volume-controls . timer-controls";
    .timer-controls { text-align: right; }
  }
}
