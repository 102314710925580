.themeMenu {
  min-width: 200px;
  padding: 0.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 0.11rem;
  background: var(--popup-background);
  box-shadow: 0 4px 12px var(--shadow);
  box-sizing: border-box;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  .view-mode-icons svg {
    &.selected {
      fill: var(--primary);

      &:hover {
        fill: var(--text-link);
      }
    }

    &.inactive {
      fill: var(--gray);

      &:hover {
        fill: var(--text-link);
      }
    }
  }
}

.themeMenu__column {
  display: flex;
  flex-direction: column;
}

.themeMenu__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.theme-menu__view-mode-items {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .view-mode-icons {
    width: 100%;
  }
}

.theme-title {
  color: var(--gray);
  padding: 0 0.44rem;
  font-family: "Roboto Condensed", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
}

.theme-menu-button {
  align-items: center;
  display: flex;
  white-space: nowrap;
  width: 100%;
  margin: 0.28rem 0;
  cursor: pointer;

  &:hover {
    .theme-title {
      color: var(--primary)
    }
  }
}

.mode-text {
  .themeMenu {
    display: inline-block;
    cursor: pointer;
    padding: 0.3rem;
    font-size: 1rem;
    line-height: 1rem;
    box-shadow: none;
    border: 1px solid var(--border-sec);

    .view-mode-icons {
      flex-direction: column;
    }
    .view-mode-icons-icon {
      width: 100%;
      text-align: left;
      padding: 0 0.89rem;
    }
  }

  .theme-menu-button {
    justify-content: normal;
    width: 100%;

    .theme-title {
      padding: 0 0.89rem;
    }
  }
}
