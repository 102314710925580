.unsupported-page {
  background-color: rgba(5, 65, 87, 0.9);
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: var(--unsupported-page-z-index);
  * {
    margin: 0;
    padding: 0;
  }
  .container {
    text-align: center;
    padding: 90px 10px 10px 10px;
    max-width: 600px;
    margin: 0 auto;
    h2 {
      font-family: "Bebas Neue", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 56px;
      line-height: 56px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff;
    }
    h4 {
      font-family: "Roboto Condensed", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 37px;
      text-align: center;
      color: #ffffff;
      &:first-of-type {
        margin-top: 70px;
      }
      &:last-of-type {
        margin-bottom: 90px;
      }
    }
    h6 {
      font-family: "Roboto Condensed", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 55px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      li {
        a {
          text-decoration: none;
          color: #ffffff;
          span {
            font-family: "Roboto Condensed", sans-serif;
            display: block;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 23px;
            margin-top: 10px;
            color: #ffffff;
          }
        }
      }
    }
    button {
      background-color: #ff9d5b;
      color: white;
      outline: none;
      border: 1px solid transparent;
      margin-top: 100px;
      border-radius: 5px;
      padding: 14px 40px;
      font-size: 12px;
      cursor: pointer;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
}

html[data-whatinput="keyboard"] {
  .unsupported-page {
    a {
      &:focus {
        text-decoration: underline;
      }
    }

    .button-base {
      &:focus {
        outline: none;
        border: 1px solid var(--outline-border);
        background-color: var(--outline-background);
        color: var(--outline-border);
      }
    }
  }
}

.mode-mobile {
  .unsupported-page {
    .container {
      padding: 44px 16px 16px 16px;
      h2{
        font-size: 32px;
        line-height: 32px;
      }
      h4{
        font-size: 1rem;
        line-height: 21px;
        &:first-of-type {
          margin-top: 20px;
        }
        &:last-of-type {
          margin-bottom: 20px;
        }
      }
      h6{
        font-size: 1rem;
        line-height: 21px;
        color: #FFFFFF;
        text-transform: initial;
      }
      ul{
        flex-wrap: wrap;
        li{
          flex-basis: 50%;
          margin-bottom: 45px;
        }
      }
      button{
        margin-top: 40px;
      }
    }
  }
}
