.category__long-press-wrapper {
  width: 100%;
}

.category-icon {
  transition: all 0.2s;
  fill: var(--tiles-text);
  color: var(--tiles-text);
}

.category-image {
  transition: all 0.2s;
  width: 3rem;
  overflow: hidden;
  max-height: 100%;
  border: 1px solid var(--border);
}

.category-books {
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  flex: 1;
  text-align: end;
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  line-height: 1.1;
  color: var(--tiles-book-count);
}

.categoryTitleWrapper {
  display: flex;
  flex-direction: column;
}

.categoryTitleWrapper .category-books {
  margin-bottom: 0.15rem;
}

.notLoggedInTitle {
  span, i {
    color: var(--tiles-text-disabled) !important;
  }
} 

.imageAndTextContainer {
  display: flex;
  margin-right: 0;
  align-items: normal;
}

.titleNoIcon {
  font-size: 20px;
  line-height: 20px;
}

.langCheckContainer {
  display: flex;
  position: absolute;
  align-items: center;
  top: 0.7rem;
  right: 0.6rem;
  box-sizing: border-box;
}

.lang-check-text {
  color: var(--menu-item-color);
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  margin-right: 0.5rem;
}

.lang-check-icon {
  min-width: 0.9rem;
  height: 0.9rem;
  z-index: 1;
  padding: 0;
}

// remove it use update based on text-mode
.mode-text {

  .categorySpaceItemWrap {
    text-decoration: none;
    height: 3rem;
    box-sizing: border-box;
    color: var(--tiles-text);
    background-color: var(--tiles-bg);
    border: 1px solid var(--border-sec);
    box-shadow: none;
    align-items: normal;

    &.notLoggedInTitle .category-title {
      color: var(--tiles-text-disabled);
    }

    &:hover {
        cursor: pointer;
        background-color: var(--tiles-bg-hover);
      }
  }

  .text-category-item {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 0 1rem;
    flex-direction: column-reverse;
    justify-content: center;
    color: var(--tiles-text);

    .category-title {
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: 0.1em;
      overflow: hidden;
    }
    .category-books {
      margin-top: 1rem;
    }
  }

  &.notLoggedInTitle {
    .text-category-item {
      color: var(--tiles-text-disabled);
    }
  }
  &.mode-mobile {
    .category-title {
      white-space: nowrap;
    }
  }
}

.title-no-icon-min {
  font-size: 0.8rem;
  line-height: 1.1;
}

.categorySpaceItemWrap {
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  color: var(--tiles-text);
  box-shadow: 0 4px 15px var(--shadow);
  display: inline-flex;

  svg {
    fill: var(--tiles-text);
  }

  &.has-checkbox {
    .categoryListItem {
      padding-right: 2rem;
    }
  }
}

.category-title {
  font-size: 1em;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.mode-tablet,
.mode-desktop {
  .text-category {
    margin: 2px;
    width: 240px;
    height: 132px;
    display: inline-table;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background-color: var(--tiles-bg-text-mode-not-mobile);

    .text-category-item {
      padding: 1rem;
      height: 132px;
      flex-direction: column;
      margin-top: 0;
    }
  }

  .category-title {
    max-height: 4em;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    position: absolute;
    text-align: right;
  }

  .categorySpaceItemWrap {
    width: 13rem;
    height: 10.3rem;
    margin: 0.3rem;
    position: relative;
    box-shadow: none;
  }

  .category-icon {
    font-size: 3.3rem;
    width: 3.3rem;
    height: 3.3rem;
  }

  .category-image {
    width: 4.3rem;
    height: 3.3rem;
    box-shadow: 0 4px 15px var(--shadow);
    border: none;
  }

  .categoryListItem {
    background-color: var(--tiles-bg-alpha);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    font-size: 1.33rem;
    padding: 2.1rem 1rem;
    transition: all 0.2s;
    justify-content: space-between;
    align-items: normal;
    flex-direction: column;
    box-shadow: 0 3px 6px var(--shadow);

    @media(hover:hover) {
      &:hover {
        cursor: pointer;
        background-color: var(--tiles-bg-hover);
        margin: -0.2rem;
        font-size: 1.43rem;

        .category-icon {
          fill: var(--tiles-text-hover);
          width: 3.8rem;
          height: 3.8rem;
          color: var(--tiles-text-hover);
          font-size: 3.8rem;
        }

        .category-image {
          width: 5rem;
          height: 4rem;
        }
      }
    }
  }
}

.mode-tablet {
  .category-image {
    width: 4.3rem;
    height: 3.3rem;
    box-shadow: 0 4px 15px var(--shadow);
    border: none;
  }

  .category-icon {
    width: 3.3rem;
    height: 3.3rem;
    font-size: 3.3rem;
  }
  &.mode-blind {
      .categoryListItem {
        box-shadow: none;
        @media(hover:hover) {
          &:hover {
            margin: 0;
    
            .category-icon {
              width: 3.3rem;
              height: 3.3rem;
              font-size: 3.3rem;
            }
          
            .category-image {
              width: 4.3rem;
              height: 3.3rem;
              box-shadow: none;
              border: 1px solid var(--border);

            }
          }
        }
      }
    
  }
}

.mode-mobile {
  .category-icon {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
  }

  .categoryListItem {
    display: flex;
    flex: 1;
    box-sizing: border-box;
    padding: 0 0.9rem;
    transition: all 0.2s;
    align-items: center;
    position: relative;
    width: 100%;

    .categoryTitleWrapper {
      overflow: hidden;
      .category-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .category-books {
    text-align: start;
  }

  .category-title {
    font-size: 1.2rem;
    margin-top: 0.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imageAndTextContainer {
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    margin-right: 0.75rem;
  }

  .categorySpaceItemWrap {
    background-color: var(--tiles-bg);
    height: 3.3rem;
    width: 100%;
    display: flex;
    &:active {
      background-color: var(--tiles-bg-active);
      color: var(--tiles-text-hover);
    }
  }

  .category-image {
    border: 1px solid var(--border);
    box-shadow: none;
    height: 2rem;
  }
  &.mode-text {
    .categorySpaceItemWrap {
      align-items: center;
    }
    .category-books {
      margin-top: 0;
    }
  }
  &.mode-blind {
      .categorySpaceItemWrap {
        border: none;
        border-top: 1px solid var(--border);
        box-shadow: none;
        &:last-child {
          border-bottom: 1px solid var(--border);
        }
      }
      .categoryListItem {
        box-shadow: none;
        @media(hover:hover) {
          &:hover {
            margin: 0;
    
            .category-icon {
              font-size: 2rem;
              width: 2rem;
              height: 2rem;
            }
          
            .category-image {
              width: 3rem;
              height: 2rem;
              max-height: 100%;
              box-shadow: none;
              border: 1px solid var(--border);
            }
          }
        }
      }
    }
}

.mode-blind {
  .categorySpaceItemWrap {
    border: 2px solid var(--border);
    box-shadow: none;
  }
  .category-image {
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .categoryListItem {
    box-shadow: none;
    @media(hover:hover) {
      &:hover {
        margin: 0;
        .category-icon {
          font-size: 3.5rem;
          width: 3.5rem;
          height: 3.5rem;
        }
      
        .category-image {
          width: 4.3rem;
          height: 3.3rem;
          box-shadow: none;
          border: 1px solid var(--border);
        }
      }
    }
  }
}

.mode-mobile {
  .categorySpaceItemWrap  {
    .categoryListItem {
      padding-right: 0.5rem;
    }
  }
  .categoryTitleWrapper {
    flex: 1;
  }
  .langCheckContainer {
    position: static;
    order: 3;
  }
}
.mode-zoom-150 {
  &.mode-mobile {
    .lang-check-text {
     letter-spacing: normal;
      margin-right: 0.2rem;
    }
  }
}
