@import "../shared/styles/egw-web-font.scss";

.no-scroll {
  overflow: hidden;
}

[contenteditable] {
  user-select: text;
}

::selection {
  background: var(--selection-background);
}

.popup-container {
  position: fixed;
  z-index: var(--popup-container-z-index);
  padding: 0.25rem;
  display: flex;
  background: var(--background);
  box-shadow: 0 4px 12px var(--shadow);

  &.text-only {
    padding: 0;
  }

  svg,
  i {
    margin: 0 0.6rem;
    position: relative;
    z-index: 5;
    min-width: 1.1rem;
    min-height: 1.1rem;

    &:hover {
      cursor: pointer;
      fill: var(--primary);
    }
  }

  a {
    max-height: 2.2rem;
    display: inline-block;
  }
}

.contentMain {
  width: 100%;
  display: flex;
}

.dropDownMenuBack {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: var(--dropdown-menu-back-z-index);
}

.expandItem {
  text-decoration: none;
  user-select: none;
  padding-right: 0.3rem;
  cursor: pointer;
  color: var(--bc-text);
  display: inline-block;
  position: relative;
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  line-height: 1;

  &::after {
    font-family: "egw-web-font";
    content: map-get($egw-web-font-map, "chevron-right");
    display: inline-block;
    padding-right: 0.1rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: 0.7em;
    padding-left: 0.3rem;
    transition: all 0.3s;
  }

  &:hover,
  .expandItem.collapsed {
    color: var(--primary);
  }
}

.expandItemActive {
  color: var(--bc-text-active);
}

.expandItemActive:active {
  color: var(--bc-text-active);
}

.expandChevronRotate::after {
  transform: rotate(90deg);
}

.initLoad {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.errorMessage {
  color: var(--primary);
  font-size: 2rem;
  text-align: center;
  line-height: 2rem;
}

.iconInActive,
.iconActive {
  cursor: pointer;
  transition: all 0.2s;

  svg {
    transition: all 0.2s;
  }
}

.iconInActive {
  color: var(--icon-color);

  svg {
    fill: var(--icon-color);
    color: var(--icon-color);
  }
}

.iconActive {
  color: var(--icon-active);

  svg {
    fill: var(--icon-active);
    color: var(--icon-active);
  }
}

@media (hover: hover) and (pointer: fine) {
  .iconActive:hover,
  .iconInActive:hover {
    color: var(--icon-hover);

    svg {
      fill: var(--icon-hover);
      color: var(--icon-hover);
    }
  }
}

.iconDisabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--icon-disable);

  svg {
    fill: var(--icon-disable);
  }
}

.modalBack {
  width: 100vw;
  height: 100vh;
  z-index: 395;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
}

.showView {
  transition: visibility 0.1s, opacity 0.1s linear;
  opacity: 1;
  visibility: visible;
}

.hideView {
  transition: visibility 0s 0.1s, opacity 0.1s linear;
  opacity: 0;
  visibility: hidden;
}

.hide {
  display: none;
}

.nonClickable {
  pointer-events: none;
}

.textDimensionCalculation {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
}

.inputField {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  line-height: 110%;
  color: var(--text-inactive);
  margin-top: 0.4rem;
  box-sizing: border-box;
  padding: 0.55rem;
  border: 1px solid var(--gray);
  background: var(--background);

  &::placeholder {
    color: var(--gray);
  }
}

.relativePosition {
  position: relative;
}

.icon-margin {
  margin: 8px 4px;
}

.icon-padding {
  padding: 6px 3px;
  margin: 2px 1px;
}

.popup-wrap-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.text-button {
  display: inline-flex;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  cursor: pointer;

  &:hover {
    color: var(--icon-hover);

    svg {
      fill: var(--icon-hover);
    }
  }

  &.bordered {
    width: auto;
    height: auto;
    min-width: auto;
    min-height: auto;
    font-family: "Bebas Neue", sans-serif;
    padding: 6px 8px 4px 8px;
    border-radius: 15px;
    border: 1px solid var(--icon-color);
    color: var(--icon-color);
    font-size: 16px;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;

    &:hover {
      color: var(--icon-hover);
      border-color: var(--icon-hover);
    }

    &.iconActive {
      border: 1px solid var(--icon-active);
      color: var(--icon-active);
    }
  }
}

.icon-button-base {
  &.text-only {
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    cursor: pointer;
    width: auto;
    height: auto;

    &:hover {
      color: var(--icon-hover);
    }

    &.icon-button-active {
      color: var(--icon-active);
    }

    &.icon-button-disabled {
      color: var(--icon-disable);
    }

    &.bordered {
      font-family: "Bebas Neue", sans-serif;
      padding: 0.25rem 0.5rem;
      border-radius: 0.9rem;
      border: 1px solid var(--icon-color);
      color: var(--icon-color);
      font-size: 0.9rem;
      line-height: 0.9rem;
      margin-top: 0;
      margin-bottom: 0;

      &:hover {
        color: var(--icon-hover);
        border-color: var(--icon-hover);
      }

      &.icon-button-disabled {
        color: var(--icon-disable);
        border-color: var(--icon-disable);
      }

      &.icon-button-active {
        border-color: var(--icon-active);
        color: var(--icon-active);
      }
    }
  }
}

.mode-blind {
  .iconActive {
    background-color: var(--icon-background);
    border: 2px solid var(--icon-background);
    &:hover {
      color: var(--icon-active);
      svg {
        fill: var(--icon-active);
      }
    }
  }
  .search-top-panel-wrapper {
    .icon-button-base {
      outline: 2px solid transparent;
  
      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &.icon-button-active:hover {
          background-color: var(--icon-color);
          outline: 2px solid var(--icon-color);
          fill: var(--icon-active);
          color: var(--icon-active);
        }
      }
  
      &.icon-button-active {
        outline: 2px solid var(--icon-background);
        background-color: var(--icon-background);
        color: var(--icon-active);
      }
      &:active {
        outline: 2px solid var(--icon-background);
        background-color: var(--icon-background);
      }
    }
  }

  .icon-button-base {
    &.icon-button-active {
      outline: 2px solid var(--icon-background);
      background-color: var(--icon-background);
      color: var(--icon-active);
      fill:   var(--icon-active);;

      &:hover{
        outline: 2px solid var(--icon-color);
        background-color: var(--icon-color);
      }
    }
    &:active {
      outline: 2px solid var(--icon-background);
      background-color: var(--icon-background);
    }
  }
 
  .popup-container {
    box-shadow: none;
    outline: 1px solid var(--border);
  }
  .panel-popup {
    box-shadow: none;
    outline: 1px solid var(--border);
  }
}

.loadingWrap {
  &.flex-center {
    display: flex;
    flex: 1;
    align-items: center;
    justify-items: center;
    flex-direction: column;
  }
}

// TODO research and remove if it possible
// hide a popup while selection a text in a reader
.status-selection {
  .popup-background {
    &.reader-context-menu-popup {
      opacity: 0;
      display: none;
    }
  }
}

a {
  color: var(--text-link);

  &:hover {
    color: var(--text-link-hover);
  }

  &[disabled] {
    pointer-events: none; // Simulate disability of the actions.
  }
}

html,
body {
  user-select: none;
}

body {
  .reader-content-wrap {
    user-select: text;
    width: 100%;
    box-sizing: border-box;
  }

  .SplitPane {
    user-select: none !important;
  }

  .passive-reader {
    .reader-content-wrap {
      user-select: none;
    }

    &:active {
      .reader-content-wrap {
        user-select: text;
      }
    }
  }
}

.show-popup-text-button {
  font-family: "Bebas Neue", sans-serif;
  display: inline-block;
  position: relative;

  &::after {
    font-family: "egw-web-font";
    content: map-get($egw-web-font-map, "chevron-right");
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    font-weight: 700;
    font-size: 0.8rem;
    width: 0.7rem;
    color: var(--icon-color);
    transition: all 0.3s;
    right: 0;
  }

  &.active {
    color: var(--icon-active);
    background-color: var(--icon-background);
    &::after {
      content: map-get($egw-web-font-map, "chevron-down");
      color: var(--icon-active);
      background-color: var(--icon-background);
    }
  }
}

.panel-popup {
  &.panel-popup-columns {
    max-width: none;
    padding: 12px;
    top: 29px;
    flex-direction: column;

    &.text-only {
      box-shadow: none;
      border: 1px solid var(--border);
    }
  }
}

.columns-wrapper {
  display: flex;
  width: 100%;

  .icons-column {
    text-align: center;
    overflow: hidden;
    padding: 0.1rem 0.75rem;
    border-right: 1px solid var(--border);

    &:first-of-type {
      padding-left: 0.1rem; // 0.1rem - to make current active element highlight visible because of "overflow: hidden" on the parent.
    }

    &:last-of-type {
      padding-right: 0.1rem; // 0.1rem - to make current active element highlight visible because of "overflow: hidden" on the parent.
      border-right: none;
    }

    .default-select {
      margin-bottom: 0.5rem;

      .text-only {
        &.icon-margin {
          margin: 0;
        }
      }
    }

    .text-only {
      font-family: "Roboto Condensed", sans-serif;
      display: block;
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none;
      border-radius: initial;
      padding: 0;
      color: var(--text-default);

      &.icon-button-active {
        outline: 2px solid var(--icon-background);
        background-color: var(--icon-background);
        color: var(--icon-active);

        &:hover {
          color: var(--icon-active);
        }
      }

      &.icon-button-disabled {
        color: var(--icon-disable);
      }

      &:hover {
        color: var(--icon-active);
      }

      &:last-of-type {
        margin-bottom: 0;
        margin-top: 0;
      }

      &.iconInActive {
        color: var(--text-default);
      }

      &.iconActive {
        color: var(--icon-active);
      }

      &.iconActive:hover,
      &.iconInActive:hover {
        color: var(--icon-hover);
      }

      &.iconDisabled {
        color: var(--icon-disable);
      }
    }
  }
}

button {
  background: transparent;
  text-align: inherit;
  box-shadow: none;
  border: none;
  display: inline-block;
  width: auto;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  &.wide {
    width: 100%;
  }
}

.at-expanded-menu-close {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

path {
  pointer-events: none;
}

.mode-tablet {
  .expandItem {
    span {
      display: inline-block;
      max-width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mode-zoom-300,
.mode-zoom-400 {
  &.mode-tablet,
  &.mode-mobile {
    #mainApp {
      margin-top: var(--header-zoom-height);
      min-height: calc(100vh - var(--header-zoom-height)) !important;
    }
  }
}

.mode-text {
  &.mode-blind {
    .controls-wrap {
      .show-popup-text-button:hover:after {
        background-color: var(--icon-background);
        color: inherit;
      }
    }

    .columns-wrapper {
      .icons-column {
        .text-only {
          &.icon-button-active {
            &:hover:not(:active) {
              color: var(--icon-active);
            }
          }
          &:hover:not(:active) {
            color: var(--icon-background);
          }
        }
      }
    }

    .show-popup-text-button {
      &:hover {
        &:after {
          color: var(--icon-background);
        }
      }
    }
  }

  .show-popup-text-button {
    &:hover {
      &:after {
        color: var(--icon-hover);
      }
    }
  }
}

//must be global because split pane uses in different places
.Resizer {
  background: var(--border-sec);
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:hover {
    background: var(--primary);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.Resizer.horizontal {
  height: 3px;
  min-height: 3px;
  margin: -1px 0;
  border-top: 1px solid var(--background);
  border-bottom: 1px solid var(--background);
  cursor: row-resize;
  width: 100%;
  &:hover {
    border-top: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);
  }
}

.Resizer.vertical {
  width: 3px;
  min-width: 3px;
  margin: 0 -1px;
  border-left: 1px solid var(--background);
  border-right: 1px solid var(--background);
  cursor: col-resize;
  &:hover {
    border-left: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
  }
}

.Resizer.disabled {
  cursor: not-allowed;
  &:hover {
    border-color: transparent;
  }
}
