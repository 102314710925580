@import "../../styles/layers";

@mixin setActionButtonsSizes($size) {
  .book-description-cover {
    .action-buttons {
      & > .egw-font-icon {
        font-size: $size;
      }
    }
  }
}

.book-description-cover {
  z-index: $book-list-item-cover__desc-z-index;
  position: absolute;
  top: 0;
  min-height: 9.15rem;
  box-sizing: border-box;
  padding: 0.4rem;
  font-size: 0.78rem;
  font-family: "Roboto Condensed", serif;
  background-color: var(--popup-background);
  box-shadow: 0 4px 12px var(--shadow);

  &.is-translation-cover {
    .book-cover-part-left {
      height: 9rem;
    }
  }

  .book-cover-part-left {
    display: flex;
    flex-direction: column;
    height: 7.6rem;
    overflow: hidden;
  }

  .book-cover-part-right {
    margin-top: 0.7rem;

    & > .book-row {
      align-items: flex-start;
      flex-direction: column;

      .actions-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 0.3rem;
      }

      .book-download-links {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
    }
  }

  .book-desc-info-main {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.5rem;
    margin-bottom: 0.55rem;
  }

  .title-wrapper {
    overflow: hidden;
    color: var(--text-link);
  }

  .title,
  .author {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    padding-top: 0.2rem;
    font-size: 0.9rem;
    font-weight: bold;
    font-family: "Bebas Neue", serif;
  }

  .pagesNum-wrapper {
    color: var(--secondary);
    display: flex;
    flex-direction: column;
  }

  .lang {
    text-transform: capitalize;
  }

  .scroll-wrapper-description {
    height: 100%;
    font-size: 0.8rem;
    line-height: 1rem;
    color: var(--text-default);
    overflow: hidden;
  }

  .desc-block {
    display: block;
    overflow: hidden;
    max-height: calc(1rem * 5);
  }

  .btn-download {
    height: 100%;
    .button-link {
      // Compensate specific font vertical align.
      padding: 0.1rem 0;
      font-size: 0.88rem;
    }
  }

  .bookItemCode {
    width: 100%;
  }

  &.has-compact-view {
    padding: 0.33rem 0.38rem;

    .book-desc-info-main {
      .pagesNum-wrapper {
        flex-direction: row;

        .lang {
          margin-left: 0.5rem;
        }
      }
    }

    .desc-block {
      max-height: calc(1rem * 3);
    }

    .book-row.listRow.with-static-download {
      margin-left: 0;
      margin-top: 1.11rem;
      flex-direction: column;
      align-items: normal;

      .book-download-links {
        display: flex;
        align-items: center;
      }
    }
  }

  &.has-compact-view,
  &.is-shown-on-both-sides {
    .book-desc-info-main {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      margin-bottom: 0;
    }
  }

  &.is-shown-on-both-sides {
    display: flex;
    justify-content: space-between;

    .book-cover-part-left {
      height: unset;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // Ios, Android wrong width fix.
      flex-grow: 0;
      flex-shrink: 1;
      min-width: 0;
    }

    .book-cover-part-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // Reset top indent when the block is under the image.
      margin-top: 0;
      // Ios, Android wrong width fix.
      flex-grow: 0;
      flex-shrink: 1;
      .book-row {
        flex-direction: row;
        justify-content: center;
      }
    }

    .desc-block {
      max-height: calc(1rem * 6);
    }

    .book-cover-part-left {
      .book-row {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .action-buttons {
      width: 100%;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .book-download-links {
      width: 100%;
      flex-wrap: wrap;
    }

    .btn-download {
      margin-left: 0;
    }
  }
}

.translate-title {
  border-bottom: var(--border) 1px solid;
}

.translate-wrapper {
  display: flex;
  color: black;
  flex-direction: column;
  margin-top: 0.25rem;
  letter-spacing: 0.05em;
  font-size: 0.75rem;
  justify-content: space-between;

  &.big {
    font-size: 1rem;
  }

  .transalte-button-wrap {
    margin-top: 0.5rem;
    display: flex;
    column-gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
  }
}

.translate-wrapper-item {
  display: flex;
  justify-content: space-between;
  color: var(--text-default);
  margin-top: 0.25rem;

  .translate-stat-title {
    flex: 3;
  }

  .translate-stat-value {
    flex: 1;
    text-align: right;
  }
  .translated {
    color: var(--secondary);
  }
  .reviewed {
    color: var(--quaternary);
  }
}

@include setActionButtonsSizes(1.1rem);

.mode-mobile {
  .book-description-cover {
    &.is-shown-on-both-sides {
      padding: 0.3rem 0.2rem;
    }
  }

  @include setActionButtonsSizes(1rem);
}

.mode-mobile,
.mode-tablet {
  .book-description-cover {
    min-height: 8.15rem;
    padding: 0.4rem 0.3rem;
    &.is-shown-on-both-sides {
      .book-cover-part-left {
        min-height: 8.1rem;
      }
      .book-desc-bottom-part {
        .action-buttons,
        .book-download-links {
          justify-content: center;
          text-align: center;
          .image-button {
            &:last-child {
              span {
                &::after {
                  content: "" !important;
                }
              }
            }
          }
        }
      }
    }

    .btn-download {
      padding: 0;
    }
  }

  .book-description-cover {
    &.show-bookshelf {
      .images-for-download-actions {
        margin: 0;
      }
    }
  }
}

.mode-desktop {
  .book-description-cover {
    &.is-shown-on-right-side {
      .bookItemCode {
        text-align: right;
        align-self: flex-end;
        margin-left: auto;
      }
    }
  }
}

.mode-tablet {
  .book-description-cover {
    &.is-shown-on-right-side.is-shown-on-top {
      .bookItemCode {
        text-align: right;
        align-self: flex-end;
        margin-left: auto;
      }
      .book-desc-bottom-part {
        order: -1;
      }
    }
  }
}

.mode-text {
  .book-description-cover {
    &.has-compact-view {
      .book-row.listRow.with-static-download {
        flex-direction: column;
        align-items: normal;
      }
    }
    .image-button,
    .button-base {
      font-size: 1rem !important;
    }
    &.is-shown-on-both-sides {
      flex-wrap: wrap;

      .book-desc-bottom-part {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .desc-block {
        max-height: calc(1rem * 8);
      }

      .book-row.listRow.with-static-download .actions-wrapper {
        align-items: center;
      }

      &.show-bookshelf {
        .book-cover-part-left {
          justify-content: normal;
        }
      }

      &.is-shown-on-top {
        .book-desc-bottom-part {
          order: -1;
          margin-bottom: 0.7rem;
        }
      }
    }
  }
}

.mode-blind {
  .book-description-cover {
    box-shadow: none;
    border: 1px solid var(--border);
  }
}
