/* Safari 10+ */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    .sc-tree-wrap {
      height: calc(100% - 57px);
    }

    .reader-page-wrap {
      .SplitPane {
        display: flex;
        flex: 1 1 0;
        height: inherit!important;

        &.vertical {
          flex-direction: row;
          height: 100% !important;
        }

        &.horizontal {
          flex-direction: column;
          height: 100% !important;

          .Pane {
            .reader-panel-scrollbar {
              height: 100% !important;
            }
          }
        }
      }
    }

    .mode-tablet {
      .book-details {
        .SplitPane {
          &.vertical {
            .Pane1, .Pane2 {
              height: 100%;
            }
          }
        }
      }
    }

    .mode-desktop {
      .mainWrapper {
        height: -webkit-calc(100% - 122px) !important;
      }
    }
  }
}
