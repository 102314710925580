.system-wrap {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.system-page__btn-clear {
  align-self: flex-start;
}

.system-table-header {
  font-weight: bold;
}

.system-table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.system-name {
  flex: 3;
}

.system-count {
  flex: 1.5;
}

.system-size {
  flex: 2;
}

.system-lu {
  flex: 2;
}
.system-action {
  flex: 1;
}


.system-table-row {
  border-bottom: 1px solid var(--border);
  padding: 0.5em 0;
  display: flex;
}