@import "../../styles/variables.module.scss";
@import "src/shared/styles/mixins";

$border-size: 1px;
$border: $border-size solid var(--border);
$book-description-side-indent: 0.6rem;

.book-details {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  height: 100%;

  .book-image-wrap {
    height: auto; // For Safari 11.1.
    overflow: hidden; // Forbid image to go out of the boundaries.
  }
}

.bookInfoContentContainer {
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px; // Fix [EGWW-2599].
  padding: 2rem 0.7rem 0.6rem;
  background-color: transparent;
}

.book-details-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;

  &.line-up-in-one-column {
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    margin: 0 0.9rem;

    .bookImageContainer {
      .book-image-wrap {
        max-height: none; // Prevent image cropping.
      }
    }

    .bookDescriptionContainer {
      width: initial;
    }
  }
}

.book-details {
  .player-component {
    border: 1px solid var(--border);
  }
}

.bookImageContainer {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-width: 14rem;
  margin: 0 auto 1rem;

  .book-image-wrap {
    max-height: 20rem; // Prevent outgrowth.
  }

  .bookCover {
    box-shadow: 2px 4px 8px var(--shadow);
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
}

.image-block_book-cover {
  min-height: 20rem;
  min-width: 14rem;

  .text-over-image {
    font-size: 1.5rem;
    @include lineClamp(8, 1.35);
  }
}

.bookBtnGridContainer {
  margin-top: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-actions-container {
  display: grid;
  grid-gap: 0.6rem;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  margin-top: 0.6rem;
  flex-grow: 1;
  flex-shrink: 0;

  &.actions-download {
    grid-gap: 0.3rem;
  }

  &:first-of-type {
    margin-top: 0 !important;
  }

  &.translate-container {
    width: 70%;
    margin-top: 0px;
  }

  .button-base {
    letter-spacing: 0.1em;
    font-size: 0.9rem;

    .button-link {
      font-size: 0.9rem;
    }

    &.height-tiny {
      $font-size: 0.8rem;
      height: 1.3rem;
      font-size: $font-size;
      letter-spacing: 0.05em;

      .button-link {
        font-size: $font-size;
      }
    }

    &.height-small {
      $font-size: 0.8rem;
      height: 1.8rem;
      font-size: $font-size;

      .button-link {
        font-size: $font-size;
      }
    }

    &.height-middle {
      min-height: 2.2rem;
    }
  }
}

.bookDescriptionContainer {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  width: 0;
  min-width: 14rem; // Prevents book translations carousel from stretching ".bookDescriptionContainer" width and thus wrap to the bottom.
  margin-left: 3rem;
  margin-right: $book-description-side-indent;

  user-select: text;

  svg:hover {
    fill: var(--primary);
  }

  a {
    text-decoration: none;
    color: var(--btn-sec-outline-text);
    word-wrap: break-word;

    &:hover {
      text-decoration: underline;
      color: var(--text-link-hover);
    }
  }
  > span,
  p {
    font-family: "Roboto Condensed", sans-serif;
    line-height: 1.5rem;
  }
  > .price {
    font-size: 1.3rem;
  }
}

.bibliographyContainer {
  margin-top: 2.5rem;
}

.bibliographyContainer .bookInfoTitle {
  font-size: 1.3rem;
  color: var(--primary-light);
}

.bookDescriptionContainer .bibliographyContainer p {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

.bibliographyContainer p strong {
  display: inline-block;
  margin-right: 0.2rem;
}

.bookDescriptionHeader__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.bookDescriptionHeader__pagesLang {
  display: flex;
  color: var(--secondary);
  align-items: center;
}

.bookDescriptionHeader__pagesLang > span,
.bookContentIcon {
  margin-right: 0.6rem;
}

.bookDescriptionHeader__pagesLang > span:last-child {
  margin-right: 0;
}

.bookDescriptionHeader__lang {
  display: inline-block;
}

.bookDescriptionHeader {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--primary-light);
}

.bookDescriptionContainer {
  .scroll-list-with {
    margin-bottom: 1rem;
  }

  p.book-code {
    color: var(--form-label-color);
  }

  .book-copy-icon {
    margin-left: 0.3rem;
    color: var(--icon-color);
    font-size: 0.9rem;

    &:hover {
      cursor: pointer;
      color: var(--icon-hover);
    }
  }

  p {
    color: var(--text-default);
    margin-bottom: 0;
  }
}

.bookInfoDescription {
  margin: 0;
}

.bookInfoTitle {
  display: flex;
  justify-content: space-between;
  font-family: "Bebas Neue", sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: 0.08em;
  font-weight: bold;
  margin-top: 0;
  text-transform: uppercase;
}

.bookInfoAuthor {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 0.6rem;
  margin-bottom: 0.5rem;
}

.bookInfoSmallMargin {
  margin-top: 0.4rem;
}

.bookInfoBigMargin {
  margin-top: 1.7rem;
}

.bookTextUppercase {
  text-transform: uppercase;
}

.bookContentIcon {
  min-width: 1.1rem;
  min-height: 1.11rem;
  cursor: pointer;
}

.bookContentIcon.text-only {
  letter-spacing: 0.05em;
  align-self: flex-start;
}

.bookInfoTitleContainer {
  display: flex;
  letter-spacing: 0.02rem;
  flex: 0 0 auto;
  align-items: center;
  color: var(--primary-light);
}

.bookInfoTitleContainerMin {
  flex-direction: column;
  margin-left: 0;
}

.bookInfoContentTitle {
  font-size: 1.1rem;
  line-height: 1.1rem;
  white-space: nowrap;
  max-width: 73%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bookInfoContentTitleMin {
  font-size: 2.2rem;
  line-height: 2.2rem;
  padding-bottom: 0.6rem;
}

.bookInfoGrayText {
  font-size: 1.1rem;
  line-height: 1.1rem;
  white-space: nowrap;
  color: var(--text-inactive);
}

// Customize resizer line.
.book-details .Resizer {
  min-width: unset;
  width: $border-size;
  margin: $border-size #{-$border-size} 0;
  visibility: visible;
  border-style: none;
  background: var(--border);

  &:hover {
    border-style: none;
    background: var(--primary);
  }
}

.book-details-scroll,
.book-details .vertical .Resizer,
.book-details .Pane1,
.book-details .Pane2 {
  height: 100% !important;
}

// Indent between panes.
.book-details .Pane1 {
  margin-right: 0.4rem;
}

// Book TOC border when is open as a pane.
.book-details.is-book-toc-visible .Pane2 {
  border-top: $border;
  border-bottom: $border;
  border-right: $border;
}

// Book TOC border when it is shown only.
.book-details.is-toc-shown-only {
  .book-details-inner {
    border: $border;
  }
}

.mode-mobile {
  .book-details {
    flex-direction: column;
    height: unset;
  }

  .bookInfoTitle {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }

  // Remove player border.
  .book-details {
    .player-component {
      padding: 0;
      border: none;
    }
  }
  .bookInfoTitleContainerMin {
    margin-bottom: 0.5rem;
  }
}

.mode-text {
  .book-details {
    .bookCover {
      // Add static height as there is no image in the text mode.
      min-height: 20rem;
    }
  }
}

.mode-mobile,
.mode-tablet {
  .book-details {
    // So as not to stick to the block on the left.
    .bookDescriptionContainer {
      margin-left: $book-description-side-indent;
    }

    .bookInfoBigMargin {
      margin-top: 0.6rem;
    }
  }
}

.mode-tablet,
.mode-desktop {
  .book-details.is-toc-shown-only {
    .book-details-inner {
      height: 100%;
    }
  }

  .book-details-inner {
    &.line-up-in-one-column {
      .player-component {
        // Prevent the CSS borders content overflow.
        height: initial;
      }
    }
  }
}

.mode-blind {
  .book-details {
    .bookCover {
      box-shadow: none;
    }
  }
}

.mode-zoom-400 {
  .book-details {
    $items-indent: 0.3rem;
    .bookImageContainer {
      width: 100%;
      max-width: none;
      display: flex;
      flex-wrap: wrap;
      margin: -#{$items-indent};
    }

    .book-image-wrap,
    .bookBtnGridContainer {
      padding: $items-indent;
    }

    .book-image-wrap {
      flex-grow: 1;
      justify-self: center;
      min-width: 5.6rem;
      width: calc(45% - 0.6rem);
      // Make block height the same as the image inside has.
      line-height: 0;
    }

    .bookBtnGridContainer {
      flex-grow: 1;
      width: calc(55% - 0.6rem);
      margin-top: 0;
    }

    .book-actions-container {
      flex-shrink: 1;
      // Fix a height overflow in the Safari.
      height: 100%;
      margin-top: 0.3rem;
      grid-gap: 0.3rem;

      &.actions-download {
        // Fix a height overflow in the Safari. 200% - because here is two grid rows.
        height: 200%;
        grid-template-columns: 1fr 1fr;
      }

      .button-base {
        $font-size: 0.7rem;
        overflow: hidden;
        font-size: $font-size;
        height: auto;
        min-height: 1.4rem;

        .button-link {
          font-size: $font-size;
          // Tree dots text overflow.
          overflow: hidden;
          display: block;
          height: $font-size;
          text-align: center;
          text-overflow: ellipsis;
          //
        }
      }
    }
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .bookDescriptionContainer {
      margin: 0;
    }
    .book-details .book-details-inner.line-up-in-one-column {
      margin: 0 0.5rem;
    }
  }
}
