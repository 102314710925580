.field-root {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  &.multiline {
    .field-container {
      padding: 18.5px 14px;
    }
  }

  &.with-label {
    &.active, &.focus {
      .field-fieldset legend span {
        padding: 0 5px;
      }

      .field-label {
        color: var(--primary);
        transform: translate(14px, -0.4rem) scale(0.75);
      }
      .field-fieldset {
        legend {
          max-width: 1000px;
          transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        }
      }
    }

    &.focus {
      .field-label {
        color: var(--primary);
      }
    }
  }

  &.focus {
    .field-fieldset {
      border-color: var(--border);
    }
  }

  &.error {
    .field-fieldset {
      border-color: #f44336;
    }
    .field-label {
      color: var(--text-inactive);
    }
  }

  &.hover {
    .field-fieldset {
      border-color: var(--primary);
    }
  }

  &.disabled {
    .field-label {
      opacity: 0.6;
      color: var(--border);
    }
  }

  // don't show input placeholder if we have a label
  .field-label[data-label=true] + .field-container .field-input::placeholder {
    opacity: 0;
  }
}

.field-container {
  font-family: "Roboto", sans-serif;
  line-height: 1.1875em;
  letter-spacing: 0.00938em;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  cursor: text;
  font-size: 1rem;
  align-items: center;
  position: relative;
  color: var(--text-default);
  border-radius: 2px;
}

.field-fieldset {
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  border-radius: inherit;
  pointer-events: none;
  outline: none;
  border: 1px solid var(--border);

  legend {
    width: auto;
    height: 11px;
    outline: none;
    display: block;
    padding: 0;
    pointer-events: none;
    font-size: 0.75em;
    max-width: 0.01px;
    text-align: left;
    transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    visibility: hidden;

    span {
      font-family: "Roboto", sans-serif;
      display: inline-block;
      padding: 0;
    }
  }
}

.field-label {
  font-family: "Roboto", sans-serif;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: none;
  color: var(--primary);
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
  transform: translate(14px, 12px) scale(1);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transform-origin: top left;
}

.field-input {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  margin: 0;
  display: block;
  background: none;
  border: none;
  resize: none;
  flex: 1 0 auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.7rem;
  color: var(--text-inactive);

  &::placeholder {
    color: var(--text-inactive);
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.field-helper {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  margin: 3px 0 0 0;
  text-align: left;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
