.book-row {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: width 0.5s;

  .action-buttons {
    display: flex;
    flex-wrap: wrap;
  }

  svg,
  img {
    height: 1.3rem;
    padding: 0.1rem;
    transition: all 0.25s;
    cursor: pointer;
    will-change: fill, height, width, padding;
  }

  svg {
    width: auto; // Reset fixed svg width (e.g. Book List "Like" icon).
  }

  img {
    object-fit: contain;
    vertical-align: middle;
  }

  .icon-active {
    fill: var(--icon-active);
  }

  svg:not(.book-row-button-subscribe).icon-disabled {
    fill: var(--icon-disable);
    pointer-events: none;
  }

  svg:not(.book-row-button-subscribe),
  img {
    fill: var(--icon-booklist-default);
  }

  svg:not(.book-row-button-subscribe) {
    &.book-play {
      fill: var(--gray);
    }
    &.book-play-disabled {
      fill: var(--icon-booklist-disabled);
      pointer-events: none;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .book-row {
    svg {
      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

.mode-mobile,
.mode-tablet {
  .book-row {
    img {
      height: 1.4rem;
    }

    svg {
      height: 1.1rem;
      margin: 0;
    }
  }
}
