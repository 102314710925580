@import "../shared/styles/variables.module";

:root {
  font-size: $root__font-size;
  line-height: $root__font-size;
}

body {
  margin: 0;
  font-family: "Bebas Neue", "Roboto", "Roboto Condensed", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  text-rendering: optimizeSpeed; */
  background-color: var(--background);
  color: var(--text-default);
  transition: background-color 0s;
  &.isDragging {
    overscroll-behavior-y: none;
    overflow: hidden;
    position: relative;
    touch-action: none;
    -webkit-touch-callout: none;
    user-select: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type=search],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  font-family: "Roboto Condensed", sans-serif;
}

ul {
  padding: 0;
  margin: 0;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

*:focus {
  outline: none;
}

.book-image-wrap,
.icon-button-base,
.button-base,
.button-link,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0); // [EGWW-2150] A trace remains on the screen after pressing a tile on Ipad 9.7 (IOS 14.1)
}

a {
  &:active, &:focus {
    outline:  none;
  }
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.toggle-button {
  position: relative;
  height: 0.8rem;
  width: 100%;

  .toggle-button__left, .toggle-button__right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0.6rem;
    height: 0.1rem;
    background-color: var(--outline-color);
    display: block;
    transition: transform 300ms;
  }

  .toggle-button__left {
    left: 50%;
    transform: rotate(25deg) translateX(-0.05rem);
  }

  .toggle-button__right {
    right: 50%;
    transform: rotate(-25deg) translateX(0.05rem);
  }

  &.is-active {
    .toggle-button__left {
      transform: rotate(-25deg) translateX(-0.05rem);
    }
    .toggle-button__right {
      transform: rotate(25deg) translateX(0.05rem);
    }
  }
}
