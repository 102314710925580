.mainAppContainer {
  .fade-in-appear,
  .fade-in-enter {
    opacity: 0;
  }

  .fade-in-appear-active,
  .fade-in-enter-active {
    opacity: 1;
    transition: all 0.3s ease-out;
  }

  .fade-in-exit,
  .fade-in-exit-active {
    display: none;
  }

  .swap-up-appear,
  .swap-up-enter {
    opacity: 0;
    transform: translateY(1rem);
  }

  .swap-down-appear,
  .swap-down-enter {
    opacity: 0;
    transform: translateY(-1rem);
  }

  .swap-up-appear-active,
  .swap-down-appear-active,
  .swap-up-enter-active,
  .swap-down-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-out;
  }

  .swap-up-exit,
  .swap-down-exit,
  .swap-up-exit-active,
  .swap-down-exit-active {
    display: none;
  }

  @keyframes blinker {
    50% {
      opacity: 0.25;
    }
  }
}
