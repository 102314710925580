$cart-li-padding: 1rem;

.cart-li {
  position: relative;
  display: flex;
  list-style: none;
  padding: $cart-li-padding 0;
  align-items: center;
  border-top: 1px solid var(--border);
}

.cart-li__details {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-left: 1rem;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.cart-li__image-container {
  width: 4.1rem;
  height: 4.8rem;
  padding-left: 1rem;
  align-self: flex-start;
}

.cart-li__image {
  width: 100%;
  height: 100%;
}

.cart-li__title {
  font-size: 1rem;
  color: var(--text-default);
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  letter-spacing: 0.05rem;
}

.cart-li__price {
  display: inline-flex;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  font-size: 1.3rem;
}

.cart-li__icon {
  margin-right: 0.4rem;
  margin-bottom: -0.1rem;
  color: var(--icon-color);
}

.cart-li__discount {
  font-family: "Roboto Condensed", sans-serif;
  color: var(--check-active);
  padding-bottom: 0.5rem;
}

.cart-li__set {
  font-family: "Roboto Condensed", sans-serif;
  color: var(--bc-text);
}

.cart-li__close-btn {
  position: absolute;
  top: $cart-li-padding;
  right: 0;
  align-items: initial;
  span {
    margin-bottom: -0.2rem;
  }
}

.mode-mobile {
  .cart-li__close-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1rem;
    span {
      display: none;
    }
  }
  .cart-li__title {
    width: 100%;
    // letter-spacing: 0.05em;
  }
  .cart-li__close-btn {
    width: 2rem;
    justify-content: flex-end;
  }
}
