@import "./src/styles/layers";

.small-player {
  position: absolute;
  z-index: $small-player-z-index;
  background: var(--background);
}

.small-player__wrapper {
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-gap: 0.7rem;
  align-items: center;
}

.small-player__btn-toggle-progress {
  grid-area: btn-toggle-progress;
}

// Smaller font size for the progress bar time labels.
.small-player__progress-controls {
  .progress-controls__time-wrapper {
    font-size: 0.9rem;
  }
}

.small-player__panel-actions {
  grid-area: panel-actions;
  display: flex;
  justify-content: flex-end;

  .icon-button-base {
    margin-left: 0.4rem;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

.book-toc-button {
  grid-area: book-toc-button;
  position: relative;
  background: none;
  overflow: hidden; // Text three dots cropping.
  height: 1.2rem;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--icon-color);
  cursor: pointer;

  .book-toc-button__text {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow-wrap: break-word;
    color: var(--icon-color);
  }

  &:hover {
    color: var(--icon-color);
  }

  &:active {
    color: var(--icon-active);

    .book-toc-button__text {
      color: var(--icon-active);
    }
  }

  &.open {
    color: var(--icon-active);

    .book-toc-button__text {
      color: var(--icon-active);
    }   
  }
 
}

.book-toc-dropdown {
  z-index: 1; // Be above the backdrop.
  box-sizing: border-box;
  background: var(--background);
  box-shadow: 0 0.2rem 0.7rem var(--shadow);
}

.toc-small {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.3rem;

  .book-toc-list-item__inner {
    font-size: 0.9rem;
  }
}

.small-player-expanded__split-wrapper {
  height: 100%;

  &.not-enough-split-place {
    .Resizer.vertical {
      display: none;
    }
    .Pane1 {
      width: 100% !important;
    }
  }

  &.not-enough-split-place.is-book-toc-visible {
    .Pane2 {
      z-index: 1;
      position: absolute !important;
      width: 100% !important;
      top: 0;
      bottom: 0;
    }
  }
}

.small-player-expanded__book-toc {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 2rem 0.9rem 0.9rem;
  background-color: var(--background);
}

.mode-tablet,
.mode-desktop {
  .small-player {
    top: 0;
    right: 0;
  }

  .small-player__wrapper {
    grid-template-areas: "book-toc-button player-controls panel-actions";
    grid-template-columns: auto auto auto;
    border-left: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    padding: 0.4rem 0.9rem 0.4rem 0.4rem;
  }

  $delimiter-border: 1px solid var(--border);
  $delimiter-gap: 0.5rem;
  // Left delimiter line.
  .small-player__play-controls {
    border-left: $delimiter-border;
    display: flex;
    .icon-button-base {
      padding-left: 0.3rem;
    }
  }
  .small-player__panel-actions {
    // Right delimiter line.
    .icon-button-base:first-of-type {
      border-right: $delimiter-border;
      padding-right: $delimiter-gap;
    }
  }

  .book-toc-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 3.1rem;
    height: 28rem;
  }
}

.mode-tablet,
.mode-mobile {
  .small-player {
    &.has-layout-expanded {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.mode-mobile {
  .small-player {
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;

    &.toc-open {
      flex-grow: 1;
      flex-shrink: 0;
      height: 100%;
    }

    .book-toc-button__text {
      /* Text three dots cropping. */
      overflow: hidden;
      text-overflow: ellipsis;
      /* ======= */
    }

    .dropDownMenuBack {
      display: none;
    }
  }

  .small-player__play-controls {
    transform: translateX(0.1rem); // Align play/pause button vertically parallel to the toggle TOC button.
  }

  .book-toc-dropdown {
    position: static;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow: 0 0.2rem 0.2rem var(--shadow);
  }

  .small-player__wrapper {
    $padding-side: 0.9rem;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas:
      "btn-toggle-progress btn-toggle-progress btn-toggle-progress"
      "book-toc-button player-controls panel-actions"
      "progress-controls progress-controls progress-controls";
    grid-gap: 0.5rem 0.1rem;
    padding: 0.4rem $padding-side;
    border-top: 1px solid var(--border);

    &.has-small-size-ui {
      .small-player__btn-full-screen {
        // Position full screen button at the left side.
        position: absolute;
        left: $padding-side;
      }
    }
  }

  .small-player__btn-close {
    justify-self: flex-end;
  }

  .toc-small {
    .bookInfoTitleContainer {
      display: flex;
      flex-direction: column;
      margin-top: 0.8rem;
    }

    .bookInfoContentTitle {
      font-size: 1.4rem;
      line-height: 1.4rem;
      margin-bottom: 0.4rem;
    }
  }
}

.mode-blind {
  .book-toc-dropdown {
    box-shadow: none;
    border: 1px solid var(--border);
  }

  .book-toc-button {
    &:active, &.active {
      outline: 2px solid var(--icon-background);
      background-color: var(--icon-background);
    }
  }
  .player-button__open-hide {
    &:active, &.active {
      outline: 2px solid var(--icon-background);
      background-color: var(--icon-background);
    }
  }
}
