@import "../../../src/shared/styles/mixins";

.search-result-header.featured-tab {
  .back-button {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-light);

    i {
      display: flex;
      font-size: 0.4rem;
    }
  }

  .featured-actions {
    display: flex;
    align-items: center;
    color: var(--icon-color);

    i.featured-refresh {
      width: auto;
      height: auto;
      font-size: 1rem;
    }
  }
}

.relevant-search-view {
  &.row-1 {
    .relevant-search-box.featured-section {
      max-width: 28.1rem;
    }
    .featured-search-column {
      grid-template-columns: 1fr;
    }
  }
  &.row-2 {
    .featured-search-column {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &.row-3, &.row-4 {
    .featured-search-column {
      grid-template-columns: repeat(3, 1fr);

      &.two-columns {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  &.row-5, &.row-6, &.row-7 {
    .featured-search-column {
      grid-template-columns: repeat(4, 1fr);
      &.two-columns {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &.row-8, &.row-9, &.row-10 {
    .featured-search-column {
      grid-template-columns: repeat(6, 1fr);
      &.two-columns {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.featured-section {
  margin: 0.5rem 0;

  h3{
    margin-left: 0.5rem;
  }  

  &.relevant-search-box {
    flex-wrap: wrap;

    .featured-section__more {
      margin-left: 0;
      position: absolute;
      bottom: 0;
      margin-bottom: 0.5rem;
    }
    .featured-search-column {
      width: 100%;
      display: grid;
      grid-gap: 0.8rem;
    }
    .featured-search-content {
      margin-bottom: 0;
      padding-bottom: 1.5rem;
      border: 1px solid transparent;
      border-bottom-color: var(--border);
      box-sizing: border-box;
      position: relative;
    }
    .featured-section__item {
      padding: 0.5rem 0;
      margin: 0;
      border-bottom: none;
      box-sizing: border-box;
    }
    .folder-item {
      display: flex;
      align-items: center;
      letter-spacing: 0.05rem;
    }
  }

  &:not(.relevant-search-box ) {
    .folder {
      margin-bottom: 0.4rem;
      .folder-item {
        padding: 0 0.75rem;
        letter-spacing: 0.05rem;
        font-family: "Bebas Neue", sans-serif;
        font-weight: bold;
        cursor: pointer;
        color: var(--primary-light);
      }
    }
  }
}

.featured-section__item {
  display: grid;
  grid-template-columns: minmax(1.6rem, max-content) minmax(min-content, 1fr);
  grid-gap: 0.5rem;
  padding: 0.5rem;
  margin: 0 0.8rem;
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;
}

.featured-section__title {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 0.2rem;
}

.featured-section__title-text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  color: var(--text-default);

  @include threeDots;
}

.featured-section__image {
  background-image: url("../../assets/placeholder/base.png");
  background-size: cover;
  position: relative;
  z-index: 2;
  max-width: 1.6rem;
  max-height: 2.4rem;
  flex: none;
  img {
    width: 100%;
    height: auto;
  }
  .text-over-image{
    font-size: 0.55em;
    @include lineClamp(2, 1.35);
  }
}

.featured-section__links {
  &.hidden {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }
}

.featured-section__link {
  display: inline-block;
  position: relative;
  padding-right: 0.3rem;
  margin-right: 0.3rem;
  
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: var(--border);
    right: 0;
    top: 0;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.featured-section__link-text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.8rem;
  line-height: 1.25;
  color: var(--text-default);
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s;
  word-break: break-all;

  &:hover {
    color: var(--text-link);
  }
}

.featured-section__more {
  font-family: "Roboto Condensed", sans-serif;
  cursor: pointer;
  color: var(--icon-hover);
  line-height: 1.2;
  display: inline-block;
  margin-left: 0.3rem;

  span {
    font-size: 0.8rem;
  }
}

.featured-directory {
  .list-wrap {
    display: flex;
  }
}

// moved from the "RightPanel.scss"
.search-plus-featured-wrapper {
  width: calc(100% - 1.2rem);
  color: var(--primary-light);
  letter-spacing: 0.02em;
  margin-bottom: auto;
  align-self: start;
  text-align: left;
  margin-top: 0.5rem;

  font-family: "Bebas Neue", sans-serif;
  font-size: 1rem;
  font-weight: bold;

  h3 {
    font-size: 1.2rem;
    color: var(--bc-text);
  }

  a {
    color: var(--primary-light);
    text-decoration: none;
  }

  .folder {
    width: 100%;
    margin-bottom: 0.25rem;

    .folder-item {
      svg {
        fill: var(--primary-light);
        margin-left: 0.25rem;
      }
      letter-spacing: 0.05rem;
      cursor: pointer;
    }

    .list-wrap {
      .item {
        color: var(--text-inactive);
        cursor: pointer;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 0.8rem;
        font-weight: normal;
        line-height: 1.8;

        a, button {
          color: var(--text-default);
        }

        &:hover {
          color: var(--text-link-hover);
        }

        &.passive {
          a {
            color: var(--bc-text);
          }

          color: var(--bc-text);
          font-weight: normal;
        }
      }
    }
  }
}
