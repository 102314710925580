$scrollbars-render-view-z-index: 0;

.scrollbars-wrapper,
.scrollbars-render-view {
  height: 100%;
}

.mode-mobile {
  .scrollbars-wrapper {
    flex-grow: 1;
  }
}

.scrollbars-render-view {
  z-index: $scrollbars-render-view-z-index;

  /* Another approach to hide native scrollbars. */
  // Compensate negative margins given by the plugin.
  margin-right: 0 !important;
  margin-bottom: 0 !important;

  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
  &::-webkit-scrollbar {
    display: none;
  }
  /* =========== */

  &.has-static-position {
    position: static !important;
  }

  &.gap-for-scrollbar {
    box-sizing: border-box;
    padding-right: 0.9rem;
  }
}

.scrollStyle {
  background: var(--icon-color);
  cursor: pointer;
  opacity: 0.4;
  border-radius: 5px;
}

.scrollMainTrack {
  z-index: $scrollbars-render-view-z-index + 1;
  height: 100%;
  width: 10px !important;
  right: 2px;
  top: 0;
  position: absolute;
}
