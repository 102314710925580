.book-details_label-supreme-info {
  font-family: Roboto Condensed, sans-serif;
  font-weight: bold;

  color: var(--secondary);
}

.mode-mobile {
  .book-details_label-supreme-info {
    font-weight: normal;
  }
}
