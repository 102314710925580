@import "../../shared/styles/functions";

$header-height: 5.5rem;
$header-tablet-height: 3.8rem;
$header-mobile-height: 3.3rem;

$header-padding-side: 0.9rem;

$header-children-gap-default: 1rem;
$header-children-gap-small: 0.8rem;

$header-icon-max-width-default: 1.3rem;
$header-icon-max-width-small: 1.2rem;
$header-icon-max-width-text-mode: 3.4rem;

$header-login-btn-max-width-default: 3.5rem;

$header-search-min-width-default: 8rem;
$header-search-min-width-mobile: 7rem;
$header-search-min-width-text-mode: 13rem;

/* These sizes are not set in the scss due to the logo DOM structure inconvenience. */
$header-logo-max-width-icon: 2.9rem;
$header-logo-max-width-big: $header-logo-max-width-icon + 8.22rem;
/* ====== */

:export {
  headerHeight: stripUnit($header-height);
  headerTabletHeight: stripUnit($header-tablet-height);
  headerMobileHeight: stripUnit($header-mobile-height);
  headerPaddingSide: stripUnit($header-padding-side);

  headerChildrenGapDefault: stripUnit($header-children-gap-default);
  headerChildrenGapSmall: stripUnit($header-children-gap-small);

  headerIconMaxWidthDefault: stripUnit($header-icon-max-width-default);
  headerIconMaxWidthSmall: stripUnit($header-icon-max-width-small);
  headerIconMaxWidthTextMode: stripUnit($header-icon-max-width-text-mode);

  headerLoginBtnMaxWidthDefault: stripUnit($header-login-btn-max-width-default);

  headerSearchMinWidthDefault: stripUnit($header-search-min-width-default);
  headerSearchMinWidthMobile: stripUnit($header-search-min-width-mobile);
  headerSearchMinWidthTextMode: stripUnit($header-search-min-width-text-mode);

  headerLogoMaxWidthBig: stripUnit($header-logo-max-width-big);
  headerLogoMaxWidthIcon: stripUnit($header-logo-max-width-icon);
}