body {
  &.only-old-ios {
    // TODO: Need another fix. Fixes button vertical align.
    .button-base,
    .sc-color-circle-item {
      display: inline-block;
      text-align: center;
    }
  }
}
