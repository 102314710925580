.badge-wrap {
  margin-left: 0.5rem;
  height: 1rem;
  border-radius: 1.1rem;
  border: 1px solid var(--primary-light);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .badge-text {
    font-family: "Bebas Neue";
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1;
    text-align: center;
    width: 100%;
    padding: 0 0.3rem;
    color: var(--primary-light);
    min-width: 0.5rem;
  }
}
