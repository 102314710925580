.text-mode-page {
  background-color: var(--background);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--text-mode-page-z-index);

  .header-page {
    position: relative;
    padding-right: 0.3rem;
  }

  .container {
    text-align: center;
    padding: 0 0.5rem;
    max-width: 28rem;
    height: 100%;
    margin: 0 auto;
    color: var(--text-default);

    p {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 1.25rem;
      line-height: 1.43rem;
      color: var(--text-inactive);
      margin: 0;
      padding: 0;
    }

    .logo-container {
      margin: 1.6rem 0;
    }

    .content-container {
      margin-top: auto;
      margin-bottom: 0.8rem;

      .title, .text-mode-button {
        margin-bottom: 1.2rem;
      }

      .title {
        font-family: "Bebas Neue", sans-serif;
        font-size: 2.25rem;
        line-height: 2.43rem;
      }

      .text-mode-button {
        margin-top: 1.3rem;
        transition: all 0.3s;
      }

      .btn-group {
        margin-top: 1.8rem;
        margin-bottom: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          display: inline-block;
          margin-bottom: 0.5rem;
          padding: 0.3rem;
          height: 3.3rem;
          border: 1px solid transparent;

          img {
            border: none;
          }
        }
      }
    }
  }
}

html[data-whatinput="keyboard"] {
  .text-mode-page {
    a {
      &:focus {
        text-decoration: underline;
        color: var(--text-link-hover);
      }
    }
    .btn-group {
      a {
        &:focus {
          background: var(--outline-background);
          border: 1px solid var(--outline-border);
          border-radius: 6px;
        }
      }
    }
  }
}

.mode-desktop {
  .text-mode-page {
    .container {
      .logo-container {
        margin-top: 5rem;
        margin-bottom: 3rem;
      }

      .content-container {
        .title, .text-mode-button {
          margin-bottom: 1.5rem;
        }

        .btn-group {
          flex-direction: row;

          a {
            margin-right: 1.1rem;
            margin-left: 1.1rem;
          }
        }
      }
    }
  }
}
