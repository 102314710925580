@import "../../styles/variables.module.scss";
@import "src/shared/styles/mixins";
$book-list-offset: 0.2rem;

.page_books {
  height: 100%;
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-rows: repeat(auto-fill, 1fr);
}

/* Fixes book item cropping by the container when the item is grown on either hover or focus or "i" button click. */
.bookList {
  box-sizing: border-box;

  .grid-wrap {
    // Prepare the space for the grown item.
    margin-left: -#{$book-list-offset};
  }

  .bookItemWrap {
    // Combined padding gap with margin gap to avoid the margin indents merging.
    padding-top: $book-list-offset;
    margin: 0 $book-list-offset $book-list-offset;
  }
}

.book-image-wrap {
  &.enlarged {
    .hover-wrapper {
      transform: scale(1.05); // Grow item.
    }
  }
}
/* ============ */

/* Fixes the book item cover is cropped by the containers when the only one column is represented. */
.bookList {
  .ReactVirtualized__Grid.grid-view,
  .ReactVirtualized__Grid__innerScrollContainer {
    width: 100% !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    max-width: 100% !important;
  }
}
/* ============ */

.book-list-grid-view-container {
  width: 100%;
}

.book-list-item {
  max-width: 100%;
}

.book-download-links {
  .btn-download {
    padding: 0 0.3rem;
    vertical-align: top;
  }
}

.bookItemWrap {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.bookshelf-active {
    .book-item-bookshelf {
      display: none;
    }
  }

  .book-image-wrap {
    width: $book-list-item__width__desktop;
    height: 9.15rem;
  }

  .book-item-bookshelf {
    width: $book-list-item__width__desktop;
    margin-top: 0.3rem;
    .bookItemCode {
      height: 1.3rem;
      line-height: 1.6rem;
      width: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: flex-end;
    }
  }

  .action-buttons {
    flex-wrap: nowrap; // prevent icons wrap when long ref code.
  }
}

.bookList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.bookItemText {
  font-family: "Roboto Condensed", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  font-style: normal;
  font-weight: normal;
  text-overflow: ellipsis;
}

.bookItemCode {
  text-transform: uppercase;
  color: var(--gray);
  font-size: 1rem;
  line-height: 1.1;
  flex-shrink: 0;
}

.bookItemSetLabel {
  color: var(--gray);
  font-size: 0.8rem;
}

.bookItemLabel {
  text-transform: capitalize;
  margin-right: 3px;
}

.bookItemTitle {
  font-size: 1.22rem;
  line-height: 1.3;
  color: var(--text-default);
  cursor: pointer;
  &:hover {
    color: var(--primary);
  }
}

.bookItemHorText {
  white-space: nowrap;
}

.bookItemWrapHor {
  position: relative;
  width: 100%;
  display: inline-flex;
  padding-right: 1.35rem;
  box-sizing: border-box;

  .book-image-wrap {
    width: 2.75rem;
    flex-shrink: 0;
  }

  .bookItemText {
    padding-top: 0.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .bookItemCode {
    max-width: 40%; // The rest 60% is for the icons of share, sub, like...
  }

  .book-item-image-wrap{
    width: 2.75rem;
    height: 4rem;
  }
}

.bookItemContentHor {
  overflow: hidden; // Prevent width go more than available when long refcode.
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  margin-left: 0.33rem;

  img {
    margin-left: 1rem;
    cursor: pointer;

    &:hover {
      background-color: var(--shadow);
    }
  }
}

.book-item-hor__head-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.1rem;
  justify-content: space-between;
}

.listRow {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 100%;
  display: inline-flex;
  justify-content: space-between;

  svg, .egw-font-icon {
    margin: 0 0.11rem;
  }
}

.book-row {
  width: 100%;

  &.with-static-download {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .actions-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
    }
  }
}

.label-horizontal {
  font-weight: bold;
  font-size: 0.9rem;
  padding: 6px 6px 2px;
  letter-spacing: 0.08em;
  color: var(--white);
  background-color: var(--primary);
}

/* Book download */
.bookDownloadPopup {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.33rem;
  background: var(--background);
  box-shadow: 0 4px 10px var(--shadow);

  .image-button {
    margin-right: 0.55rem;
    display: inline-flex;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.btn-download {
  padding-left: 2.78rem;
  padding-right: 2.78rem;
  margin-left: 0.55rem;
  min-width: 4.33rem;
  height: 1.2rem;
  white-space: nowrap;
  border-radius: 0.16rem;;
  font-weight: bold;
}

.bookDownloadIcon {
  $size: 1.1rem;
  height: $size;
  width: $size;
  min-width: $size;
  min-height: $size;
  position: relative;

  &:hover {
    fill: var(--primary);
  }
}

.char-wrap {
  position: relative;
  transition: all 0.25s;
  box-sizing: border-box;
  height: 1.2rem;
  flex-shrink: 0;
  justify-content: space-between;

  span {
    text-transform: uppercase;
    color: var(--gray);
    flex: 1;
    max-width: 8.83rem;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.2rem;
    max-height: 3rem;
  }
}

.char-wrap__inner {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.char-three-dots {
  position: relative;
}
.char-wrap-hidden {
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 4px 12px var(--shadow);
  right: 1.5rem;
  top: -0.4rem;
  position: absolute;
  z-index: 2;
  background: var(--popup-background);
  display: flex;

  span {
    display: block;
    padding: 0.3rem;
  }
}

.mode-blind {
  .char-wrap-hidden {
    box-shadow: none;
    outline: 1px solid var(--border);
  }
}

.char-selected {
  color: var(--primary) !important;
}

.selected-icon {
  fill: var(--secondary) !important;
}

.bookItemWrap .book-row .booklist-fill-icon,
.bookItemWrapHor .book-row .booklist-fill-icon {
  color: var(--icon-booklist-default);

  &.active {
    color: var(--icon-booklist-active);
  }
  &.disabled {
    color: var(--icon-booklist-disabled);
  }
}

@include canHover {
  .char-wrap {
    span {
      &:hover {
        color: var(--primary) !important;
      }
    }
  }

  .bookItemWrap .book-row .booklist-fill-icon,
  .bookItemWrapHor .book-row .booklist-fill-icon {
    &:hover {
      fill: var(--icon-booklist-hover);
      color: var(--icon-booklist-hover);;
    }
  }
}

.mode-mobile {
  .bookDownloadPopup {
    flex-wrap: nowrap;
  }

  .book-row.listRow {
    .bookItemCode {
      font-size: 0.9rem;
    }

    &.with-static-download {
      flex-direction: column;
      align-items: normal;

      .book-download-links {
        display: flex;
        align-items: center;
      }
    }
  }

  .bookList {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .bookItemContentHor {
    > .book-row .egw-font-icon {
      margin: 0.1rem;
    }
  }

  .bookItemWrap {
    .bookItemTitle {
      font-size: 1rem;
    }
  }

  .bookItemWrapHor {
    .bookItemText {
      font-size: 1rem;
    }
  }

  .book-list-item {
    .bookItemWrapHor {
      padding-right: 0;
    }
  }
}

.mode-tablet {
  .bookItemWrap {
    .bookItemTitle {
      font-size: 1.16rem;
    }
  }
}

.mode-mobile,
.mode-tablet {
  .bookList {
    flex-direction: row-reverse;
  }

  .bookItemWrap {
    .book-image-wrap {
      width: $book-list-item__width__mobile__tablet;
      height: 8.15rem;
    }

    .book-item-bookshelf {
      width: $book-list-item__width__mobile__tablet;

      .bookItemCode {
        width: 4rem;
      }
    }
  }

  .bookDownloadPopup {
    .image-button {
      margin-right: 0.33rem;
    }
  }

  .char-wrap {
    margin-left: 0;
    width: 1.2rem;
    height: unset; // Because height should not be set to stretch the element if parent's flex direction is a row type.

    span {
      text-align: center;
      letter-spacing: unset;
    }
  }

  .char-wrap__inner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .bookItemCode {
    font-size: 0.9rem;
  }
}

.mode-tablet,
.mode-desktop {
  .book-list-grid-view-container {
    height: 100%;
  }
}

.mode-desktop {
  .book-description-cover {
    &.is-shown-on-left-side,
    &.is-shown-on-right-side {
      .bookItemCode {
        // TODO: Use sass variable "$book-list-..." when will be merged.
        max-width: calc(100% - 6.5rem);
      }
    }
  }
  .bookItemSetLabel {
    margin-top: 1rem;
  }
  .bookItemSetLabel + .action-buttons {
    margin-top: 0.7rem;
  }

  .action-translate-buttons {
    display: flex;
    width: 80%;
    > div {
      margin-right: 25px;
    }
  }
}

.book-description-cover {
  &.is-shown-on-top:not(.is-shown-on-both-sides) {
    display: flex;
    flex-direction: column;
    .book-cover-part-right {
      order: -1;
      margin-bottom: 0.7rem;
      margin-top: 0;
      .actions-wrapper {
        order: -1;
        margin-bottom: 0.3rem;
        margin-top: 0;
      }
    }
  }
}

.book-list_btn-like {
  &:not(.text-only) {
    transform: scale(0.85);
  }
}

.mode-text {
  .bookItemWrap, .bookItemWrapHor {
    .book-row {
      font-family: "Roboto Condensed", Roboto, Helvetica, Arial, sans-serif;
      display: block;

      .booklist-fill-icon,
      .book-play {
        cursor: pointer;
        color: var(--text-link);
        font-size: 1rem;
        line-height: 1;
        display: inline-block;
        border-right: 0.06rem solid var(--gray);
        margin-right: 4px;
        padding-right: 4px;
        text-transform: capitalize;

        &:hover {
          color: var(--text-link-hover);
        }

        &:last-of-type {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }

      .bookItemCode {
        display: block;
        color: var(--text-default);
      }

      .disabled{
        color: var(--icon-booklist-disabled);
      }

      .book-play-disabled {
        font-size: 1rem;
        color: var(--icon-booklist-disabled);
        pointer-events: none;
      }
    }

    .is-shown-on-top {
      .book-row {
        display: flex;
      }
      &.is-shown-on-right-side {
        .book-row {
          .bookItemCode {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .book-item-bookshelf {
    .book-row {
      .booklist-fill-icon,
      .book-play {
        border-right: none;

        text-transform: capitalize;
        white-space: normal;
        overflow: visible;
      }
    }
  }

  .bookItemWrapHor {
    height: 100%;

    .bookItemCode {
      margin-bottom: 0.22rem;
      max-width: unset;
    }

    .action-buttons {
      margin-bottom: 0.3rem;
    }
  }

  .bookDownloadPopup {
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    text-transform: capitalize;
    box-shadow: none;
    color: var(--icon-color);
    border: 1px solid var(--border-sec);
    padding: 0.33rem 0.55rem;
    display: flex;
    flex-wrap: wrap;
    z-index: 5;
    &.with-buy-link {
      min-width: 13.33rem;
    }
    .image-button, .button-base {
      font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
      font-weight: normal;
      text-decoration: none;
      color: var(--icon-color);
      font-size: 0.88rem;
      &:last-child{
        &::after {
          content: "" !important;
        }
      }
      &:hover {
        color: var(--icon-active);
        cursor: pointer;

        &::after {
          color: var(--icon-color);
        }
      }

      &::after {
        content: "|";
        padding-left: 0.16rem;
        padding-right: 0.16rem;
      }
    }

    .image-button {
      text-transform: uppercase;
      display: inline-block;
      margin: 0;
      padding: 0;
      line-height: 1.5;
      font-size: 0.88rem;
      &:hover {
        color: var(--icon-active);
        cursor: pointer;

        &::after {
          color: var(--icon-color);
        }
      }

      &::after {
        content: "|";
        padding-left: 0.16rem;
        padding-right: 0.16rem;
      }
      &:last-of-type {
        margin-right: 0;
        padding-right: 0;
      }
    }

    .button-base {
      border-radius: 0;
      background: none;
      padding: 0;
      margin: 0;
      border: none;
      height: 100%;
      .button-link {
        line-height: 1.5;
        font-size: 0.88rem;
        height: 100%;
        &:hover {
          color: var(--icon-active);
          cursor: pointer;

          &::after {
            color: var(--icon-color);
          }
        }
      }
      &::after {
        content: "|";
        padding-left: 0.16rem;
        padding-right: 0.16rem;
      }

      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }

  .book-row.listRow.with-static-download {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: normal;

    .bookItemCode {
      min-width: 5.55rem;
    }

    .actions-wrapper {
      flex-direction: column;
      justify-content: normal;
      align-items: start;

      .book-download-links {
        margin-bottom: 0.28rem;
        flex-wrap: wrap;
        white-space: nowrap;

        .button-base {
          font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
          font-weight: normal;
          background: none;
          padding: 0;
          margin: 0;
          border: none;
          height: 100%;
          color: var(--text-link);

          &::after {
            content: "|";
            padding-left: 0.16rem;
            padding-right: 0.16rem;
          }

          &:last-child {
            &::after {
              content: "";
            }
          }

          .button-link {
            text-transform: capitalize;
            font-size: 0.88rem;

            &:hover {
              color: var(--text-link-hover);
              cursor: pointer;
            }
          }
        }

        .image-button {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 0.88rem;
          color: var(--text-link);

          &.active {
            color: var(--icon-booklist-active);
          }

          &.disabled {
            color: var(--icon-booklist-disabled);
          }

          &:hover {
            color: var(--text-link-hover);
            cursor: pointer;
          }

          span {
            &::after {
              color: var(--icon-color);
              content: "|";
              padding-left: 0.16rem;
              padding-right: 0.16rem;
            }
          }

          &:last-child{
            &::after {
              content: "";
            }
          }
        }
      }
    }
  }

  .bookItemWrap {
    & > .book-row {
      width: 100%;
      margin-top: 0.1rem;
    }

    .icon-info {
      font-size: 0.65rem;
      color: var(--footer-item);

      &:before {
        display: block;
        width: 0.56rem;
        height: 0.56rem;
        padding: 0.16rem;
        border-radius: 50%;
        border: 1px solid var(--footer-item);
        text-align: center;
      }
    }
  }

  .bookItemWrapHor {
    &.bookshelf-active {
      padding-bottom: 0;
    }
  }

  .btn-download {
    .button-link {
      // Remove specific font vertical align compensating.
      padding-top: 0;
    }
  }
}

.mode-text.mode-mobile {
  .book-row.listRow.with-static-download {
    flex-direction: column;
    align-items: normal;
  }
}

.mode-blind {
  .bookDownloadPopup {
    box-shadow: none;
  }
  .label-horizontal {
    background-color: var(--selection-background);
  }

  &.mode-text {
    .bookDownloadPopup {
      .image-button, .button-base, .button-link {
        &:hover {
          color: var(--icon-hover);
          cursor: pointer;

          &::after {
            color: var(--icon-color);
          }
        }
      }
    }
    .bookItemWrap,
    .bookItemWrapHor {
      .book-row {
        .booklist-fill-icon,
        .book-play {
          color: var(--secondary);
          background-color: inherit;
          outline: 2px solid transparent;

          &:hover {
            color: var(--text-link-hover);
          }
        }
        .disabled{
          color: var(--icon-booklist-disabled);
          &:hover {
            cursor: inherit;
            color: var(--icon-booklist-disabled); 
          }
        }
      }
    }
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .bookItemWrapHor {
      padding-right: 0;
      padding-top: 0;
      margin: 0;

      .bookItemText {
        display: block;
        margin-bottom: 0.2rem;
      }
      .book-image-wrap {
        display: none;
      }
    }
    .bookItemContentHor {
      display: block;
      margin: 0;
    }

    .book-row.listRow {
      display: block;
    }

    .bookItemWrap {
      .enlarged {
        .hover-wrapper {
          display: none;
        }

        .book-cover-part-left {
          padding-left: 0 !important;
        }
      }

      .book-download-links.with-buy-link {
        flex-wrap: wrap;

        .btn-download {
          margin-left: 0;
        }
      }

      .book-row.with-buy-link.listRow {
        margin-top: 0.2rem;
      }
    }
  }
}

