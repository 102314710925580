@use 'sass:math';
@import "variables.module.scss";

@mixin mobile-only {
  @include until-tablet {
    @content;
  }
}

@mixin until-mobile-lg {
  @media (max-width: #{$mobile-lg-width - 1}) {
    @content;
  }
}

@mixin from-mobile-lg {
  @media (min-width: #{$mobile-lg-width}) {
    @content;
  }
}

@mixin only-mobile-lg {
  @media (min-width: #{$mobile-lg-width}) and (max-width: #{$tablet-width - 1}) {
    @content;
  }
}

@mixin until-tablet {
  @media (max-width: #{$tablet-width - 1}) {
    @content;
  }
}

@mixin from-tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin until-desktop {
  @media (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

@mixin from-desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin canHover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin marginChildrenGapHorizontal($gap, $usePadding: false) {
  margin-left: -#{math.div($gap, 2)};
  margin-right: -#{math.div($gap, 2)};

  @if $usePadding {
    > * {
      padding-left: math.div($gap, 2);
      padding-right: math.div($gap, 2);
    }
  } @else {
    > * {
      margin-left: math.div($gap, 2);
      margin-right: math.div($gap, 2);
    }
  }
}

@mixin marginChildrenGap($gap) {
  margin: -#{math.div($gap, 2)};

  > * {
    margin: math.div($gap, 2);
  }
}

@mixin threeDots() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin lineClamp($line-count, $line-height) {
  @if ($line-height) {
    line-height: $line-height;
  }

  @supports (display: -webkit-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 3) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line-count;
    overflow: hidden;
  }

  @supports not ((display: -webkit-box) and (-webkit-box-orient: vertical) and (-webkit-line-clamp: 3)) {
    display: block;
    overflow: hidden;
    line-height: $line-height;
    max-height: $line-count * $line-height;
  }
}

@mixin removeBr {
  br {
    content: " ";
    &::before {
      content: "\00a0";
    }
  }
}

@mixin only-ios-safari {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin positionAbsoluteCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Fake font weight "bold" to not influence the content size.
@mixin fakeBold {
  text-shadow:
          0 0 .5px currentColor,
          0 0 .5px currentColor,
          0 0 .5px currentColor;
}
