@import "src/shared/styles/mixins";

.bookTocContentItem {
  display: flex;
  align-items: baseline;
  margin-top: 0.55rem;
  font-weight: normal;
  flex-direction: row;
  outline: none;
}

// TODO: Should be removed.
.book-toc-legacy {
  height: 100%;
  border: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  background: var(--background);
  .bookInfoTitleContainer {
    height: 1.1rem;
    line-height: 1.1rem;
    white-space: nowrap;
    margin-left: 0.9rem;
    font-size: 1.1rem;
    span:first-child {
      display: inline-block;
      position: relative;
      max-width: 72%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.book-toc-list-item__inner {
  display: flex;
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.2rem;
  text-decoration: none;

  &:hover,
  &:focus {
    cursor: pointer;  
    color: var(--text-link-hover); 

    span{
      text-decoration: underline;
    }
  }

  // Play icon must be shown when hover is triggered on the mobile.
  // https://rollncode.atlassian.net/browse/EGWW-2293?focusedCommentId=39088
  &:hover {
    .book-toc-list-item-icon-play {
      visibility: visible;
    }
  }
}

.book-toc-list-item__btn {
  cursor: pointer;
  overflow: hidden;

  .book-toc-list-item__text {
    @include lineClamp(2, 1.2rem);
  }

  &.is-current {
    color: var(--primary);
    text-decoration: underline;
    font-weight: bold;
  }
}


.book-toc-list-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text-inactive);

  &.no-audio-track {
    padding-left: 1.3rem;
  }

  &.active,
  &.is-child-playing {
    & > .book-toc-list-item__inner {
      .book-toc-list-item__btn {
        color: var(--text-link-hover);
        font-weight: bold;
      }
    }
  }

  &.active {
    & > .book-toc-list-item__inner {
      .book-toc-list-item-icon-play,
      .book-toc-list-item-icon-expand {
        visibility: visible;
        background-color: inherit;
      }
    }
  }

  &.is-child-playing {
    & > .book-toc-list-item__inner {
      .book-toc-list-item-icon-expand {
        fill: var(--icon-active);
        color: var(--text-link-hover);
      }
    }
  }

  &.is-expanded {
    color: var(--text-default);

    & > .book-toc-list-item__inner {
      .book-toc-list-item-icon-expand {
        fill: var(--icon-active);
        color: var(--icon-active);
      }

      .book-toc-list-item__btn {
        font-weight: bold;
      }
    }
  }

  .book-toc-list-item-icon-play {
    margin-right: 0.3rem;
  }

  .book-toc__list {
    padding-left: 1.3rem;
  }
}

.book-toc__list {
  list-style: none;
  padding-inline-start: 0;
  padding: 0;
}

.book-toc-list-item-icon-expand {
  flex-shrink: 0; // prevent element shrink when the title is too long
  cursor: pointer;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  color: var(--icon-color);
  text-decoration: none;

  &:before {
    transition: all 0.25s;
    display: inline-block;
  }

  &.is-expanded:before {
    transform: rotate(90deg);
  }
}

.book-toc-list-item-icon-play {
  cursor: pointer;
  min-width: 1rem;
  min-height: 1rem;
  align-self: flex-start;
  visibility: hidden;
  fill: var(--icon-color);
}

.book-toc-rtl {
  direction: rtl;
}

.mode-blind {
  .book-toc-list-item {
    &.is-expanded {
      & > .book-toc-list-item__inner {
        .book-toc-list-item-icon-expand {
          fill: var(--text-link-hover);
          color: var(--text-link-hover);
        }
      }
    }
  }
}
