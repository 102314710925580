/* The base CSS-flex logic of the application layouts. */

// Regular container with direction row.
@mixin containerDirectionRow() {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
}

// Regular container with direction column.
@mixin containerDirectionColumn() {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

// One of the flex parent children that possibly might have content that overflows boundaries (e.g. main content with right panel).
@mixin oneOfTheChildrenWithPossibleBigContent() {
  flex-grow: 0;
  flex-shrink: 1;
}

// One of the flex parent children that has more or less static content (e.g. left menu).
@mixin oneOfTheChildrenWithStaticContent() {
  flex-grow: 1;
  flex-shrink: 0;
}

html,
body { // Prevent ios scroll bouncing.
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.header-main {
  position: sticky;
  top: 0;
}

// for shopping cart
.mainPart {
  overflow: hidden;
  position: relative;
}

.mode-tablet,
.mode-desktop {
  #root,
  .main-application > .scrollbars-render-view,
  .dnd-wrap, // This block is a child, but there should be a scroll when footer visible.
  .mainPart {
    @include containerDirectionColumn();
  }

  .layout-wrap,
  .mainPart {
    @include containerDirectionColumn();
    @include oneOfTheChildrenWithPossibleBigContent();
  }

  .main-application,
  .mainWrapper {
    @include containerDirectionRow();
    @include oneOfTheChildrenWithPossibleBigContent();
  }

  .left-menu {
    @include oneOfTheChildrenWithStaticContent();
  }

  .swipeable-wrapper {
    @include containerDirectionColumn();
  }

  .resizerWrap {
    @include containerDirectionRow();
  }
}

.mode-tablet {
  .mainPart,
  .mainContent {
    overflow: hidden; // Prevents Reader width overflow when any panel is pinned, because of Resize Detector in ".reader-control-panel".
  }
}

.mode-mobile {
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .main-application {
    position: relative; // For the small player.
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%; // Is needed for IOS mobile.
  }

  .dnd-wrap {
    position: relative; // For ".scrollToWrapper" (scroll to top/bottom buttons).
  }

  .mainPart {
    @include containerDirectionColumn();
  }

  .dnd-wrap,
  .swipeable-wrapper,
  .resizerWrap,
  .mainWrapper {
    height: 100%;
  }

  .mainWrapper {
    display: flex;
  }

  .mainContent > .scrollbars-render-view > div {
    min-height: 100% !important; // Prevent footer showing above the bottom of the app.
  }

  /* Reader fix height equals 0. */
  .layout-wrap {
    display: flex;
    flex-direction: column;
  }

  .reader-panel-main,
  .reader-panel-scrollbar {
    flex-grow: 1;
  }
  /* =========== */
}
