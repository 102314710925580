@import "./src/styles/layers";

.topBar {
  z-index: $top-bar-z-index;
  position: relative;
  padding-bottom: 0.5rem;

  &.--empty {
    & > * {
      display: none;
    }
  }
}

.tp-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 
.topPanelWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tpRightMenu {
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.tpMenuIcon {
  margin: 0 0.2rem;
}

.tp-menu-reader-icon {
  height: 1.2rem;
  width: 1.2rem;
  margin-top: -2px;
}

.tpMenuWrap {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .add-language-button {
    text-transform: uppercase;
  }

  .add-language-margin {
    margin-right: 24px;
  }
}

.homeSearchIcon {
  min-width: 4.7rem;
  font-size: 1.2rem;
  transition: all 0.2s;
  margin-left: 1.3rem;
  cursor: pointer;
  text-transform: lowercase;
  font-family: "Roboto Condensed", sans-serif;
}

.homeSearchTopPanel {
  margin-right: 1.1rem;
}

.hsFirst {
  margin-bottom: -0.2rem;
  font-size: 1.3rem;
}

.hsLast {
  font-size: 0.9rem;
}

#listenIcon {
  margin-right: 1.4rem;
}

.mode-mobile,
.mode-tablet {
  .topBar {
    background-color: var(--background);
  }

  .topPanelWrap {
    flex-direction: row;
  }
}

.mode-tablet {
  .topBar {
    padding-bottom: 0.5rem;
  }
  .tp-main {
    margin-left: 0.3rem;
  }
}

.mode-mobile {
  .topBar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .topPanelWrap {
    padding: 0 0.5rem;
  }

  .topPanelWrap,
  .main-header {
    // Fix the content width overflow.
    min-width: 0;
  }
}

.mode-text {
  .panel-popup.showView {
    .tpMenuIcon:before {
       display: none;
    }
  }
  .tpMenuIcon {
    position: relative;
    display: inline-block;

    &:before {
      position: absolute;
      content: "|";
      left: -1rem;
      margin-left: 0.4rem;
      color: var(--gray);
    }

    &:first-child {
      &:before {
        content: "";
        margin-left: 0;
        padding-right: 0;
      }
    }
  }

  .tpMenuWrap {
    min-height: 21px;

    .text-only {
      overflow: visible;
      width: auto;
      min-width: auto;
      color: var(--gray);
      font-size: 1rem;
      line-height: 1rem;
      margin-left: 0.8rem;

      &.homeSearchIcon {
        font-family: "Bebas Neue", Roboto, "Roboto Condensed", sans-serif;
        font-size: 1.1rem;
        line-height: 1.1;
        font-weight: bold;
        margin-left: 24px;

        &::before {
          content: "";
          padding-left: 0;
          padding-right: 0;
        }
      }

      &.iconActive {
        color: var(--icon-active);
      }
    }
  }

  .panel-tools .panel-popup {
    display: block;
    min-width: 122px;
    padding: 12px 5px 12px 16px;

    .text-only {
      width: auto;
      min-width: auto;
      color: var(--text-default);
      display: block;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.1rem;
      margin-left: 0;
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.icon-button-active {
        color: var(--icon-active);
      }
      &.icon-button-disabled {
        color: var(--icon-disable);
      }
    }
  }
}
