.main-tree {
  .tree-item {
    &:hover {
      &.egwwritings {
        background: var(--highlighted-elem-egw) !important;
        color: var(--category-egw);
        .tree-link {
          color: var(--highlighted-elem-egw-text);
        }
      }
    }
  }
}
