@import "src/shared/styles/mixins";

.category-book {
  display: flex;
  flex-shrink: 0;
  height: 3.5rem;
  align-items: flex-end;
  cursor: pointer;
  hr {
    width: 100%;
    margin: 0;
    border-width: 0;
    height: 1px;
    color: var(--border);
    background-color: var(--border);
  }
}

.category-book__description {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding-left: 0.5rem;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  white-space: nowrap;
  min-width: 0;
}

.category-book__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}

.category-book__row {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;
}

.category-book__row.pub-name {
  flex: 1 1 17rem;
}
.category-book__row.pub-code {
  flex: 1 1 9.8rem;
}
.category-book__row.eng-pub-code {
  flex: 1 1 11.5rem;
}
.category-book__row.lang {
  flex: 1 1 13rem;
}
.category-book__row.pub-year {
  flex: 1 1 7rem;
}

.category-book__title {
  font-weight: 400;
  color: var(--text-inactive);
  margin-bottom: 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2rem;
}
.category-book__subtitle {
  color: var(--text-default);
  margin-bottom: 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.2rem;
}

.category-book__img {
  width: 2.5rem;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  margin-left: 0.2rem;
  img {
    width: 100%;
    height: 100%;
  }
  >div { 
    // a workaround for longpresswraper 
    // TODO remove when there is no longpresswrapper for desktop and tablet
    width: 100%;
    height: 100%;
  }
  .text-over-image {
    font-size: 0.5rem;
    @include lineClamp(4, 1.35);
  }
}

.mode-tablet,
.mode-mobile {
  .category-book {
    align-items: flex-start;
  }
  .category-book__description {
    height: 100%;
  }
  .category-book__title {
    display: none;
  }

  .category-book__row.pub-name {
    flex: 1 1 55%;
  }
  .category-book__row.pub-code {
    flex: 1 1 10%;
  }
  .category-book__row.eng-pub-code {
    flex: 1 1 10%;
  }
  .category-book__row.lang {
    flex: 1 1 10%;
  }
  .category-book__row.pub-year {
    flex: 1 1 5%;
  }
}

.mode-mobile {
  .category-book__row.eng-pub-code {
    .category-book__title {
      display: block;
      span {
        display: none;
      }
    }
  }
}

.mode-text {
  .category-book__img {
    display: none;
  }
}


