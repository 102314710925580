// TODO: Rewrote to sass variables and remove import in the "index.scss"
:root {
  --dropdown-menu-back-z-index:       1;
  --dialog-container-z-index:         1;
  --sc-top-panel-wrap-z-index:        4;
  --drag-overlay-z-index:             169;
  --drag-view-z-index:                170;
  --layout-wrap-content-z-index:      189; // Seems unnecessary at all.
  --cookies-notification-z-index:     199;

  --app-notification-z-index:         1000;
  --donate-notification-z-index:      1000;
  --popup-wrap-z-index:               2001;
  --dialog-background-z-index:        2001;
  --date-picker-container-z-index:    2002;
  --tooltip-z-index:                  2005;
  --toast-z-index:                    3000;
  --popup-container-z-index:          3001;
  --search-example-view-z-index:      9998;
  --unsupported-page-z-index:         9999;
  --text-mode-page-z-index:           9999;
}

$main-application-z-index:         1;
$header-main-z-index:              $main-application-z-index + 1;

$dnd-wrap-z-index:                 1;
$small-player-z-index:             $dnd-wrap-z-index  + 1;

$main-part-z-index:                1;
$right-panel-sliding-z-index:      $main-part-z-index + 9; // ONLY FOR THE SLIDING PANEL
$left-panel-sliding-z-index:       $main-part-z-index + 9; // ONLY FOR THE SLIDING PANEL
$cart-side-panel-z-index:          $right-panel-sliding-z-index + 1;

$main-wrapper-z-index:             1;
$top-bar-z-index:                  $main-wrapper-z-index + 4;
$relevant-search-view-z-index:     $top-bar-z-index + 1;
$base-drag-line-z-index:           $relevant-search-view-z-index + 1;
$left-menu-z-index:                $base-drag-line-z-index + 1;
$adv-search-z-index:               $left-menu-z-index + 1;

$book-list-item-cover__backdrop-z-index: 1;
$book-list-item-cover__desc-z-index:     $book-list-item-cover__backdrop-z-index + 1;
$book-list-item-cover__image-z-index:    $book-list-item-cover__desc-z-index + 1;

$resizer-phantom-wrap-z-index: 49;
