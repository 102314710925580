@import "./SubscriptionsDialog.variables.module.scss";

// Add subscription block common styles (dialog window and mobile individual page).
.add-sub-container {
  padding: 0 0.9rem 0.9rem;
  font-family: "Roboto Condensed", sans-serif;
  color: var(--text-inactive);
  overflow: hidden;
}

.add-sub-options-block {
  display: flex;
  flex-direction: column;

  &.delivery {
    padding-bottom: 0.4rem;
    border-bottom: 1px solid var(--border);
  }
}

.add-sub-options-block__delete {
  padding: 0 0.9rem;
}

.add-sub-option-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.6rem;
  white-space: nowrap;

  &.tablet {
    flex-direction: column;
    align-items: flex-start;
  }

  &.book {
    border-bottom: 1px solid var(--border);
    padding: 0.8rem 0;

    &:first-child {
      padding: 0 0 0.8rem;
    }
  }

  &.delivery {
    &:first-child {
      flex-direction: column;
    }

    &.weekdays {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.add-sub-option-label {
  height: $sub-option-height;
  &.can-click {
    cursor: pointer;
  }
  &.active {
    color: var(--bc-text-active);
    font-weight: bold;
  }
}

.add-sub-arrow-text-container {
  display: flex;
  max-width: 11rem;
  align-items: center;
  color: var(--dot-active);
}

.add-sub-option-text {
  height: 1.1rem;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  max-width: 11rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: var(--primary);
  }
}

.add-sub-block-title {
  display: inline-block;
  font-family: "Bebas Neue", sans-serif;
  color: var(--tab-inactive);
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1;
  letter-spacing: 0.05em;
  &.tab-title {
    cursor: pointer;
    margin-right: 1.7rem;

    &:hover {
      color: var(--tab-active);
    }
  }

  &.active {
    text-decoration: underline;
    color: var(--tab-active);
  }
}

.weekdays-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 0.5rem;

  .add-sub-week-day {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0.1rem;
    padding-top: 0.1rem;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: var(--sub-weekday);
    color: var(--sub-weekday);
    font-family: "Bebas Neue", serif;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .add-sub-week-day-selected {
    background-color: var(--sub-weekday-active-bg);
    color: var(--sub-weekday-active-color);
  }

  &.mobile {
    width: 100%;
    justify-content: space-between;
    margin-top: 0.7rem;
    margin-left: 0;

    .add-sub-week-day {
      width: 1.7rem;
      height: 1.7rem;
      margin-left: 0;
    }
  }

  &.tablet {
    .add-sub-week-day {
      margin-left: 0;
    }
  }

  &:not(.mobile):not(.tablet) {
    .add-sub-week-day:hover {
      border-color: var(--sub-weekday-hover);
      color: var(--sub-weekday-hover);
    }

    .add-sub-week-day-selected:hover {
      background-color: var(--sub-weekday-active-bg-hover);
      color: var(--sub-weekday-active-color-hover);
    }
  }
}

.add-sub-pages-container {
  width: 5rem;
  height: 1.3rem;
  display: flex;
  border-radius: 0.3rem;
  box-sizing: border-box;
  border: 1px solid var(--icon-color);

  & > span:first-child {
    border-right: 1px solid var(--icon-color);
  }

  & > span:last-child {
    border-left: 1px solid var(--icon-color);
  }
}
.add-sub-pages-btn,
.add-sub-pages-label {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  color: var(--text-default);

  svg {
    width: 0.7rem;
    height: 0.7rem;
  }
}

.add-sub-date-container {
  display: flex;
  align-items: center;

  &.tablet {
    width: 100%;
  }
}

.add-sub-date-value {
  display: inline-block;
  width: 5rem;
  height: 1.3rem;
  margin-right: 0.3rem;
  padding-top: 0.2rem;
  color: var(--text-default);
  box-sizing: border-box;
  border-bottom: 1px solid var(--icon-color);

  &.tablet {
    width: 90%;
    margin-top: 0.4rem;
  }
}

.add-sub-state-icon {
  margin-right: 0.3rem;
}

.add-sub-delivery-default {
  font-weight: bold;
}

.add-sub-delivery-default-icon {
  pointer-events: none;
  fill: var(--icon-disable);
}

.add-sub-delivery-checkbox {
  color: var(--icon-color);

  svg:hover {
    cursor: pointer;
  }
}

.add-sub-connect:hover {
  cursor: pointer;
  color: var(--bc-text-active);
}

.add-sub-checkbox-icon {
  fill: var(--icon-hover) !important;
  cursor: pointer;
}

.add-sub-delivery-icon {
  width: 0.7rem !important;
  height: 0.7rem !important;
  margin-right: 0.2rem;
}

.add-sub-text-disabled {
  color: var(--gray);
}

.add-sub-icon-disabled {
  cursor: default;
  pointer-events: none;
}

.add-sub-center-btn {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

// Add subscription block in dialog window
.dialog-container {
  .add-sub-container {
    // Add top indent, but remove side indents, that are used on mobile individual page.
    margin-top: $subs-dialog-content-indent-top;
    padding: 0;
  }

  .add-sub-container-content-wrap {
    // Add negative margin to compensate made indents between ".add-sub-options-block(s)".
    margin: 0 -#{$sub-options-block-indent-side} -#{$sub-options-block-indent-bottom};
    display: flex;
    flex-wrap: wrap;
  }

  .add-sub-options-block {
    flex-grow: 1;
    flex-shrink: 0;
    // Made indents between blocks.
    padding: 0 $sub-options-block-indent-side $sub-options-block-indent-bottom;

    &.delivery-methods {
      min-width: 10rem;
      max-width: calc(50% - #{$sub-options-block-indent-side * 2});
    }

    &.delivery-options,
    &.book-options {
      min-width: 16rem;
    }
  }
}

// Edit subscription block common styles (dialog window and mobile individual page).
.subs-edit-container {
  overflow: hidden auto;
  // To make indent for content to not cover the scroll thumb.
  padding-right: 0.5rem;

  .dialog-title {
    z-index: 1;
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0.3rem 0;
    background: var(--popup-background);
    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  .dialog-title svg:first-child {
    margin-left: 0.7rem;
  }

  .dialog-title svg:last-child {
    margin-left: 0.2rem;
  }

  .dialog-btn-wrap {
    position: sticky;
    bottom: 0;
    background: var(--popup-background);
    width: 100%;
    padding-top: 1.2rem;
  }

  .subs-edit-tabs-container,
  .add-sub-container {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0 $subs-edit-inner-child-indent-side;
    &:first-child {
      padding-left: 0;
    }
  }

  .add-sub-container {
    min-width: 31rem;
  }

  .subs-edit-tabs-container {
    display: flex;
    flex-direction: column;
    min-width: 16.5rem;
    margin-top: $subs-dialog-content-indent-top;
    box-sizing: border-box;
    border-right: 1px solid var(--border);
  }

  .add-sub-container-content-wrap {
    // Prevent multiple scrollbars. One more is on the "#subs-edit-container".
    overflow: hidden;
    max-height: initial;
  }

  .subs-edit-tabs {
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0.7rem;
  }

  .subs-edit-item {
    margin-top: 0.3rem;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1rem;
    line-height: 150%;
    color: var(--text-inactive);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:first-of-type {
      margin-top: 0;
    }

    &:hover {
      font-weight: bold;
      color: var(--primary);
    }
  }

  .subs-edit-item-selected {
    font-weight: bold;
    color: var(--primary);
  }

  .subs-edit-def-text {
    font-family: "Bebas Neue", serif;
    font-size: 1.1rem;
    letter-spacing: 0.08em;
    color: var(--icon-color);
  }

  #add-sub-no-item {
    min-height: 22rem;
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:not(.tablet) {
      min-width: 31rem;
    }
    &.tablet {
      min-height: 31rem;

      .dialog-btn-wrap {
        .dialog-btn {
          margin: 0 0.8rem;
        }
      }
    }

    .subs-edit-def-text {
      margin-left: 2.3rem;
    }
  }
}

.subs-edit-inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.subs-edit-scroll {
  min-height: 25vh;
}

.edit-sub-container {
  flex: 1;
  box-sizing: border-box;
  padding-bottom: 0.9rem;
}

.tab-wrap {
  &.subs-edit-tabs {
    .tab-default {
      font-size: 1rem;
      max-width: 5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.subs-edit-active {
  margin-top: 0.8rem;
  color: var(--text-default);

  &.mobile {
    margin-top: 0.7rem;
    margin-bottom: 0.4rem;

    svg {
      margin-right: 0.2rem;
    }
  }

  svg {
    margin-right: 0.8rem;
  }
}

// Edit subscription block in dialog window
.dialog-container {
  .edit-sub-container {
    padding-bottom: 0;
  }

  .subs-edit-container {
    max-height: 90vh;
  }
}
/*Edit subscription*/

/*Twitter dialog*/
.twitter-container {
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
  font-family: "Roboto Condensed", sans-serif;

  a {
    margin-top: 0.7rem;
    color: var(--bc-text-active);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.twitter-input {
  width: 100%;
}
/*Twitter dialog*/

.subs-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.subs-button-delete-sub {
  margin-top: 0.7rem;
  color: var(--bc-text-active);
  font-family: "Roboto Condensed", sans-serif;
}

@media (hover: hover) and (pointer: fine) {
  .add-sub-pages-btn {
    cursor: pointer;

    &:hover {
      color: var(--icon-hover);

      svg {
        fill: var(--icon-hover);
      }
    }
  }

  .subs-button-delete-sub {
    cursor: pointer;
  }
}

.mode-mobile,
.mode-tablet.mode-zoom-200 {
  // Add subscription block in dialog window
  .dialog-container {
    .subs-edit-container {
      max-height: initial;
    }

    .subs-edit-inner {
      // Reset negative margin that was used to compensate made side indents between ".add-sub-options-block(s)".
      margin: 0;
      // Line up blocks in the single column.
      flex-wrap: initial;
      flex-direction: column;
    }

    .subs-edit-tabs-container,
    .add-sub-container {
      // Stretch width and reset indents because lining up blocks in the single column.
      flex-grow: 1;
      flex-shrink: 0;
      padding: 0;
      // Reset fixed sizes.
      min-width: initial;
      max-width: initial;
    }

    .subs-edit-tabs-container {
      padding-bottom: 0.4rem;
      border-right: none;
      border-bottom: 1px solid var(--border);
    }
  }

  .add-sub-block-title {
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .add-sub-action-buttons-wrap {
    .button-base {
      margin: 0 0.3rem;
    }
  }

  .add-sub-option-container {
    color: var(--text-default);
  }
  &.mode-zoom-150 {
    .dialog-container {
      .delete-sub-container {
        .dialog-btn {
          min-width: 5rem;
        }
      }
    }
  }
}

.mode-mobile,
.mode-tablet {
  // Add subscription block in dialog window
  .dialog-container {
    .add-sub-container-content-wrap {
      // Reset negative margin that was used to compensate made side indents between ".add-sub-options-block(s)".
      margin: 0;
      // Line up blocks in the single column.
      flex-wrap: initial;
      flex-direction: column;
    }

    .add-sub-options-block {
      // Reset indents between blocks except the top one to keep vertical indents.
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .add-sub-options-block.delivery-methods,
    .add-sub-options-block.delivery-options,
    .add-sub-options-block.book-options {
      // Reset fixed sizes.
      min-width: initial;
      max-width: initial;
    }

    .subs-edit-scroll {
      .scrollbars-render-view {
        // Stretch to full width when is single row, not to be more than max-width when not.
        min-width: 100%;
        max-width: 15rem;
        // Prevent multiple scrollbars. The other one is on the React Scrollbar component.
        position: initial !important;
        overflow: hidden;
      }
    }
  }

  .add-sub-options-block {
    padding-top: 0.9rem;

    &:first-of-type {
      padding-top: 0;
    }
  }
  .subs-edit-inner {
    position: relative;
    padding-bottom: 4rem;

    .add-sub-action-buttons-wrap {
      position: absolute;
      bottom: 0;
      // diff for scroll wrapper
      padding: 0 0 17px 0;
    }
  }
}

.mode-tablet {
  //noinspection CssInvalidPropertyValue
  .dialog-container {
    .add-sub-container {
      display: flex;
      flex-direction: column;
      &.border-left {
        border-left: 1px solid var(--border);
        padding-left: 1rem;
        margin-left: 1rem;
        flex-grow: 1;
      }
    }
    .add-sub-container-content-wrap {
      &.add-mode {
        flex-wrap: nowrap;
        flex-direction: row;
      }

      &.zoom-mode {
        flex-wrap: wrap;

        .add-sub-container {
          width: 100%;
          padding-left: 0;
          margin-left: 0;
          border-left: 0;
        }
      }
    }

    .subs-edit-container {
      .add-sub-container {
        min-width: 14rem;
      }

      .add-sub-action-buttons-wrap {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .add-sub-action-buttons-wrap {
    padding-top: 1.3rem;
    // diff for scroll wrapper
    padding-bottom: 17px;
  }

  .subs-edit-container.zoom-mode {
    padding-right: 0;

    .subs-edit-inner {
      flex-direction: column;
      width: calc(100vw - 6rem);
    }

    .subs-edit-tabs-container {
      padding-bottom: 0.9rem !important;
      border-right: none;
      border-bottom: 1px solid var(--border);
    }

    .subs-edit-tabs-container,
    .add-sub-container {
      padding: 0;
    }
    .add-sub-date-value {
      width: 100%;
    }
  }
}

.mode-blind {
  .add-sub-block-title {
    color: var(--secondary);
  }
}
