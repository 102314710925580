.footerMain {
  background-color: var(--footer);
  color: var(--footer-item);
  position: relative;
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr minmax(6rem, 13.3rem);
  padding-left: 3rem;
  grid-row-gap: 1.3rem;
  grid-column-gap: 1rem;
}

.footerContent {
  width: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 1.3rem 1.3rem 1.3rem 3.3rem;
}

.footerLinksWrapper {
  display: flex;
  justify-content: space-between;
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: start;
  padding: 1.3rem 0;
}

.footerRow .view-mode-icons {
  border-top: 1px solid var(--footer-item);
  padding-top: 1.3rem;
}

.footerContentWrapper {
  min-height: 10rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.footerMain a {
  color: var(--footer-item);
  font-family: "Roboto Condensed", Roboto, Helvetica, Arial, sans-serif;
  text-decoration: none;
}

.footerIcon {
  fill: var(--footer-item);
  color: var(--footer-item);

  &:hover {
    fill: var(--footer-link-hover);
    color: var(--footer-link-hover);
  }
}

.footerCopyrightText .btn-more {
  color: var(--footer-item);
  text-transform: capitalize;
  display: inline-block;
}

.footerCopyrightText .btn-more:hover {
  color: var(--footer-item);
  text-decoration: underline;
}

.footerCopyrightText .btn-more .footerIconRightArrow {
  fill: var(--footer-item);
}

.footerCopyrightText .footer-build-time-link {
  margin-right: 0.3rem;
}

.footerIconRightArrow {
  cursor: pointer;
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
}

.footerADVIcon {
  fill: var(--primary);
}
.footerADVIcon:hover {
  fill: var(--primary);
}

.footerMain a:hover {
  color: var(--footer-link-hover);
  text-decoration: underline;
}

.footerIconsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  justify-self: start;
  padding-top: 1.3rem;

  .view-mode-icons-icon {
    margin-right: 1.2rem;
    svg {
      fill: var(--footer-inactive);
      &.selected {
        fill: var(--footer-item);
      }
      &:hover {
        fill: var(--footer-item);
      }
    }
  }
}

.footerLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1.3rem;
}

.footerIconsContainer {
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  a {
    margin-right: 1.2rem;
    display: inline-flex;
    width: 2.8rem;
    height: 2.8rem;

    .egw-font-icon {
      font-size: 2.7rem;
    }
    
    svg {
      width: 2.7rem;
      height: 2.7rem;
    }
  }

  span {
    cursor: pointer;
  }
}

.logoFooterContainer {
  display: flex;
  align-items: center;
  grid-column: 1 / 3;
  grid-row: 2;
  padding-bottom: 1.3rem;
}

.logoFooterIcon {
  fill: var(--footer-item);
  width: 3.9rem;
  height: 2.3rem;
}

.footerSmallLogo {
  margin-left: -0.3rem;
  font-size: 0.8rem;
}

.footerCopyright {
  display: flex;
  align-items: baseline;
  min-width: 3.9rem;
}

.footerCopyrightText {
  line-height: 130%;
  margin-left: 1.4rem;
}

.footerLinksContainer {
  margin-right: 1.3rem;
}

.footerLinksGrid {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1.9rem;

  & > a {
    margin-top: 1rem;
    white-space: nowrap;
    display: block;
  }
}

.footerLinksTitle {
  font-size: 1.3rem;
  line-height: 1.3rem;
  letter-spacing: 0.03em;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid var(--footer-item);
  margin-bottom: -0.4rem;
}

.adventistContainer {
  grid-column: 3;
  grid-row: 1 / span 2;
  display: flex;
  background-color: var(--footer-logo-bg);
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  align-items: center;

  svg {
    margin-bottom: 0.5rem;
  }
}

.adventistIconContainer {
  width: 8.2rem;
  margin: 0 auto;
  padding: 0.3rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  a {
    max-height: 5rem;
  }

  span {
    font-size: 1rem;
  }

  svg {
    width: 3.5rem;
    height: 3.5rem;
  }
}

.footerChevroneRight {
  display: inline-block;
  font-family: monospace;
  font-size: 1.44rem;
  cursor: pointer;
  transition: all 0.3s;
}

.footerChevroneRight.opened {
  transform: rotate(90deg);
}

.mode-text {
  .footerIconsContainer {
    margin-bottom: 0;
    text-transform: uppercase;
    flex-basis: 68%;

    a,
    span {
      width: auto;
    }

    a {
      margin-right: 1.5rem;
    }
  }

  .footerIconsWrapper {
    width: 100%;
    max-width: 35rem;
    margin-bottom: 1rem;
    align-items: baseline;
    .view-mode-icons-icon {
      line-height: 1;
      height: auto;

      span {
        height: 2.8rem;
        display: block;
      }
    }
  }

  .footerMain .view-mode-icons span {
    margin-bottom: 0;
    color: var(--footer-inactive);
    &.selected {
      color: var(--footer-item);
    }
  }

  &.mode-tablet {
    .footerLinksWrapper {
      margin-bottom: 0;
    }

    .footerIconsContainer {
      display: flex;
      flex-wrap: wrap;
      min-width: 15rem;
      margin-bottom: 0;

      &:first-child {
        margin-bottom: 0;
      }

      a {
        width: 33%;
        margin-right: 0;
      }
    }

    .footerIconsWrapper {
      margin-bottom: 0;
      width: auto;
      max-width: none;

      .view-mode-icons {
        display: flex;
      }

      .view-mode-icons-icon {
        width: auto;
        margin-right: 0;
      }
    }

    .adventistContainer a {
      height: 2.8rem;
    }
  }

  &.mode-mobile {
    .footerIconsWrapper {
      width: auto;
      max-width: none;
      margin-bottom: 1rem;
      .view-mode-icons {
        justify-content: flex-start;
      }
      .view-mode-icons-icon {
        margin-right: 2rem;
      }
    }

    .footerIconsContainer {
      justify-content: flex-start;
      flex-basis: auto;
      &:first-child {
        margin-bottom: 0;
      }
      a {
        width: 100%;
      }
    }
  }
}

.mode-tablet,
.mode-mobile {
  .adventistIconContainer {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    span {
      margin-left: 0.5rem;
      font-size: 0.9rem;
    }
    svg {
      width: 2.6rem;
      height: 2.6rem;
      margin-bottom: 0;
    }
  }

  .footerCopyrightText {
    margin-left: 0.5rem;
  }

  .footerContent {
    padding: 1.3rem;
  }

  .logoFooterContainer {
    margin-bottom: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.5rem;
  }
}

.mode-tablet {
  .footerMain {
    padding: 0;
    display: block;
  }

  .logoFooterContainer {
    padding-top: 1.3rem;
    padding-left: 1.3rem;
    margin-bottom: 0;
  }

  .footerContent {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 1.3rem;
  }

  .footerIconsWrapper {
    padding: 0;
    justify-content: space-between;
    flex-basis: 15rem;
    margin-right: 2rem;
  }

  .footerLinksWrapper {
    padding: 0;
    flex-wrap: wrap;
  }

  .footerLinksContainer {
    margin-bottom: 2rem;

    &:first-child {
      margin-right: 2.7rem;
    }
  }

  .footerIconsContainer,
  .footerIconsWrapper .view-mode-icons {
    justify-content: space-between;
    width: 100%;
  }

  .footerIconsWrapper .view-mode-icons-icon,
  .footerIconsContainer a {
    margin-right: 0;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  .footerIconsContainer {
    &:first-child {
      margin-bottom: 2.3rem;
    }

    &:nth-child(2) {
      margin-bottom: 1.3rem;
    }
  }

  .adventistContainer {
    padding: 0.6rem;
  }

  .footerLinksGrid {
    display: block;
  }
}

.mode-mobile {
  .footerMain {
    z-index: 1; // Fix "Footer" and "Add subscriptions" layers bugs on the Mac OS Safari in the mobile view mode.
    padding: 0;
    display: block;
  }

  .footerContent {
    display: block;
    padding: 0 1.3rem;
  }

  .logoFooterContainer {
    padding-top: 1.3rem;
    margin-bottom: 0;
  }

  .footerIconsContainer {
    a {
      margin-right: 0;
    }

    &:first-child {
      margin-bottom: 2.3rem;
    }

    &:nth-child(2) {
      margin-bottom: 1.3rem;
    }
  }

  .adventistContainer {
    padding: 0.6rem;
  }

  .footerLinksGrid {
    display: block;
  }

  .footerIconsContainer,
  .footerIconsWrapper .view-mode-icons {
    width: 100%;
    justify-content: space-around;
  }

  .footerIconsWrapper {
    padding-top: 0;

    .view-mode-icons-icon {
      margin-right: 0;
    }
  }

  .footerLinksWrapper {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .footerLinksContainer {
    margin: 0 0 1.3rem 0;
    flex-basis: 10rem;

    &:first-child {
      margin-right: 1.3rem;
    }
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .logoFooterContainer {
      margin-top: 0;
      flex-wrap: wrap;
    }
    .footerCopyright {
      margin: 0 auto 0.5rem;
    }
    .footerCopyrightText {
      margin: 0;
    }
  }
  &.mode-desktop {
    .footerMain.withWrap {
      .footerLinksWrapper {
        padding-right: 0;
      }
    }
  }
}

.mode-zoom-150,
.mode-zoom-200 {
  &.mode-tablet:not(.mode-text) {
    .footerContent {
      flex-wrap: wrap;
    }
    .footerIconsWrapper {
      flex-basis: auto;
      margin-bottom: 2rem;
    }
  }
}

.mode-desktop {
  .footerMain {
    &.withWrap {
      grid-column-gap: 0.5rem;
      grid-template-columns: 1fr 1fr;
      padding-left: 0;

      .adventistContainer {
        grid-row: 3;
        grid-column: 1 / span 2;
        padding: 0.5rem 0;
      }

      .logoFooterContainer {
        grid-row: 2;
        padding-bottom: 0;
      }

      .footerLinksWrapper {
        justify-self: end;
        padding-bottom: 0;
        padding-right: 1rem;
      }

      .footerLinksGrid {
        display: block;
      }

      .footerIconsWrapper,
      .logoFooterContainer {
        padding-left: 3rem;
      }
    }
    &.withZoom {
      .adventistContainer {
        grid-row: 4;
      }
      .logoFooterContainer {
        grid-row: 3;
      }
    }
  }
}

.mode-zoom-150,
.mode-zoom-200 {
  &.mode-text {
    &.mode-desktop {
      .footerMain.withWrap {
        .footerIconsWrapper,
        .logoFooterContainer {
          padding-left: 1rem;
        }
        .footerIconsContainer {
          a:last-child {
            margin-right: 0;
          }
        }
        .footerLinksContainer {
          margin-right: 0.7rem;
          &:last-child {
            margin-right: 0;
          }
        }
        .footerLinksWrapper {
          a {
            font-size: 0.9rem;
          }
        }
      }
    }
    &.mode-tablet {
      .footerContent {
        flex-wrap: wrap;
      }
    }
  }
}
