@import "src/shared/styles/mixins";

.search-for-books {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  // Prevent "Search list" would cover "Tree",
  // because "Search list" has fixed height, based on the "zoom" and "items count".
  max-height: 80%;
  font-family: "Roboto Condensed", sans-serif;

  &.has-height-full {
    height: 100%;
    max-height: 100%;
  }

  .horizontal-data-row {
    border-bottom: 1px solid var(--border);
  }

  .panel {
    padding: 0 0.5rem;

    &.inactive {
      background: var(--tree-row-even);

      .panel-child {
        cursor: auto;
        border-bottom: 0 solid var(--tree-row-even);
        font-weight: normal;
        color: var(--icon-disable);
        pointer-events: none;

        &:hover {
          color: var(--icon-disable);
        } 

        .counter {
          display: none;
        }
      }
    }

    .panel-child {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.5em;
      box-sizing: border-box;
      color: var(--primary);
      white-space: nowrap;
      font-weight: bold;
      font-size: 0.75em;

      .with-chevron::after{
        font-size: 0.8em;
      }

      &:hover {
        cursor: pointer;
        color: var(--primary-light);
      }

      .title {
        white-space: nowrap;
        flex: 1;
        overflow: hidden;
      }

      .counter {
        flex: 0 1.5em;
        font-family: "Roboto Condensed", sans-serif;
        float: right;
        line-height: 1em;
        padding: 0.2em;
        border-radius: 1em;
        color: var(--background);
        background: var(--primary-light);
        text-align: center;
      }
    }
  }

  .panel-bottom {
    padding: 0;
    
    .with-chevron-less-more::after{
      font-size: 1em;
    }

    .panel-bottom-child {
      height: 2em;
      font-weight: normal;
      font-size: 0.8em;
      text-align: center;
      color: var(--text-link);
      border-top: 1px solid var(--border);
      border-bottom: 1px solid var(--border);
      display: flex;
      justify-content: center;
      align-items: center;

   
      &:hover {
        cursor: pointer;
        color: var(--text-link-hover);
      }
    }
  }
}

.search-for-books_book-list {
  .item {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    height: 6em;
    padding: 0.5em;
    border-bottom: 1px solid var(--border);

    font-family: "Roboto Condensed", sans-serif;

    &:hover {
      cursor: pointer;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    .text-holder {
      box-sizing: border-box;
      overflow: hidden;
      height: 100%;
      padding-left: 0.5em;
      line-height: 1;
    }

    .title {
      font-weight: bold;
    }
  }
}

.search-for-books_cover {
  flex-shrink: 0;
  width: 3.3em;
  height: 5em;

  .text-over-image {
    font-size: 0.7em;
    @include lineClamp(4, 1.35);
  }
}

.mode-blind {
  .search-for-books {
    .item {
      &:hover {
        &.egwwritings, &.reference, &.bible, &.apl {
          background: var(--highlighted-elem-egw);
          color: var(--highlighted-category-egw);
        }
      }
    }
   }
}
