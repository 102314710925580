@import "../../styles/variables.module.scss";

.panel-tools {
  margin-left: auto;
  display: inline-flex;
  align-items: center;

  &+.panel-tools {
    margin-left: 0;
  }
 
  .panel-popup {
    top: 100%;
    left: unset;
    right: 4px;
    box-shadow: 0 4px 12px var(--shadow);
    flex-wrap: wrap;
    max-width: 19rem;

    .rc-tools-icons {
      display: flex;
      flex-wrap: wrap;
      width: 250px;
      margin: auto;
    }
  }

  .panel-tools-icon {
    display: inline-block;

    svg {
      margin: 0;
      width: 1.1rem;
      height: 1.1rem;
      max-width: 1.1rem;
      max-height: 1.1rem;
    }
  }

  .panel-tools-icon {
    margin-right: 0.9rem;
  }
}

.mode-zoom-400 {
  .panel-popup svg {
    margin: 0.3rem 0.4rem;
  }
  .panel-popup {
    .reader-translation-icon {
      margin: 0.3rem 0.4rem !important;
    }
  }
}

.mode-text {
  .panel-popup.reader-control-panel-popup {
    .icon-button-base, .icon-button-base.text-only {
      width: auto;
      min-width: auto;
      flex-basis: auto;
      overflow: visible;
    }
    .font-list-container {
      width: auto;
    }
  }
}

.mode-mobile {
  .panel-tools {
    .panel-popup.reader-control-panel-popup {
        .icon-button-base {
          width: auto;
          min-width: auto;
        }
    }
  }
  &.mode-zoom-150 {
    .panel-tools {
      .panel-popup.reader-control-panel-popup {
        .np-icon-wrap {
          width: 100%;
          svg {
            margin: 0.4rem 0;
            flex-basis: auto;
          }
        }
        .font-list-container {
          flex-basis: 20%;
          svg {
            flex-basis: 100%;
          }
        }
        svg {
          margin: 0.4rem 0;
          flex-basis: 20%;
        }
      }
    }
  }

  &.mode-text {
    .panel-tools {
      .panel-popup {
        .font-list-container {
          display: block;
        }
      }
    }

    .reader-control-panel {
      .reader-control-panel-popup {
        border: 1px solid var(--border-sec);
        top: $top-bar-mobile-height;
      }
    }
  }
}

@media (max-width: 375px) {
  .mode-mobile {
    .panel-tools {
      .panel-popup.reader-control-panel-popup {
        max-width: 100%;
        right: 1px;
        left: 1px;
      }
    }
  }
}
