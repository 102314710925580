html[data-whatinput="keyboard"] {
  .categoryListItem:focus {
      @include focusOutline;
      background-color: var(--tiles-bg-hover);
  }

  .tab-wrap {
    .tab-default {
      &:focus {
        color: var(--text-link-hover);
        text-decoration: underline dotted;
      }
    }
  }

  header {
    .logo-container {
      a {
        &:focus {
          @include focusOutline;
        }
      }
    }

    .header-egw-old-container {
      &:focus {
        svg {
          @include focusOutlineWithBackground;
        }
      }
    }

    .headerProfileContainer {
      &:focus {
        @include focusOutline;
      }
    }

    .login-header-menu  {
      &:focus {
        @include focusOutline;
      }
      .dropDownText, .dropDownText a {
        &:focus {
          text-decoration: underline;
          color: var(--text-link-hover)
        }
      }
    }
  }

  footer {
    .footerCopyrightText,
    .footerLinksWrapper {
      a {
        &:focus {
          color: var(--footer-link-hover);
          text-decoration: underline;
        }
      }
    }

    .footerIconsContainer,
    .view-mode-icons {
      a, .view-mode-icons-icon {
        &:focus {
          @include focusOutlineWithBackground(1px, solid, var(--outline-footer-border), var(--outline-footer-background));
        }
      }
    }

    .adventistIconContainer {
      &:focus {
        @include focusOutline;
      }
    }
  }

  .book-details {
    .bookDescriptionContainer {
      a {
        &:focus {
          color: var(--text-link-hover);
          text-decoration: underline;
        }
      }

      svg {
        &:focus {
          @include focusOutline;
        }
      }
    }
  }

  .sideMenuItem {
    &:focus {
      position: absolute;
      z-index: 10;
      background-color: var(--outline-background);
      border-bottom: none;
    }

    &.sideMenuSelected {
      &:focus {
        z-index: 20;
      }
    }
  }

  .menuItemWrap.item-more {
    &:focus {
      @include focusOutline;
      z-index: 20;
    }
  }

  .icon-button-base,
  .search-icon {
    &:focus {
      @include focusOutlineWithBackground;
    }
  }

  .text-button {
    &:focus {
      @include focusOutline;
    }
  }

  .hide-icon-container {
    &:focus {
      @include focusOutline;
    }
  }

  .egw-slider-thumb,
  .MuiSlider-thumb {
    &:focus {
      @include focusOutlineWithBackground;
    }
  }

  .bc-wrap {
    .dropdown-menu-area p, .breadcrumb-item {
      &:focus {
        outline: none;
        color: var(--bc-text-active);
        text-decoration: underline;
      }
    }
  }

  .bookItemWrap {
    .book-image-wrap {
      &:focus {
        .book-item-image-img  {
          padding: 2px;
          @include focusOutline;
        }
      }
    }
  }

  .temporary-focused {
    animation: blinker 0.6s ease-in-out;
  }

  button {
    &.button-base {
      &:focus, &:focus-within {
        outline: none;
        border: 2px solid var(--outline-border);
        background-color: var(--outline-background);
        color: var(--outline-color);

        a {
          text-decoration: none;
        }
      }
    }
    &.radio-item,
    &.checkbox-item {
      &:focus, &:focus-within {
        color: var(--text-link-hover);
        text-decoration: underline;
      }
    }
    &.tree-item {
      &:focus {
        background: var(--outline-background);
        text-decoration: underline;
      }
    }
  }

  .checkbox-item,
  .radio-item {
    input {
      &:focus {
        ~ .controlLabel {
          color: var(--text-link-hover);
          text-decoration: underline;
        }
      }
    }
  }

  .checkbox-item {
    &.lang-check-icon {
      &:focus-within {
        svg {
          @include focusOutlineWithBackground;
        }
      }
    }
  }

  .settingsPage {
    .settingsClickable,
    .settingsItemButton,
    .settingsToggleItem {
      &:focus, &:focus-within {
        text-decoration: underline;
        color: var(--text-link);
      }
    }

    .settingsItem {
      &.settingsToggleItem {
        &:focus {
          .settingsItemInactive {
            color: var(--text-link);
          }
        }
      }

      .add-language {
        &:focus {
          text-decoration: underline;
          color: var(--text-link);

          svg {
            fill: var(--text-link);
          }
        }
      }
    }
  }

  .theme-menu-button {
    &:focus {
      svg {
        @include focusOutline;
      }
    }
  }

  .right-panel-content {
    .search-plus-featured-wrapper,
    .search-plus-list {
      button, a {
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .share-popup-container,
  .common-popup {
    a {
      &:focus {
        svg {
          @include focusOutline;
        }
      }
    }
  }

  .share-popup-container {
    svg {
      &:focus {
        @include focusOutline;
      }
    }
  }

  .menuTreeWrap {
    .search-for-books {
      .search-for-books-header {
        &:focus {
          background: var(--outline-background);

          .title {
            text-decoration: underline;
          }
        }
      }
    }

    .book-list {
      .item {
        &:focus {
          color: var(--category-egw);
          fill: var(--category-egw);
          background: var(--highlighted-elem-egw);

          &.apl {
            background: var(--highlighted-elem-apl);
            color: var(--category-apl);
            fill: var(--category-apl);
          }
        }
      }
    }
  }

  .scm-action-menu-row,
  .rcm-wrap {
    .rcm-icon {
      &:focus {
        @include focusOutlineWithBackground;
      }
    }
  }

  .sc-color-item {
    &:focus {
      @include focusOutlineWithBackground;
    }
  }

  .dd-option-container {
    &:focus {
      text-decoration: underline;
    }
  }
  .date-picker-content {
    .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--outside-month) {
      &:focus {
        @include focusOutlineWithBackground;
      }
    }
  }

  .sc-table-wrap {
    .sc-table-row {
      &:focus {
        @include focusOutlineWithBackground;
      }

      .checkbox-item.focused .checkbox-icon {
        @include focusOutlineWithBackground;
      }
    }
  }

  .search-item-wrap, dictionary-item-wrap {
    &:focus {
      &.egwwritings, &.external, &.reference, &.bible, &.apl {
        border-color: var(--outline-border);
      }

      &.egwwritings, &.external {
        background: var(--highlighted-elem-egw);
        color: var(--category-egw);
      }
      &.reference {
        background: var(--highlighted-elem-reference);
      }
      &.bible {
        background: var(--highlighted-elem-bible);
      }
      &.apl {
        background: var(--highlighted-elem-apl);
      }
    }
  }

  .search-history-item:focus {
    border: 1px solid var(--outline-border);
    .search-history-item-inner {
      border: none;
    }
  }

  .mode-text {
    .text-category-item {
      &:focus {
        @include focusOutline;
        background-color: var(--tiles-bg-hover);
      }
    }
  }

  .rcm-hl-container:focus {
    outline: 1px solid var(--outline-border);
    background: var(--outline-background);
  }

  .select-container {
    &:focus {
      .pub-select-container-base {
        border: 1px solid var(--outline-border);
        background: var(--outline-background);
      }
    }
  }

  .lang-sel-item {
    &:focus {
      @include focusOutlineWithBackground;
      outline-offset: -1px;
    }
  }

  .mode-blind {
    .icon-button-base,
    .search-icon {
      &:focus {
        fill: var(--icon-background);
      }
    }
    .lang-sel-item {
      &:focus {
        color: var(--icon-background);
      }
    }
  }
}
