@import "Header.layers";
@import "../../styles/variables.module.scss";

.theme-menu-wrap {
  position: relative;
  display: inline-flex;
  align-items: center;

  &.active {
    .themeIcon,
    .icon-button-base.themeIcon {
      fill: var(--icon-hover);
    }
  }
}

.theme-menu-popup {
  max-height: $theme-popup-height;
}

.themeIcon {
  height: 1.2rem;
  width: 1.2rem;
}

.mode-blind {
  .theme-menu-wrap {
    &:hover,
    &.active {
      .themeIcon {
        fill: var(--icon-active);
        color: var(--icon-active);
        background-color: var(--icon-background);
      }
    }
  }
  &.mode-text {
    .theme-menu-wrap {
      &:hover,
      &.active {
        .themeIcon {
          color: var(--icon-background);
          background-color: var(--icon-active);
        }
      }
    }
  }
}

.mode-mobile {
  .theme-menu-wrap {
    height: 1.8rem; // To make the top indent for the theme popup.
  }
}

.mode-tablet {
  .theme-menu-wrap {
    height: 2.3rem; // To make the top indent for the theme popup.
  }
}

.mode-desktop {
  .theme-menu-wrap {
    height: 2.75rem; // To make the top indent for the theme popup.
  }
}
