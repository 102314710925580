@mixin applyLangSelItemColorOnHoverAndWhenHighlighted ($color) {
  .lang-sel_item:not(.is-disabled) {
    &:hover {
      color: $color;
    }

    &.is-highlighted {
      color: $color;
      font-weight: bold;
    }
  }
}

.lang-sel-container {
  width: 13.1rem;
  background: var(--popup-background);
  box-sizing: border-box;
  padding: 0.5rem;

  .checkbox-item {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    max-width: 1.3rem;
  }

  .lang-sel-title {
    font-size: 1.1rem;
    line-height: 1.6rem;
    letter-spacing: 0.05em;
    color: var(--primary-light);
  }

  .lang-sel-search {
    height: 1.2rem;
  }

  &.is-disabled {
    .lang-sel_scroll {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.lang-sel_item {
  display: flex;
  align-items: center;
  flex-shrink: 0; // Prevent IOS item shrink.

  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.8rem;

  cursor: pointer;

  &.is-disabled {
    pointer-events: none;
    color: var(--bc-text);

    .lang-sel-check-icon {
      fill: var(--bc-text);
    }
  }

  &.is-default,
  &.is-highlighted {
    font-weight: bold;
  }

  .lang-sel-check-icon {
    min-width: 0.8rem;
    min-height: 0.8rem;
    height: 0.8rem;
    fill: var(--tiles-text);
    margin-right: 0.3rem;
  }
}

@include applyLangSelItemColorOnHoverAndWhenHighlighted(var(--icon-active));

.lang-sel-img {
  width: 0.9rem;
  min-width: 0.9rem;
  min-height: 0.5rem;
  height: 0.5rem;
  margin-right: 0.3rem;
}

.lang-sel-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lang-sel-count {
  margin-right: 0.7rem;
  margin-left: 0.2rem;
}

html[data-whatinput="keyboard"] {
  .lang-sel-container {
    .lang-sel-item {
      &:focus-within {
        color: var(--text-link-hover);
        text-decoration: underline;
      }
    }
  }
}

.mode-blind {
  @include applyLangSelItemColorOnHoverAndWhenHighlighted(var(--icon-background));
}

.mode-zoom-150 {
  &.mode-mobile {
    .lang-sel-container {
      max-width: 10rem;
    }
  }
}
