.accordion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.toggling-disabled {
    .accordion_head {
      pointer-events: none;
    }
  }
}

.accordion_body {
  height: 100%;
  padding-bottom: 0.5rem;
}

.accordion_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.45rem;

  cursor: pointer;
}
