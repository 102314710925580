@use 'sass:math';
@import "./src/styles/layers";
@import "./src/components/header/Header.variables.module.scss";
@import "./src/components/header/Header.layers";

$header-search-input-height: 2.2rem;

.search-wrap-base-header {
  position: relative;
  z-index: 100;
}

.header-search-wrap {
  height: $header-search-input-height;
  min-width: $header-search-min-width-default;
  position: relative;
  flex: 1;
  // flex-grow: 1; // Prevent FF shrink.
  box-sizing: border-box;
  border-radius: 30px;
  transition: all 0.25s;

  .search-icon {
    font-size: 1rem;
    color: #9f9f9f;

    &:hover {
      color: var(--icon-hover);
    }
    &.icon-button-disabled {
      color: #cfcfcf;
    }
  }
}

.content-hidden {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
}

.focusable {
  &.focused {
    background-color: var(--highlighted-elem-egw);
  }
}

.translate-label {
  font-weight: bold;
  margin-right: 0.25rem;
}

.header-search-view {
  position: absolute;
  background: var(--background);
  box-shadow: 0 10px 20px var(--shadow);
  width: 100%;
  text-align: left;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.88rem;
  color: var(--text-inactive);
  min-width: auto;
  margin-top: 0;
  box-sizing: border-box;
  display: block;
  z-index: $header-search-view-z-index;
  padding: math.div($header-search-input-height, 2) 0 0.3rem 0.8rem;
  top: 50%;

  .correction-content {
    display: flex;
    align-items: baseline !important;
    flex-direction: row !important;
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.2;

    .correct-label {
      font-weight: bold;
      white-space: nowrap;
    }

    .correct-wrap {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      .correct-item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: normal;
        margin-right: 0.5rem;
        color: var(--text-link);

        &:hover {
          cursor: pointer;
          color: var(--text-link-hover);
        }

        &.cuted {
          flex: 0 1 150px;
        }
      }
    }
  }

  .search-filter__preload-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 181px;
  }

  .hide-icon-container.hidden-desktop {
    display: none;
  }

  .form-col {
    box-sizing: border-box;
    width: auto;
    height: auto;
    min-height: 100%;

    &.expanded {
      .header-search-box.clickable {
        white-space: pre-wrap;
      }

      .ellipsis {
        overflow: hidden;
        text-overflow: initial;
      }
    }

    .form-search {
      width: calc(100% - 50px);
    }

    .header-search-box.clickable {
      white-space: nowrap;
      &:hover {
        color: var(--text-link-hover);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .header-search-box {
    font-size: 1rem;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;
    align-items: center;
    padding-bottom: 0;
  }

  .search-detect-main-text {
    //font-weight: bold;
    color: var(--icon-hover);
  }

  .translations-content {
    display: flex;
    font-size: 1rem;
    line-height: 1.2;
    align-items: baseline;
    row-gap: 0.25rem;
    flex-wrap: wrap;
    text-transform: capitalize;
    margin-top: 0.25rem;

    &.open {
      width: 100%;
      margin-left: 0;
    }
  }

  .detected-lang {
    color: var(--gray);
    text-transform: uppercase;
    border-width: 0 1px 0 0;
    border-style: solid;
    padding-right: 0.25rem;
    margin-right: 0.25rem;
    font-weight: bold;

    &.active {
      color: var(--icon-hover);
      &:hover {
        color: var(--icon-hover);
      }
    }
    &.clickable {
      cursor: pointer;
      &:hover {
        color: var(--icon-hover);
      }
    }

    &.last {
      border-width: 0;
      margin-right: 0;
    }
  }

  .translate-lang {
    color: var(--gray);
    text-transform: uppercase;
    font-weight: bold;
    border-width: 0 1px 0 0;
    border-style: solid;
    padding-right: 0.25rem;
    margin-right: 0.25rem;

    &.active {
      color: var(--icon-hover);
      &:hover {
        color: var(--icon-hover);
      }
    }
    &.clickable {
      cursor: pointer;
      &:hover {
        color: var(--icon-hover);
      }
    }
    &.last {
      border-width: 0;
      margin-right: 0.25rem;
    }
  }

  .flex-container-vertical {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .horizontal {
      display: flex;
    }

    .horizontal-header-row {
      display: flex;
      min-height: 20px;
    }

    .header {
      font-family: Bebas Neue, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #5aaece;
    }
    .horizontal-data-row {
      display: flex;
      height: auto;
      flex-direction: column;

      &.first-row {
        margin-right: 6rem;
      }

      .header-search-box.recent-more {
        color: var(--icon-hover);
        cursor: pointer;
        font-size: 0.9rem;
        height: 1.8rem;
        min-height: 1.8rem;
      }

      .horizontal-data-content {
        flex: 1 0 50%;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
      }

      .suggestion-content {
        &.is-login {
          border-bottom: 1px solid #e9e9e9;
        }
      }
      .header-search-box-placeholder {
        display: flex;
        font-size: 0.8rem;
        font-family: "Roboto Condensed", sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 1.22;
        align-items: center;
        padding-bottom: 0;
        color: var(--bc-text);
        white-space: nowrap;
        height: 1.8rem;
        span {
          padding-right: 0.9rem;
        }
      }
    }
  }

  .header-search-box {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    margin: 0.25rem 0;

    &.clickable {
      cursor: pointer;
    }

    .recent-ellipsis,
    .suggestion-ellipsis {
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .facets-text {
      color: var(--category-egw);
    }

    .egw-font-icon {
      font-size: 0.8rem;
      width: unset;
      height: unset;
    }

    .categories-icons {
      font-size: 0.8rem;
      color: inherit;
    }

    .facets-icons {
      padding-right: 0.25rem;
      padding-top: 5px;
      color: var(--category-egw);
    }
    .search-detect-main-text {
      &.clickable {
        cursor: pointer;
      }
    }
  }

  .search-box-more-btn {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.2;
    font-family: "Roboto Condensed", sans-serif;
    color: var(--text-link);
    &:hover {
      cursor: pointer;
      color: var(--text-link-hover);
    }
  }

  .recent-content,
  .suggestion-content {
    flex-wrap: wrap;
    height: 100%;
  }

  .recent-content {
    .header-search-box {
      flex: 1 0 20%;
    }
  }

  .search-popup-actions {
    position: absolute;
    right: 0;
    display: flex;
    font-family: "Bebas Neue", sans-serif !important;
    align-items: center;
    font-size: 1rem !important;
    line-height: 1.2 !important;
    margin: 0.25rem 0.8rem 0 0;
    z-index: 2;

    .search-lang-container {
      margin-right: 0.25rem;
      color: var(--bc-text);

      i {
        margin-right: 0.25rem;
        font-size: 0.7rem;
      }

      &.active {
        color: var(--bc-text-active);
      }

      &:hover {
        color: var(--bc-text-active);
        cursor: pointer;
      }
    }
    .hide-icon-container {
      position: unset;
      line-height: unset;

      i:before {
        line-height: unset;
      }

      span {
        font-size: 1rem !important;
      }
    }
  }
}

.mode-tablet {
  .header-search-view {
    min-width: 350px;
    height: auto;
    border-radius: 2px;
  }

  .form-col {
    width: 100%;
    height: 100%;
    padding: 0;

    .form-search {
      width: calc(100% - 50px);
    }
  }

  .horizontal-data-row {
    .horizontal-data-content {
      padding-bottom: 0;
      padding-top: 0;
    }

    .recent-content {
      padding-top: 0.4rem;
    }
  }
}

.lang-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.mode-mobile {
  .header-search-wrap {
    min-width: $header-search-min-width-mobile;
  }

  .mobile-search-form-visible {
    .header-search-wrap {
      flex: 10;
    }
  }

  .header-search-view {
    top: $header-mobile-height;
    box-shadow: 0 0 0 var(--shadow);
    border-bottom: 1px solid var(--border);
    position: fixed;
    display: block;
    z-index: $header-search-view-z-index;
    padding: 0;
    width: 100%;
    left: 0;

    .hide-icon-container.hidden-desktop {
      display: flex;
      right: 19px;
      top: 8px;
    }

    .horizontal-data-row {

      &.first-row{
        margin-right: 2rem;
      }

      .autocomplete-content,
      .synonym-content,
      .suggestion-content {
        padding-bottom: 0;
        padding-top: 0;
        width: 100%;
        flex-wrap: nowrap;
        overflow: hidden;
        :last-child {
          min-width: 0 !important;
        }
        .header-search-box {
          width: 100%;

          .search-content {
            width: 100%;
          }
        }
        .header-search-box-placeholder {
          padding-bottom: 0.28rem;
          padding-top: 0.28rem;
        }
      }

      .recent-content {
        padding-top: 0;
        width: 100%;
        flex-wrap: nowrap;
        overflow: hidden;
        :last-child {
          min-width: 0 !important;
        }
        .header-search-box {
          width: 100%;
          .search-content {
            width: 100%;
          }
        }
      }

      .suggestion-content {
        .header-search-box {
          padding-bottom: 0;
        }
      }
    }
  }

  .form-col {
    padding: 0 2.5rem 0 0.9rem;
  }
}

.mode-tablet,
.mode-desktop {
  &.mode-text {
    .search-wrap-base {
      padding-left: 0;
      padding-right: 0;
      font-size: 0.875rem;
      line-height: 0.875rem;
    }

    .search-button-wrap {
      height: 100%;
      align-items: center;
    }
  }
}

.mode-blind {
  &.mode-text {
    .search-wrap-base {
      overflow: hidden;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
    }
  }

  .header-search-view {
    box-shadow: none;
    border: 1px solid var(--border);
    .horizontal-data-row {
      .header-search-box.recent-more {
        color: var(--icon-color);
        font-size: 0.9rem;

        &:hover {
          color: var(--icon-hover);
          cursor: pointer;
        }
      }
    }
  }

  .header-search-box {
    &.active {
      .categories-icons {
        color: var(--icon-active);
      }

      .search-content {
        color: var(--icon-active);
      }
    }
  }

  .detected-lang,
  .translate-lang {
    &.clickable {
      &:hover {
        color: var(--icon-active);
      }
    }
  }
}

.mode-text {
  .header-search-wrap {
    min-width: $header-search-min-width-text-mode;
  }
}
