.ws-grip-page {
  max-width: unset;
  grid-template-columns: repeat(auto-fit, 6.2rem);
  grid-auto-rows: 9.15rem;

  &.with-shelf {
    grid-auto-rows: 12.15rem;
  }

  .ws-item {
    max-width: 6.2rem;
    height: 9.15rem;

    .ws-item-layout {
      width: 6.2rem;
      height: 7.8rem;
    }
  }
}

.ws-not-found {
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 150%;
  letter-spacing: .08em;
  color: var(--bc-text);
}

.mode-mobile {
  .ws-grip-page {
    padding: 0 0.5rem
  }

  .ws-not-found {
    padding: 0 .9rem;
  }
}