.categories-filter {
  .nested-dd__container {
    box-shadow: 0 4px 12px var(--shadow);
  }

  &.nested-dd__dd {
    min-height: 100%;
  }

  .checkbox-item, .radio-item {
    padding: 0.15rem 0.5rem;

    &.inactive {
      color: var(--inactive-text-color);
    }

    span {
      font-size: 0.8rem;
      margin-left: 0.3rem;
      color: var(--bc-text);
    }

    svg {
      min-width: 0.8rem;
      min-height: 0.8rem;
      height: 0.8rem;
    }
  }

  .lang-sel-container {
    .checkbox-item {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
