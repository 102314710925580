@use 'sass:math';
@import "./src/styles/layers";
@import "Header.variables.module.scss";

@mixin setChildrenGap($gap) {
  .header-main {
    $gap-half: math.div($gap, 2);
    margin-left: -#{$gap-half};
    margin-right: -#{$gap-half};

    > * {
      margin-left: $gap-half;
      margin-right: $gap-half;
    }
  }
}

@mixin setIconsSize($size) {
  .header-main {
    > .icon-button-base {
      width: $size;
    }

    /* These icons are not square, so need a little more width. */
    .header-egw-old-container {
      width: $size + 0.2rem;
    }

    .menu-icon {
      font-size:  $size + 0.05rem;
      width: $size + 0.05rem;
    }
    /* ============ */
  }
}

.header-main {
  z-index: $header-main-z-index;
  border-bottom: 1px solid var(--border);
  text-align: center;
  background-color: var(--header);
  display: flex;
  align-items: center;
  padding: 0 #{$header-padding-side};
  height: $header-height;
  min-height: $header-height;

  > .icon-button-base,
  > .theme-menu-wrap,
  .menu-icon,
  .header-egw-old-container {
    flex-shrink: 0;
  }
}

@include setIconsSize($header-icon-max-width-default);
@include setChildrenGap($header-children-gap-default);

.header-three-dots-popup {
  z-index: 1;
  position: absolute;
  top: calc(100% + 1px);
  right: 4px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1.2rem, 1fr));
  grid-gap: 0.4rem 1rem;
  justify-items: center;
  align-items: center;
  padding: 0.9rem;
  min-width: 280px;
  box-shadow: 0 4px 6px var(--shadow);
  background-color: var(--popup-background);
}

.header-egw-old-container {
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
  font-size: 1.22rem;
}

.header-share {
  color: var(--header-item);
  width: 1.22rem;
  height: 1.22rem;
}

.header-badge {
  border-color: var(--header);
  background-color: var(--header-badge-bc);
}

.mode-mobile {
  @include setIconsSize($header-icon-max-width-small);
  @include setChildrenGap($header-children-gap-small);
  .header-main {
    height: $header-mobile-height;
    min-height: $header-mobile-height;
  }
}

.mode-tablet {
  .header-main {
    height: $header-tablet-height;
    min-height: $header-tablet-height;
  }
}

.mode-text {
  .header-main {
    > .icon-button-base.text-only,
    > .theme-menu-wrap .icon-button-base.text-only,
    .menu-icon {
      max-width: $header-icon-max-width-text-mode;
      width: unset;
    }
  }

  .share-popup-container {
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    text-transform: capitalize;
    box-shadow: none;
    color: var(--icon-color);
    border: 1px solid var(--border-sec);
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;

    .text-only {
      z-index: 5;
      line-height: 1.5;

      &:hover {
        color: var(--text-link);
        cursor: pointer;

        &::after {
          color: var(--icon-color);
        }
      }

      &::after {
        content: "|";
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }

  .view-mode-icons span {
    &.selected {
      color: var(--primary);

      &:hover {
        color: var(--text-link);
      }
    }

    &.inactive {
      color: var(--gray);

      &:hover {
        color: var(--text-link);
      }
    }
  }
  .icon-button-base.text-only {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.mode-blind {
  .header-three-dots-popup {
    box-shadow: none;
    border: 1px solid var(--border);
  }
  .header-badge {
    color: var(--icon-active);
  }
}
