.settingsPage {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tab-wrap {
    flex-wrap: wrap;

    &.tabs-settings {
      .tab-default {
        font-size: 1.2rem;
      }
    }
  }

  .settingsMain {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    min-height: 100%;
  }

  .settingsContainer {
    flex: 1;
    box-sizing: border-box;
    padding: 1.9rem 3.5rem;

    .settingsItem {
      display: flex;
      justify-content: space-between;
      font-family: "Roboto Condensed", sans-serif;
      font-style: normal;
      font-weight: normal;
      color: var(--text-default);
      line-height: 150%;

      .slider-value {
        font-weight: bold;
        color: var(--text-link);
      }

      .settingsClickable {
        text-align: right;
        font-weight: bold;
        cursor: pointer;
        color: var(--text-link);

        &:hover {
          color: var(--text-link-hover);
        }
      }

      &.settingsToggleItem {
        width: 100%;

        span {
          font-weight: bold;
        }

        .settingsItemActive {
          color: var(--primary);
        }

        .settingsItemInactive {
          color: var(--gray);
        }

        &:hover {
          cursor: pointer;
          color: var(--primary-light);

          span {
            cursor: pointer;
            color: var(--primary-light);
          }
        }
      }

      .add-language {
        color: var(--gray);
        cursor: pointer;
        .egw-font-icon{
          font-size: 0.8em;
        }       
        &.active,
        &:hover,
        &:active {
          color: var(--primary);         
        }
      }

      .settings-list {
        margin: 8px 0 0 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-item {
          border-top: 1px solid var(--border-sec);
          padding-left: 30px;
          padding-top: 8px;
          padding-bottom: 8px;
          display: flex;
          justify-content: space-between;
        }
      }

      .settingsItemButton {
        cursor: pointer;
        font-weight: bold;
        color: var(--text-link);

        &:hover {
          color: var(--text-link-hover);
        }
      }
    }

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .settings-subtitle {
      display: inline-block;
      font-family: "Roboto Condensed", sans-serif;
      color: var(--text-link);
      margin: 20px 0 10px;

      &.clickable {
        &:hover {
          color: var(--text-link-hover);
        }
      }
    }

    .clickable {
      cursor: pointer;
    }

    .settingsSliderWrap {
      padding: 5px 0;

      .egw-slider-progress,
      .settingsSlider {
        color: var(--secondary);
      }

      .settingsSliderLegend {
        display: flex;
        justify-content: space-between;
        color: var(--gray);
      }
    }

    hr {
      display: block;
      height: 1px;
      border: 0;
      margin: 8px 0;
      border-top: 1px solid var(--border-sec);
      padding: 0;
    }
  }

  .capitalize {
    text-transform: uppercase;
  }

  &.width-sm {
    .settingsContainer {
      padding: 0.9rem;
    }
  }

  &.width-md {
    .settingsContainer {
      padding: 1.4rem;
    }
  }
}

.settings-title {
  font-weight: bold;
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: 0.08em;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 0;
  color: var(--primary-light);
  &:first-child {
    margin-top: 0;
  }
}

.settingsDivider {
  height: 1px;
  color: var(--main-gray-light);
}

.setting-ml-timer {
  padding: 0 1em;
  margin-right: 1em;
  border-right: 1px solid var(--border-sec);
}

.time-zone-container {
  width: 13.1rem;
  height: 21.1rem;
  position: relative;
  z-index: 101;
  box-sizing: border-box;
  padding: 0.5rem;
  background-color: var(--popup-background);

  .search-wrap-base {
    font-size: 1rem;
    height: 1.3rem;
  }

  .title {
    font-size: 20px;
    line-height: 1.5em;
    letter-spacing: 0.05em;
    color: var(--primary-light);
  }

  .radio-item {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    svg {
      width: 0.6rem;
      height: 0.6rem;
    }

    img {
      width: 0.9rem;
      height: 0.5rem;
      margin-right: 0.3rem;
      position: relative;
    }

    span {
      font-size: 0.8rem;
      font-weight: normal;
      margin: 0;
    }
  }
  & .active {
    span {
      font-weight: bold;
    }
  }
}

.color-mode-mobile {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1rem;

  svg {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;
  }
}

.settingsPage-popup {
  .radio-item,
  .checkbox-item {
    width: 100%;
    box-sizing: border-box;
  }
  svg {
    min-width: 1.1rem;
  }
}

.mode-mobile {
  .settingsPage {
    padding-bottom: 1rem;

    .settingsMain {
      margin: 0 0.9rem;
    }

    .tab-wrap {
      flex-direction: column;
      padding: 0;

      .tab-default {
        padding: 0.8rem 0.3rem 0.6rem 0.9rem;
        font-size: 1.2rem;
        line-height: 1.2rem;
        text-decoration: none;
        align-items: center;
        display: flex;
        color: var(--tiles-text);
        background-color: var(--tiles-bg);
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .settingsContainer {
    padding: 0 !important; // Strict set to interrupt "width-sm", "width-md" styles.
  }
}

.mode-tablet {
  .settingsPage {
    .tab-wrap {
      padding: 0;

      .tab-default {
        margin: 0.5rem 0.3rem 0.5rem 0;
      }
    }
  }

  .settingsContainer {
    padding: 1.3rem !important; // Strict set to interrupt "width-sm", "width-md" styles.
  }
}

.mode-desktop {
  .settingsPage {
    .settingsMain {
      margin-top: 1.1rem;
    }
  }

  .settingsContainer {
    flex: 0.8;
  }
}

.mode-tablet,
.mode-desktop {
  .settingsPage {
    height: 100%;
  }

  .settingsContainer {
    border: 1px solid var(--border);
  }
}

.mode-mobile {
  .time-zone-container {
    width: 100%;
    max-height: calc(100vh - 1rem);
  }
  .settingsPage {
    .tab-wrap {
      flex-wrap: nowrap;
      width: 100%;
    }
  }
}

.mode-blind {
  &.mode-mobile {
    .tabs-settings.tab-wrap {
      .tab-default {
        box-shadow: none;
        border-bottom: 1px solid var(--border);
      }
    }
  }
}
