@import "./src/styles/mixins";

.donate-notification {
  z-index: var(--donate-notification-z-index);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 13rem;
  box-shadow: 0 -0.3rem 0.4rem rgba(0, 0, 0, 0.15);
  background-color: #ffffff;

  .donate-notification-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    align-items: center;
  }

  .donate-notification-icon-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 10%;
    padding-left: 20%;
    background-color: #34AFD2;
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 100%;
  }

  .donate-icon {
    flex-shrink: 0;
    font-size: 6rem;
    color: var(--white);
  }

  .donate-notification-content-title {
    color: #5AAECE;
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 1.4rem;
  }

  .donate-notification-content-text {
    color: #727272;
    font-size: 1.25rem;
    line-height: 1;
    margin-bottom: 1.4rem;
  }

  .already-donated {
    display: inline-block;
    margin-left: 1.3rem;
    letter-spacing: 0.1em;
    font-size: 1rem;
    font-family: "Bebas Neue", sans-serif;
    color: #5AAECE;
    cursor: pointer;
  }

  .donate-notification-actions {
    .button-base {
      min-width: 7rem;
      height: 2.2rem;

      &.button-primary {
        background: #FF9D5B;
        border-color: #FF9D5B;
        color: #ffffff;

        &:hover {
          background-color: #FFA96E;
          border-color: #FFA96E;
        }

        .button-link {
          color: #ffffff;
        }
      }
    }
  }

  .hide-icon-container {
    top: 0.8rem;
  }

  .iconInActive {
    color: #595959; 
  }
}

.mode-tablet {
  .donate-notification-content {
    padding: 0.5rem;
  }

  .donate-notification-content-title {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }

  .donate-notification-content-text {
    font-size: 1rem;
    line-height: 1.1rem;
    margin-bottom: 0.5rem;
  }
}

.mode-mobile {
  .donate-notification {
    height: 10rem;
    background-color: #34AFD2;

    .donate-notification-wrapper {
      grid-template-columns: minmax(3.3rem, auto) 1fr;
      grid-gap: 1rem;
      padding: 0 0.9rem;

      &.with-zoom {
        grid-template-columns: auto 1fr;

        .donate-notification-content {
          padding-top: 2rem;
        }

        .donate-notification-content-title,
        .donate-notification-content-text {
          margin-bottom: 0.3rem;
        }

        .donate-icon {
          font-size: 2rem;
        }

        .already-donated {
          margin: 0.3rem;
        }

        .donate-notification-actions {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .donate-notification-icon-wrapper {
          align-items: center;
          margin-top: 0;
        }
      }
    }

    .donate-notification-icon-wrapper {
      background: none;
      padding-left: 0;
      margin-right: 0;
      align-items: normal;
      margin-top: 3.5rem;
    }

    .donate-icon {
      font-size: 3.3rem;
    }

    .donate-notification-content-title,
    .donate-notification-content-text,
    .already-donated {
      color: #ffffff;
    }

    .donate-notification-content-title {
      font-size: 1.7rem;
      margin-bottom: 1rem;
      letter-spacing: 0.1rem;
    }

    .donate-notification-content-text {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    .already-donated {
      margin-left: 1rem;
      font-size: 0.9rem;
    }

    .hide-icon-container {
      color: rgba(255, 255, 255, 0.6);
   }
  }
  &.mode-zoom-250,
  &.mode-zoom-200,
  &.mode-zoom-300,
  &.mode-zoom-350,
  &.mode-zoom-400 {
    .donate-notification {
      height: 8rem;
      .donate-notification-content {
        padding-top: 0.5rem;
      }
    }
  }
  &.mode-zoom-350,
  &.mode-zoom-400 {
    .donate-notification {
      height: 7rem;
      .donate-notification-actions {
        .button-base {
          min-width: 6rem;
          height: 2rem;
        }
      }

      .donate-notification-wrapper.with-zoom .donate-notification-content {
        padding-top: 1rem;
      }
    }
  }
}

.mode-blind {
  .donate-notification {
    .iconActive:hover, .iconInActive:hover {
      color: var(--icon-active);
      background-color: var(--icon-background);
    }
  }
}
