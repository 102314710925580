@import "src/shared/styles/mixins";

/*Date picker*/
.root-date-picker {
  padding: 1rem;
}

.date-picker-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  z-index: var(--date-picker-container-z-index);
  margin: 0;
  background-color: var(--background);
  border-radius: 3px;
}

.panel-popup {
  position: absolute;
  padding: 0.45rem;
  box-sizing: border-box;
  left: 0;
  top: 0;
  max-width: 18rem;
  background: var(--popup-background);
  border-radius: 0.1rem;
  box-shadow: 0 4px 12px var(--shadow);
  z-index: 53;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 0.6rem;

  .font-list-container > svg,
  .egw-font-icon,
  > svg {
    margin: 0 0.6rem;
  }

  &.reader-control-panel-popup {
    .icon-button-base {
      margin: 0.6rem 0.6rem;
      min-width: 2.1rem;
      flex: 0 1 auto;
      text-align: center;
    }
    .font-list-container {
      display: flex;
      svg,
      i {
        flex-basis: 100%;
      }
    }

    .np-icon-wrap {
      width: calc(100% - 16.6%);
      margin-right: auto;
      .np-input {
        width: 100%;
      }
      svg,i {
        min-width: auto;
        flex: 1 0 auto;
        width: auto;
        margin: 0.6rem 0;
      }
    }
    .np-icon-wrap-actions {
      width: 100%;
    }
    &.is-periodical {
      .np-icon-wrap {
        width: 100%;
        order: -1;
      }
    }
  }
}

.panel-base {
  display: flex;
  svg,
  i {
    margin: 0 0.25rem;
  }
}

.dialog-container .date-picker-container {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  margin: 0;
}

.date-picker-content {
  position: relative;

  .react-datepicker {
    max-height: 90vh;
    max-width: 90vw;
    border: none;
    border-radius: 0;
    font-size: 0.9rem;
    background-color: transparent;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 0;
  }

  .datepicker-header-container {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0.3rem;
    padding: 0 0.6rem;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .datepicker-header-title {
      position: relative;
      top: 0.1rem;
      font-family: "Bebas Neue", sans-serif;
      font-weight: bold;
      font-size: 1rem;
      color: var(--text-inactive);
      letter-spacing: 0.1em;
    }

    .datepicker-header-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  .react-datepicker__month-container {
    background-color: transparent;
  }

  .react-datepicker__day {
    color: var(--text-inactive);
    font-family: "Roboto Condensed", sans-serif;
  }

  .react-datepicker__day:hover {
    color: var(--primary);
    font-weight: bold;
    background-color: transparent;
  }

  .react-datepicker__day-name {
    font-family: "Bebas Neue", sans-serif;
    color: var(--gray);
    letter-spacing: 0.1em;
    font-weight: bold;
  }

  .react-datepicker__day--today {
    color: var(--primary-light);
  }

  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: var(--border);
    pointer-events: none;
  }

  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: var(--primary-light);
    color: var(--btn-st-text);
  }

  .react-datepicker__day--selected:hover {
    border-radius: 50%;
    background-color: var(--primary);
    color: var(--btn-st-text);
  }

  .datepicker-day-container {
    position: relative;
  }

  .datepicker-mark {
    width: 0.2rem;
    height: 0.2rem;
    position: absolute;
    bottom: 0.1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  }

  .mark-selected {
    background-color: var(--white);
  }

  .mark-unread {
    background-color: var(--dot-active);
  }

  .mark-read {
    background-color: var(--sc-color-border);
  }
}

/*Date picker*/

/*Time picker*/
.time-picker-container {
  position: relative;
  z-index: 101;
  min-width: 8.9rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.9rem 1.3rem 1rem;
  box-shadow: 0 4px 12px var(--shadow);
  background-color: var(--popup-background);
}

.time-picker-unit-container {
  display: flex;
  flex-direction: column;
}

.time-picker-arrow {
  fill: var(--primary-light) !important;
}

.time-picker-input {
  box-sizing: border-box;
  padding: 0;
  width: 1.1rem;
  background: var(--background);
  border: none;
  outline: none;
}

.time-picker-input::-webkit-outer-spin-button,
.time-picker-input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.time-picker-text {
  font-family: "Bebas Neue", sans-serif;
  line-height: 0.9rem;
  font-size: 0.9rem;
  color: var(--bc-text);
  font-weight: bold;
  margin: 0.5rem 0;
  text-align: center;
  letter-spacing: 0.1em;
}

.time-picker-semicolon {
  margin: auto 0;
}

.time-picker-increaseArrow {
  transform: rotate(270deg);
}

.time-picker-decreaseArrow {
  transform: rotate(90deg);
}

/*Tab bar*/
.tab-wrap {
  display: flex;
  justify-content: space-between;
  padding: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.07em;
  flex-wrap: wrap;

  &.mobile {
    position: sticky;
    width: 100%;
    background-color: var(--background);
    z-index: 10;
  }

  .tab-default {
    font-size: 1.3rem;
  }
}

.mode-mobile {
  .tab-wrap.subs-tabs {
    .tab-default {
      font-size: 1rem;
    }
  }

  .tab-wrap-mob-resizable {
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: space-between;

    .tab-default {
      margin: 0;
    }
  }
}

.tab-active {
  text-decoration: underline;
  color: var(--tab-active);
}

.tab-inactive {
  text-decoration: none;
  color: var(--tab-inactive);
}

.tab-locked-active {
  text-decoration: underline;
  color: var(--tab-active);
}

.tab-locked-inactive {
  text-decoration: underline;
  color: var(--tab-inactive);
}

.tab-default {
  cursor: pointer;
  font-weight: bold;
  font-family: "Bebas Neue", sans-serif;
  line-height: 1em;
  letter-spacing: 0.08em;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;

  &:hover {
    color: var(--tab-inactive-hover);
  }
}

/*Tab bar*/

.hide-icon-container {
  font-size: 0.88rem;
  line-height: 1;
  letter-spacing: 0.1em;
  position: absolute;
  cursor: pointer;
  display: flex;
  user-select: none;
  right: 0.5rem;
  top: 0.9rem;
  z-index: 4;
  font-family: "Bebas Neue", sans-serif;
  align-items: center;
  box-sizing: border-box;

  i {
    font-size: 0.9em;
  }

  span {
    margin-left: 0.2em;
  }
}

.dialog-hide-icon {
  display: flex;
  justify-content: flex-end;

  .hide-icon-container {
    position: unset;
  }
}

.grid-wrap {
  width: 100%;
  height: 100%;
  min-height: 150px;
  position: relative;

  .grid-view {
    &:focus {
      outline: none;
    }
  }
}

.checkbox-icon {
  fill: var(--primary);
  min-width: 1rem;
  min-height: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.checkbox-item,
.radio-item {
  display: flex;
  padding: 0.2rem 0.5rem;
  align-items: center;
  white-space: nowrap;

  &:hover {
    cursor: pointer;

    svg {
      fill: var(--icon-hover);
    }

    span {
      color: var(--text-link-hover);
    }
  }

  &.active {
    color: var(--primary);

    svg {
      fill: var(--primary);
    }
  }  

  &.inactive {
    color: var(--text-inactive);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    min-width: 1.1rem;
  }

  span {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    margin-left: 0.8rem;
    line-height: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

/*buttons*/
.button-disable {
  cursor: default;
}

.button-base {
  letter-spacing: 0.05em;
  font-family: "Bebas Neue", sans-serif;
  align-items: center;
  user-select: none;
  justify-content: center;
  padding: 0 0.5rem;
  height: 2.2rem;
  line-height: normal;
  font-weight: bold;
  display: inline-flex;
  box-sizing: border-box;
  border-radius: 0.3rem;
  color: var(--white);
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &.single-line {
    white-space: nowrap;
  }
}

.button-primary {
  border: 2px solid var(--btn-st);
  color: var(--btn-st-text);
  background: var(--btn-st);

  &:hover {
    border: 2px solid var(--btn-st-hover-border);
    background: var(--btn-st-hover);
    color: var(--btn-st-text-hover);

    a {
      color: var(--btn-st-text-hover);
    }
  }
}

.button-primary-outline {
  border: 2px solid var(--btn-st);
  color: var(--btn-st-text);

  &:hover {
    border: 2px solid var(--btn-st-hover);
    color: var(--btn-st-text-hover);
  }
}

.button-secondary {
  border: 2px solid var(--btn-sec);
  color: var(--btn-sec-text);
  background: var(--btn-sec);

  &:hover {
    border: 2px solid var(--btn-sec-hover-border);
    background: var(--btn-sec-hover);
    color: var(--btn-sec-text-hover);

    a {
      color: var(--btn-sec-text-hover);
    }
  }
}

.button-secondary-outline {
  border: 2px solid var(--btn-sec-outline-border);
  color: var(--btn-sec-outline-text);

  &:hover {
    border: 2px solid var(--btn-sec-outline-hover-border);
    color: var(--btn-sec-outline-hover-text);
    background-color: var(--btn-sec-outline-hover-bg);

    a {
      color: var(--btn-sec-outline-hover-text);
    }
  }
}

.button-tertiary {
  background-color: var(--tertiary);
  border: 2px solid var(--tertiary);
  &:hover {
    background-color: var(--tertiary-light);
    border: 2px solid var(--tertiary-light);
    color: var(--btn-st-text);
  }
}

.button-tertiary-outline {
  border: 2px solid var(--tertiary);
  color: var(--tertiary);
  &:hover {
    border: 2px solid var(--tertiary-light);
    color: var(--tertiary-light);
    a {
      color: var(--btn-sec-outline-hover-text);
    }
  }
}

.button-quaternary-outline {
  border: 2px solid var(--quaternary);
  color: var(--quaternary);
  &:hover {
    border: 2px solid var(--quaternary-light);
    color: var(--quaternary-light);
    a {
      color: var(--btn-sec-outline-hover-text);
    }
  }
}

.button-quaternary {
  background-color: var(--quaternary);
  border: 2px solid var(--quaternary);
  &:hover {
    background-color: var(--quaternary-light);
    border: 2px solid var(--quaternary-light);
    color: var(--btn-st-text);
  }
}

.button-disabled {
  cursor: default;
  background: var(--btn-disable);
  border: 2px solid var(--btn-disable-border);
  color: var(--btn-disable-text);
  pointer-events: none;
}

.button-disabled-outline {
  pointer-events: none;
  cursor: default;
  border: 2px solid var(--btn-disabled-outline-border);
  color: var(--btn-disabled-outline-text);
}

/*icon-buttons*/
.icon-button-base {
  user-select: none;
  transition: all 0.2s;
  height: 1.2rem;
  width: 1.2rem;
  font-size: 1.2rem;
  fill: var(--icon-color);
  color: var(--icon-color);
  font-family: egw-web-font, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include canHover {
    &:hover {
      cursor: pointer;
      fill: var(--icon-hover);
      color: var(--icon-hover);
    }
    &.icon-button-active {
      &:hover {
        cursor: pointer;
      }
    }
    &.icon-button-disabled {
      &:hover {
        cursor: default;
        fill: var(--icon-disable);
        color: var(--icon-disable);
      }
    }
    &.has-increased-tap-zone {
      $tap-zone_size: 0.5em;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -#{$tap-zone_size};
        top: -#{$tap-zone_size};
        right: -#{$tap-zone_size};
        bottom: -#{$tap-zone_size};
      }
    }
  }

  &:active {
    cursor: pointer;
    fill: var(--icon-active);
    color: var(--icon-active);
  }
  &.icon-button-active {
    fill: var(--icon-active);
    color: var(--icon-active);
  }
  &.icon-button-disabled {
    fill: var(--icon-disable);
    color: var(--icon-disable);
    // pointer-events: none;
  }
}

.button-link {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: inherit;
}

.ls-label {
  font-family: "Roboto Condensed", sans-serif;
  color: var(--text-inactive);
  letter-spacing: 0.03em;
}

.select-container {
  min-width: 0;
  width: 100%;
  position: relative;
  display: inline-flex;

  .select-label {
    font-family: "Roboto Condensed", sans-serif;
  }

  .pub-select-container-base {
    height: 2.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
}

.pub-dialog-row {
  .select-label {
    width: 100%;
  }

  .pub-select-container-base {
    //max-width: 14.3rem;
  }
}

.select-container.with-arrow.active {
  .form-select-field {
    svg {
      transform: rotate(90deg);
    }
  }
}

.text-align-container {
  padding: 0.4rem 0.5rem 0.2rem;

  i {
    font-size: 1.1rem;
    margin: 0 0.5rem;

    &.active {
      color: var(--icon-active);
    }

    &:hover {
      color: var(--icon-hover);
      cursor: pointer;
    }
  }
}

.sort-icons-container {
  display: flex;
  flex-direction: column;

  .sort-icons:first-child {
    padding-top: 0.3rem;
  }

  .sort-icons:last-child {
    padding-bottom: 0.3rem;
  }
}

.sort-icons {
  display: flex;
  width: 0.9rem;
  align-items: center;
  justify-content: flex-end;
}

.sort-icon {
  width: 0.5rem;
  height: 0.5rem;
  fill: var(--icon-color);

  &.is-active {
    fill: var(--text-link);
  }
}

.mode-blind {
  .time-picker-container,
  .date-picker-container {
    box-shadow: none;
    border: 2px solid var(--border);
  }

  .dialog-container {
    .time-picker-container,
    .date-picker-container {
      border: none;
    }
  }

  .button-primary,
  .button-secondary {
    border-style: double;
    border-width: 7px;
  }

  .button-secondary-outline:not(.height-tiny) {
    &:hover {
      border-width: 7px;
      border-style: double;
    }
  } 

  .tab-default {
    color: var(--secondary);

    &:hover,
    &.tab-active {
      color: var(--tab-active);
    }
  }
}

.mode-mobile {
  .grid-scrollbar {
    min-height: 150px;
  }
  .react-datepicker {
    overflow: scroll;
  }
  .root-date-picker {
    padding: 0;

    .hide-icon-container {
      height: 2rem;
      margin-right: 0.5rem;
    }
  }
}

.textarea-autocomplete {
  border: 1px solid var(--border);
  min-height: 1rem;
  padding: 0.3rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-content: baseline;
  flex-wrap: wrap;

  .scrollbars-render-view {
    min-height: 0 !important;
  }

  .select-container {
    width: 100%;
    vertical-align: middle;

    input {
      height: 1.6rem !important;
      padding: unset !important;
      margin: unset !important;
      width: unset !important;
    }
  }

  .chip {
    max-height: 1rem;
    padding: 0.3rem 0.5rem;
    border: 1px solid var(--border);
    border-radius: 0.3rem;
    
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.8rem;
    white-space: nowrap;

    user-select: none;

    .icon-close {
      font-size: 0.6rem;
      cursor: pointer;
      line-height: 0.6rem;
    }
  }

  input {
    border: none !important;
    margin-top: 3px;
    background: transparent;
  }
}
