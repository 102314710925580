.price-description {
  display: flex;
  align-items: flex-end;

  font-size: 1.3rem;
  line-height: 1;

  &.price-for-one-book {
    font-size: 1rem;

    .price {
      font-size: 1.1rem;
    }
  }
}

.price-description_label {
  margin-right: 0.8rem;

  color: var(--form-label-color);
}

.price {
  font-family: "Bebas Neue", sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--text-inactive);

  & + .price {
    margin-left: 0.4rem;
  }
}
.price.discounted {
  color: var(--text-inactive);
  text-decoration: line-through;
}

.price.discount {
  color: var(--tertiary);
}

.mode-mobile {
  .price-description {
    font-size: 1.1rem;

    &.price-for-one-book {
      font-size: 1rem;
    }
  }

  .price {
    font-size: 1.2rem;
  }
}
