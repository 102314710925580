@import "./egw-web-font.scss";
@import "src/shared/styles/mixins";

.with-chevron {
  &::after {
    font-family: "egw-web-font";
    content: map-get($egw-web-font-map, "chevron-right");
    display: inline-block;
    padding-right: 0.1em;
    vertical-align: middle;
    font-weight: bold;
    font-size: 0.7em;
    padding-left: 0.3em;
    transition: all 0.25s;
  }

  &.open {
    &::after {
      transform: rotate(90deg);
    }
  }  
}

.with-chevron-start{
  &::before {
    font-family: "egw-web-font";
    content: map-get($egw-web-font-map, "chevron-left");
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    font-size: 0.7em;
    padding-right: 0.3em;
    transition: all 0.25s;
  }
  &.open {
    &::before {
      transform: rotate(90deg);
    }
  } 
}

.with-chevron-less-more {
  &::after {
    font-family: "egw-web-font";
    content: map-get($egw-web-font-map, "chevron-down");
    display: inline-block;
    padding-right: 0.1em;
    vertical-align: middle;
    font-weight: bold;
    font-size: 0.7em;
    padding-left: 0.3em;
    transition: all 0.25s;
  }

  &.open {
    &::after {
      transform: rotate(-180deg);
    }
  }  
}

.egw-font-icon {
  font-family: egw-web-font !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: unset;
  width: unset;
  transition: color, fill, font-size 0.25s;
}

.letters-manuscripts,
.modern-english,
.devotionals,
.devotional,
.misc-collections,
.manuscript-releases,
.manuscript, // For textapp.
.egwwritings,
.external,
.bookTitle,
.biography,
.books,
.book,
.language,
.periodicals,
.periodical, // For textapp.
.categories-category, // For "Category Tree".
.pamphlets {
  color: var(--category-egw);
  fill: var(--category-egw);

  h2,
  h3,
  h4,
  strong {
    color: var(--category-egw);
  }

  &:hover {
    color: var(--category-egw);
  }

  mark {
    background: var(--category-egw-search-mark);
    color: white;
  }
}

.dictionary,
.adventist-beliefs,
.reference-works,
.topical-index,
.study-guides,
.research-documents,
.childrens-stories,
.reference {
  color: var(--category-references);
  fill: var(--category-references);

  h2,
  h3,
  h4,
  strong {
    color: var(--category-references);
  }


  &:not(.search-item-snippet):not(.bible):hover {
    color: var(--category-references);
    fill: var(--category-references);
  }

  mark {
    background: var(--category-references-search-mark);
    color: var(--white);
  }
}

.apl,
.misc-titles,
.recent-authors,
.apl>.periodicals {
  color: var(--category-apl);
  fill: var(--category-apl);

  h2,
  h3,
  h4,
  strong {
    color: var(--category-apl);
  }

  &:hover {
    color: var(--category-apl);
    fill: var(--category-apl);
  }

  mark {
    background: var(--category-apl-search-mark);
    color: white;
  }
}


.bibleCommentary, // for the 'titles' tree
.bible-versions,
.bible-concordances,
.bible-dictionaries,
.bible-commentaries,
.bible-sdasi,
.bible {
  color: var(--category-bible);
  fill: var(--category-bible);

  &:hover {
    color: var(--category-bible);
  }

  h2,
  h3,
  h4,
  strong {
    color: var(--category-bible);
  }

  mark {
    background: var(--category-bible-search-mark);
    color: white;
  }
}


.book-item-image-wrap {
  height: 100%;
  position: relative;
  background-size: cover;
  // z-index: $book-list-item-cover__image-z-index - 2;
  z-index: 3;

  &.is-big {
    .text-over-image {
      font-size: 0.7em;
    }
  }

  background-image: url("../../assets/placeholder/base.png");
  &.has-cover-big {
    background-image: url("../../assets/placeholder/base-big.png");
  }

  &.dictionary,
  &.reference {
    background-image: url("../../assets/placeholder/reference.png");

    &.has-cover-big{
      background-image: url("../../assets/placeholder/reference-big.png");
    }
  }

  &.bible {
    background-image: url("../../assets/placeholder/bible.png");

    &.has-cover-big{
      background-image: url("../../assets/placeholder/bible-big.png");
    }
  }

  &.apl {
    background-image: url("../../assets/placeholder/apl.png");

    &.has-cover-big{
      background-image: url("../../assets/placeholder/apl-big.png");
    }
  }
}

.book-item-image-img {
  position: relative;
  box-sizing: border-box;
  z-index: 3; 
  width: 100%;
  height: 100%;
}

.text-over-image {
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 2;

  box-sizing: border-box;
  width: 100%;
  padding: 0 0.6em;

  font-size: 1em;
  font-weight: bold;
  word-wrap: break-word;
  letter-spacing: 0.1rem;
  text-align: center;
  @include lineClamp(5, 1.35);

  color: var(--white);

  transform: translateX(-50%);

  &.is-centered {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mode-text {
  .book-item-image-wrap {
    background: radial-gradient(ellipse at center, #ffffff 0%, #cbebff 47%, #49b5db 100%);

    .text-over-image {
      font-family: "Roboto Condensed", Roboto, Helvetica, Arial, sans-serif;
      font-weight: normal;
      letter-spacing: initial;

      color: #000;
    }
  }
}

