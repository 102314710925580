@import "../shared/styles/functions";

$side-menu-item-height: 2.7rem;

$top-bar-desktop-height: 4.5rem;

$top-bar-mobile-height: 3rem;

$panel-header-height: 2rem;

$book-list-item__width__mobile__tablet: 5.6rem;
$book-list-item__width__desktop: 6.2rem;

$reader-panel-search-field-height: 2.5rem;
$reader-panel-head-height: 3rem;
$reader-panel-head-height-reduced: 2rem;

$theme-popup-height: 17rem;

$animation-duration_side-panel: 0.3s;
$animation-timing-fn_side-panel: ease-in;

:export {
  panelHeaderHeight: stripUnit($panel-header-height);

  sideMenuItemHeight: stripUnit($side-menu-item-height);

  bookListItemWidthMobileTablet: stripUnit($book-list-item__width__mobile__tablet);
  bookListItemWidthDesktop: stripUnit($book-list-item__width__desktop);

  readerPanelSearchFieldHeight: stripUnit($reader-panel-search-field-height);
  readerPanelHeadHeight: stripUnit($reader-panel-head-height);

  themePopupHeight: stripUnit($theme-popup-height);
}
