.subs-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .subs-content {
    display: flex; // Prevent Iphone 6s subs content overflow.
    height: 100%;

    &.subs-feeds {
      flex-direction: column;
      width: 100%;
    }
  }

  .date-picker-container {
    position: absolute;
  }
}

.subs-top-panel {
  display: flex;
  flex-shrink: 0;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  border: 1px solid var(--border);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.subs-edit-icon {
  margin-left: 0.7rem;
}

.tab-wrap.subs-tabs {
  flex-shrink: 0;
  min-width: 16rem;
  padding: 0.5rem 0;

  button {
    display: block;
    margin: 0.5rem;
  }
}

/*All subs*/
.subs-default-text {
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 150%;
  letter-spacing: 0.08em;
  color: var(--bc-text);
}

.subs-list-item-title {
  font-family: "Bebas Neue";
  font-style: normal;
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: bold;
  letter-spacing: 0.08em;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.subs-list-item {
  display: flex;
  margin-bottom: 3.1rem;

  &.mobile {
    &:not(:first-child) {
      margin-top: 0.9rem;
    }

    padding-bottom: 0.5rem;
    margin-bottom: 0;
    border-bottom: 1px solid var(--border);
  }

  .book-row {
    margin-top: 0.4rem;

    .icon-button-base {
      font-size: 1.1rem;
      line-height: 1.3rem;
      width: 1.1rem;
      height: 1.1rem;

      &:hover {
        font-size: 1.3rem;
        width: 1.3rem !important;
        height: 1.3rem !important;
      }
    }

    .icon-selected {
      fill: var(--btn-st);
      color: var(--btn-st);

      &:hover {
        fill: var(--btn-st);
        color: var(--btn-st);
      }
    }
  }

  .subs-list-item-book-item {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 6.3rem;

    .book-image-wrap {
      height: 9.25rem;
      .book-item-image-img {
        height: 9.25rem;
      }
    }
  }

  .subs-list-item-desc-container {
    position: relative;
    // cursor: pointer;
    margin-left: 2.3rem;
    font-family: "Roboto Condensed", sans-serif;

    &.mobile {
      margin-left: 1.2rem;
    }
  }

  .subs-list-item-fade {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      var(--background-transparent),
      var(--background) 100%
    );
    height: 4em;
  }

  .subs-list-item-description {
    position: relative;
    margin-top: 0.3rem;

    p,
    h4,
    h3,
    h2,
    h1 {
      margin: 0;
      font-family: "Roboto Condensed", sans-serif;
      line-height: 1.5rem;
    }

    p {
      font-size: 1rem;
      font-style: normal;
      font-weight: normal;
    }
  }
}

.subs-start-stop {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
}

.subs-arrow-container {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-size: 1rem;
  display: inline-flex;
  white-space: nowrap;
  align-items: baseline;
  text-transform: capitalize;
  color: var(--bc-text-active);

  &:hover {
    .subs-more-text,
    .subs-more-icon {
      color: var(--text-link-hover);
    }
  }
}

.subs-more-text {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
}

.subs-more-icon {
  margin-left: 0.25rem;
  font-size: 0.8rem;
}

.subs-vacation {
  margin-top: 0.7rem;
}
/*All subs*/

/*Thoughts*/
.subs-thought-item-container {
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
  border-bottom: 1px solid #dcdcdc;

  .subs-thought-title-container {
    display: flex;
    margin-top: 0.8rem;

    svg {
      width: 1.1rem;
      height: 1.1rem;
      fill: var(--icon-color);
      cursor: pointer;
    }

    & svg:first-child {
      margin-right: 0.4rem;
    }
  }

  .subs-thought-content {
    margin-top: 1.7rem;
    overflow: hidden;
    color: var(--text-default);
    line-height: 152%;

    p {
      margin: 0;
    }
  }

  .subs-thought-title {
    // margin-top: 1.3rem;
    flex: 1;
    font-style: italic;
    color: var(--text-link);
  }
}
/*Thoughts*/

/*Subs badge*/
.badge-container {
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  // Fixes text down shifting. The font by itself is not correct positioned so it should be force shifted.
  // padding-top: 0.17rem;
  border-radius: 50%;
  background-color: var(--secondary);
  color: var(--sub-weekday-text);
  top: 0;
  bottom: 0;
  font-size: 0.7rem;
  // transform: translateY(50%);

  &.min {
    top: 0.1rem;
    right: 0.1rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}
/*Subs badge*/

/*EditSubscriptionPage*/
.subs-edit-type-item {
  box-sizing: border-box;
  padding: 0.9rem;
  font-size: 1.2rem;
  color: var(--primary-light);
  letter-spacing: 0.05em;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.subs-edit-item-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.9rem;
  font-family: "Roboto Condensed", sans-serif;
  color: var(--text-default);

  .title {
    width: 78%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-text {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;

    &.on {
      color: var(--primary-light);
    }

    &.off {
      color: var(--bc-text);
    }
  }

  .subs-edit-switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.3rem;
  }

  &.border {
    border-bottom: 1px solid var(--border);
  }
}
/*EditSubscriptionPage*/

@media (hover: hover) and (pointer: fine) {
  .subs-edit-type-item {
    cursor: pointer;
  }
}

.mode-mobile {
  .subs-container {
    .subs-content {
      box-sizing: border-box;
      padding: 0 0.9rem;
      /* Fix height equals 0. */
      flex-grow: 1;
      flex-direction: column;
      /* ======== */
    }

    .subs-top-panel {
      border-left: none;
      border-right: none;
      box-shadow: none;
    }
    .feeds-container-wrapper {
      padding-bottom: 1rem;
    }
    .tab-wrap {
      margin-left: 0.9rem;
      margin-right: 0.9rem;
      min-width: auto;
      flex-grow: 0;
      padding: 0.5rem 0;
    }
  }

  .subs-list-item-book-item {
    width: 5.7rem;

    .book-image-wrap {
      height: 8.4rem;
      .book-item-image-img {
        height: 8.4rem;
      }
    }
  }

  .subs-vacation {
    padding-top: 0.7rem;
    border-top: 1px solid var(--border);
  }

  .subs-list-item {
    .subs-list-item-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subs-list-item-desc-container {
      overflow: hidden;
    }
  }
}

.mode-mobile,
.mode-tablet {
  .subs-top-panel {
    height: 2rem;
  }
  .subs-vacation {
    svg:first-child {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.mode-zoom-300,
.mode-zoom-400 {
  &.mode-mobile {
    .subs-tabs {
      padding: 0.5rem 0;
    }
    .subs-top-panel {
      padding: 0 0.5rem;
      height: 2rem;
    }
    .date-switcher-date-text {
      font-size: 0.5rem;
    }
    .date-switcher-date-current {
      font-size: 0.7rem;
    }
  }
}

.mode-zoom-150 {
  &.mode-mobile {
    .subs-tabs {
      flex-wrap: wrap;
      padding: 0.5rem 0.3rem;
      min-width: auto;
      .tab-default {
        min-height: 1.4rem;
      }
    }
    .subs-list-item {
      flex-wrap: wrap;

      .subs-list-item-desc-container {
        margin: 0;
      }
    }

    .subs-list-item-book-item {
      margin: auto;
    }

    .subs-top-panel {
      height: auto;
      padding: 0.3rem;

      div:last-child {
        display: flex;

        svg:last-child {
          margin-left: 0.3rem;
        }
      }
    }

    .date-switcher-date-current {
      font-size: 0.9rem;
      margin: 0 0.3rem;
    }

    .react-datepicker {
      .react-datepicker__day,
      .react-datepicker__day-name,
      .react-datepicker__time-name {
        width: 1.2rem;
        line-height: 1.2rem;
      }

      div:last-child {
        display: inline-block;
      }

      .datepicker-mark {
        bottom: 0;
      }
    }
  }
}
