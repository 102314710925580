.view-mode-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.view-mode-icons-icon {
  display: block;
  font-family: "Roboto Condensed", Roboto, Helvetica, Arial, sans-serif;
  width: auto;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 2.7rem;
    height: 2.7rem;
  }

  &[disabled] {
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.view-mode-icons-icon svg {
  &:hover {
    fill: var(--footer-link-hover);

    .modeIconBorder {
      fill-opacity: 1;
    }
  }

  &.selected {
    fill: var(--footer-item);
    .modeIconBorder {
      fill-opacity: 1;
    }

    &:hover {
      fill: var(--footer-item);
      .modeIconBorder {
        fill-opacity: 1;
      }
    }
  }

  .modeIconBorder {
    fill-opacity: 0;
    transition: fill-opacity 0.2s;
  }
}

.mode-text {
  .view-mode-icons-icon {
    span {
      &.inactive {
        color: var(--footer-inactive);
      }
    }
    .selected {
      color: var(--footer-item);
    }

    &:hover {
      span {
        color: var(--text-link);
      }
    }
  }
}
