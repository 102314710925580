html:fullscreen {
  .header-main {
    padding: 0.25rem 0.5rem;
  }
  .topBar {
    padding-bottom: 0.5rem;
  }
  .main-header {
    padding-top: 0.5rem;
  }

  body:not(.mode-mobile) {
    .categorySpaceItemWrap {
      margin: 0.2rem;
    }
    .topPanelWrap {
      flex-direction: row;
      align-items: center;
    }
    .bookItemWrapHor {
      padding-right: 0.5rem;
    }
  }
}
