@import "../components/header/Header.variables.module.scss";

.cookies-notification {
  padding: 2rem 3rem;
  position: fixed;
  left: 0;
  right: 0;
  z-index: var(--cookies-notification-z-index);
  background-color: var(--white);
  top: $header-height;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);

  .cookies-notification-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .cookies-notification-content {
      margin-bottom: 0;

      .cookies-notification-content-title {
        color: #5AAECE;
        font-size: 1.375rem;
        font-weight: bold;
        font-family: "Bebas Neue", sans-serif;
        margin-bottom: 10px;
      }

      .cookies-notification-content-text {
        color: #000000;
        font-size: 1.125rem;
        line-height: 1.6rem;
        font-weight: normal;
        font-family: "Roboto Condensed", Roboto, Helvetica, Arial, sans-serif;
      }
    }

    .cookies-notification-actions {
      display: inline-flex;
      margin-left: 15px;

      .button-base {
        margin: 0 15px;
        min-width: 120px;
        height: 40px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .hide-icon-container {
      top: 0;
    }
  }
}

.mode-tablet {
  .cookies-notification {
    top: $header-tablet-height;
  }
}

.mode-mobile {
  .cookies-notification {
    padding: 1.5rem 1rem;
    top: $header-mobile-height;
  }

  .cookies-notification-wrapper {
    flex-direction: column;
    align-items: flex-end;

    .cookies-notification-content {
      margin-bottom: 1rem;
    }

    .cookies-notification-actions {
      align-items: center;
      flex: 1;
    }
  }
}
