@use 'sass:math';
$pub-row-space-24-width: 1.33rem;

//this styles imports in index.scss and global for all project
.pub-dialog-container {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 0 24px;
  margin: 0.5rem auto;
  max-width: 660px;
  width: 100%;

  .inputField {
    height: 2.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkbox-item {
    max-width: calc(50% - #{math.div($pub-row-space-24-width, 2)});
  }
}

.print-dialog {
  width: 660px;
}

.pub-tabs {
  z-index: 2;
  position: sticky;
  top: -.5rem;
  padding: 0;
  padding-top: .5rem;
  background-color: var(--popup-background);
}

.tab-wrap {
  &.epub-tabs,
  &.pub-tabs {
    .tab-default {
      font-size: 1.1rem;
    }
  }
}

.epub-tabs {
  z-index: 1;
  position: sticky;
  top: 0;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--popup-background);

  button {
    &:first-child {
      margin-right: 2.2rem;
    }
  }
}

.pub-select-container-base {
  padding: 0.5rem 0.6rem;
  margin-top: 0.2rem;
  font-size: 1rem;
  line-height: 1.2rem;
  height: 2.2rem;
  box-sizing: border-box;
  border: 1px solid var(--border);
  font-family: "Roboto Condensed", sans-serif;
}

.sct-select-without-label {
  .pub-select-container-base {
    margin-top: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.pub-select-container-full {
  width: 100%;
  margin-right: 1px; // Fix second bug. https://rollncode.atlassian.net/browse/EGWW-2326
}

.sct-check-text {
  .controlLabel {
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 0.5rem;
  }
}

.check-yes {
  min-width: 1rem;
  fill: var(--check-active);
  min-height: 1rem;
  cursor: pointer;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.pub-dialog-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pub-dialog-row-wrap {
  flex-wrap: wrap;
}

.pub-dialog-row-center {
  justify-content: center;
}

.pub-dialog-row-checkbox {
  padding-bottom: 0.4rem;
}

.select-margin-top {
  margin-top: 0.6rem;
}

.select-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.pt-margin-bottom {
  flex-shrink: 0;
  margin-bottom: 0.6rem;
}

.pt-flex {
  display: flex;
  flex-wrap: wrap;
}

.pub-dialog-btn {
  padding-top: 0.1rem;
  letter-spacing: 0.1em;
  font-size: 0.9rem;
}

.sc-btn {
  width: 8.3rem;
  height: 2.2rem;
  margin-top: 1.5rem;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.mode-mobile {
  .pub-select-container-full {
    max-width: none;
  }

  .sct-container {
    padding: 0.9rem 0 0;
  }
  .pt-container {
    .sct-select-title {
      margin-bottom: 0;
    }

    .sct-select-without-label {
      .pt-margin-bottom {
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .sct-link-container {
    margin-bottom: 0.6rem;
  }
  .select-container {
    .select-label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 1.5rem;
      white-space: nowrap;
    }
  }

  .pub-dialog-container {
    width: unset;
    min-height: 60%;
    max-width: calc(100vw - 3rem);

    .tab-wrap {
      padding: 0.5rem 0 0 0;
    }

    .pub-dialog-row {
      .lsContainer,
      .sct-check-text {
        width: 100%;
      }

      .lsContainer {
        &.sct-para-start,
        &.sct-para-end {
          margin: 0 0 0.8rem 0;
        }
      }
    }

    .sct-select-title {
      margin-bottom: 0.11rem;
      display: block;
    }
    .checkbox-item {
      &.single-in-row {
        max-width: 100%;
      }

      .controlLabel {
        max-width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .pub-row-space {
    width: 0.6rem;
    min-width: 0.6rem;
  }
}

.sct-check-text {
  flex: 1;
  padding: 0;
}

.pub-row-around {
  display: flex;
  justify-content: space-around;
}

.pub-selector-item {
  flex: 0.95;
}

.pub-row-space {
  width: 2.8rem;
  min-width: 2.8rem;
}

.pub-row-space-24 {
  width: $pub-row-space-24-width;
  min-width: $pub-row-space-24-width;
}
.mode-mobile {
  .pub-dialog-container {
    padding-right: 0;
    padding-left: 0;
  }
  &.mode-2k {
    .pub-dialog-container {
      max-width: unset;
    }
  }
}
.mode-mobile,
.mode-tablet {
  .select-margin-top {
    margin-top: 0;
  }
}

.mode-tablet {
  .pub-select-container-base {
    margin-top: 0.2rem;
  }
}

.mode-blind {
  .select-label,
  .select-container,
  .controlLabel {
    color: var(--gray);
  }

  .pub-dialog-container {
    .pub-tabs {
      justify-content: flex-start;

      .tab-default {
        margin-right: 2rem;
      }
    }
  }
  &.mode-mobile {
    .pub-tabs {
      justify-content: space-between;

      .tab-default {
        margin-right: 0;
      }
    }
  }
}

.mode-zoom-150,
.mode-zoom-200 {
  &.mode-tablet {
    .pub-dialog-container {
      width: 100%;
      max-width: 768px;
      padding: 0;
    }
  }

  &.mode-mobile {
    .pt-btn-container {
      flex-wrap: wrap;
    }
  }
}

.mode-zoom-400 {
  &.mode-blind {
    .pub-dialog-container .pub-tabs .tab-default {
      margin-right: 1.5rem;
    }
  }
}

.sct-container {
  height: auto;
  box-sizing: border-box;
  padding: 1.3rem 0 0;
  overflow: hidden;
  background-color: var(--popup-background);

  .expand-item {
    font-weight: bold;
  }
}

.sct-input {
  outline: none;
}

.sct-center {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.sct-margin-top {
  margin-top: 0.5rem;
}

.sct-btn {
  width: 8.3rem;
  margin-top: 1.8rem;
  line-height: 2.6rem;
  /*Deprecated 20.08.19
    padding-top: 0;*/
}

.sct-divider {
  margin: 1.5rem 0;
  height: 1px;
  background-color: var(--border-sec);
}

.mode-mobile {
  .sct-divider {
    margin: 1rem 0;
  }
}

.sct-select-title {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  color: var(--gray);
}

.sctRangeForm input[type="submit"] {
  width: 100%;
  font-family: "Bebas Neue", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.03em;
}

.sct-link-container {
  display: flex;
  width: 100%;
  margin-bottom: 1.3rem;

  label {
    display: block;
    flex: 1;
    font-family: "Roboto Condensed", sans-serif;
  }

  input {
    width: 100%;
    height: 2.2rem;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.1rem;
    line-height: 1.1rem;
    background-color: var(--background);
    color: var(--text-default);
    margin-top: 0.2rem;
    box-sizing: border-box;
    padding: 0.5rem 0.6rem;
    border: 1px solid var(--border);
    text-overflow: ellipsis;
  }

  .sct-link-plus-container {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sct-link-container-m {
  position: relative;

  input {
    margin-top: 0.2rem;
  }
}

.sct-link-plus-container {
  width: 2.2rem;
  height: 2.2rem;
  align-self: flex-end;
  padding: 0;
  margin-left: 0.6rem;
  border-radius: 0.3rem;

  .egw-font-icon{
    font-size: 0.9rem;
    margin: 0;
    color: var(--btn-st-text);
  }

  &:hover  .egw-font-icon {
    color: var(--btn-st-text-hover);
  }
}


.sct-select {
  width: 10.1rem;
}

.sct-full-width {
  width: 100%;
}

.pt-container {
  display: flex;
  flex-direction: column;
  color: var(--popup-text-color);
  flex: 1;
  padding: 1.3rem 0 0;
  overflow: hidden;

  .expand-item {
    font-family: "Bebas Neue", sans-serif;
    margin: 1.3rem 0;
  }

  input {
    font-family: "Roboto Condensed", sans-serif;
    color: var(--popup-text-color);
    letter-spacing: 0.03em;
  }
}

.pt-container-m {
  margin: 0;
  flex-direction: column;

  .ptShow.pt-margin-bottom {
    margin-bottom: 0;
  }
}

.pt-para-start {
  width: 100%;
  margin-right: 0.6rem;
}

.pt-para-end {
  width: 100%;
  margin-left: 0.6rem;
}

#pt-top-label {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.1rem;
  color: var(--gray);
  letter-spacing: 0.08em;
  margin-bottom: 0.6rem;
}

.pt-text-field {
  width: 100%;
  font-size: 1rem;
  line-height: 1.1rem;
  box-sizing: border-box;
  padding: 0.7rem 0.6rem;
  border: 1px solid var(--border-sec);

  p,
  h3 {
    margin-top: 0;
  }
}

.pt-radio {
  flex-shrink: 0;
  margin: 0.6rem 0;
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1;

  :hover {
    color: var(--text-link-hover);
    cursor: pointer;
  }

  svg:focus + span {
    color: var(--text-link-hover);
    text-decoration: underline;
  }
}

.pt-dots {
  width: 1.2rem;
  min-width: 1.2rem;
  display: block;
}

.pt-dots-m {
  transform: rotate(90deg);
}

.pt-preview {
  height: 6.1rem;
  box-sizing: border-box;
  padding: 0.5rem 0.6rem;
  overflow: hidden;
  border: 1px solid var(--border);
  font-family: "Roboto Condensed", sans-serif;
}

.pt-top-preview {
  margin-top: 0.6rem;
}

.pt-bottom-preview {
  margin-bottom: 0.3rem;
}

.pt-btn-container {
  display: flex;
  justify-content: center;
}

select {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  line-height: 1.1rem;
  color: var(--text-default);
  margin-top: 0.4rem;
  box-sizing: border-box;
  background: none;
  padding: 0.7rem 0.6rem;
  border: 1px solid var(--border);

  &::after {
    content: "";
  }
}

.pt-disabled {
  z-index: 1; // [EGWW-2326] Fix element is not always visible in Safari because of "opacity" property below.
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
  color: var(--text-default);
}

.pt-active {
  color: var(--popup-text-color);
}

.pt-show {
  display: flex;
}

.pt-btn {
  width: 6.6rem;
  margin: 2.2rem 1.4rem 0;
}

.validation-error {
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: var(--error-color);
}

.mode-mobile {
  .pt-btn-container {
    margin-top: 1.1rem;
  }

  .pt-btn {
    margin: 0 1.4rem 0;
  }
}
