.mode-mobile {
  .home-page__folder-list {
    flex-direction: column;
    margin: 0;

    .categorySpaceItemWrap {
      flex-grow: 1;
      min-height: 3.35rem;
    }
  }
}