.book-list-with-arrows {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .book-image-wrap {
    width: 4.7rem !important;
    height: 7rem !important;
  }
}

.book-list-with-arrows_scroll-view {
  $children_gap-hor: 0.5rem;

  display: flex;

  & > * {
    padding-left: $children_gap-hor;
    width: initial !important; // Prevent children with "width: 100%" to be stretched.
  }

  & > *:first-child {
    padding-left: 0;
  }
}

.book-list-with-arrows_right {
  transform: rotate(90deg);
}
.book-list-with-arrows_left {
  transform: rotate(-90deg);
}
.book-list-with-arrows_right,
.book-list-with-arrows_left {
  &.invisible {
    visibility: hidden;
  }
}

